import { Edge, Node } from "@xyflow/react";
import { Button, Input, Select, message } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import TransferService from "../../../../../entities/model/TransferService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import IntegrationManager from "../utils/common";

type Props = {
  id: string;
  nodes: Node[];
  edges: Edge[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
};

const { TextArea } = Input;

const RabbitmqOutput: React.FC<Props> = ({
  id,
  nodes,
  edges,
  setNodes,
  setEdges,
}) => {
  const [nodeName, setNodeName] = useState<string>(id);
  const [editNodeNameStatus, setEditNodeNameStatus] = useState<boolean>(false);

  const onChangeNodeId = () => {
    IntegrationManager.changeNodeId(
      id,
      nodeName,
      nodes,
      () => {},
      setNodes,
      setEdges,
      setEditNodeNameStatus,
    );
  };

  useEffect(() => {
    setNodeName(id);
    const node: any = nodes.find((el) => el.id === id)?.data;
    console.log(node);
    if (node) {
      handleEdges();
      setRabbitConnSelected(node.rabbitConn);
      setRabbitMessage(node.rabbitMessage);
      setRabbitVhost(node.rabbitVhost);
      getRabbitConn();
    }
  }, [id]);

  const [rabbitConnOptions, setRabbitConnOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [rabbitConnSelected, setRabbitConnSelected] = useState<string>("");

  const [rabbitMessage, setRabbitMessage] = useState<string>("");
  const [rabbitVhost, setRabbitVhost] = useState<string>("");

  const getRabbitConn = async () => {
    const getSystems = await TransferService.getAll({
      filters: [{ id: "system", value: "RABBITMQ" }],
    });
    setRabbitConnOptions(
      getSystems.data.rows.map((el: any) => ({ label: el.id, value: el.id })),
    );
  };

  const handleEdges = () => {
    setNodeName(id);
    const fNode: any = nodes.find((el) => el.id === id)?.data;
    if (!fNode) {
      // message.error('Не обнаружена нода. Возможно, вы ее удалили перед тем как открыть.');
      return;
    }

    const findEdges = edges.find((el) => el.target === id);
    if (!findEdges) {
      message.warning(
        `Обратите внимание, что в эту ноду (${id}) не входит поток данных`,
      );
    }
  };

  useEffect(() => {
    setNodes((prev) => {
      const res = prev.map((rec) => {
        if (rec.id === id) {
          rec.data.rabbitConn = rabbitConnSelected;
          rec.data.rabbitVhost = rabbitVhost;
          rec.data.rabbitMessage = rabbitMessage;
        }
        return rec;
      });
      return res;
    });
  }, [rabbitConnSelected, rabbitMessage, rabbitVhost]);

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Наименование ноды
          </div>
          <Input
            value={nodeName}
            status={
              nodeName.toString().match(/^[A-Za-z][A-Za-z0-9\-_]*$/)
                ? ""
                : "error"
            }
            disabled={!editNodeNameStatus}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => {
              if (!el.target.value.match(/^[A-Za-z][A-Za-z0-9\-_]*$/)) {
                message.warning(
                  "Вводимое наименование содержит недопустимые символы",
                );
              }
              setNodeName(el.target.value);
            }}
            allowClear
          />
          {!editNodeNameStatus && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditNodeNameStatus(true)}
            >
              ✏️
            </Button>
          )}
          {editNodeNameStatus && (
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <Button style={{ width: "50px" }} onClick={onChangeNodeId}>
                💾
              </Button>
              <Button
                style={{ width: "50px" }}
                onClick={() => {
                  setNodeName(id);
                  setEditNodeNameStatus(false);
                }}
              >
                🔄
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Подключение RabbitMQ
          </div>
          <Select
            value={rabbitConnSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setRabbitConnSelected(el)}
            options={rabbitConnOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>vHost</div>
          <Input
            value={rabbitVhost}
            onChange={(el) => setRabbitVhost(el.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Тело сообщения
          </div>
          <TextArea
            value={rabbitMessage}
            onChange={(el) => setRabbitMessage(el.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default RabbitmqOutput;
