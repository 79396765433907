import { keepPreviousData, useQuery } from "@tanstack/react-query";

import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";
import { useContext, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../..";
import DictionaryService from "../../entities/model/DictionaryService";
import Button from "../../shared/components/button";

import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, MenuItem } from "@mui/material";
import { Tooltip } from "antd";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import DictionaryRecordImportModal from "../../features/componets/DictionaryRecordImportModal";
import { exportToExcel } from "../../shared/helper/excelExport";
import PageError500 from "../errors/error500";

type Column = {
  attributeId: string;
  attributeName: string;
  root: boolean;
};

type ColumnsResponse = {
  code: number;
  text: string;
  data: Column[];
};

type RecordsResponse = {
  code: number;
  text: string;
  data: {
    count: number;
    rows: Record<string, any>[];
  };
};

const DataDictionaryPage = () => {
  const [dictionaryNotExist, setDictionaryNotExist] = useState<boolean>(false);

  const params = useParams();
  const dictionaryID = params.dictionaryID!;

  const { store } = useContext(Context);
  store.setPageName(`Просмотр словаря ${dictionaryID}`);

  const importRef: any = useRef();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState<MRT_ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const queryColumns = useQuery<ColumnsResponse>({
    refetchOnWindowFocus: false,
    retry: false,
    placeholderData: keepPreviousData,
    queryKey: ["dictionary-data-columns", dictionaryID],
    queryFn: async () => {
      const response = await DictionaryService.getColumns(dictionaryID);

      if (response.code !== 1) {
        throw new Error(`Ошибка при загрузке столбцов: ${response.text}`);
      }

      return response;
    },
  });

  const queryRecords = useQuery<RecordsResponse>({
    refetchOnWindowFocus: false,
    retry: false,
    placeholderData: keepPreviousData,
    queryKey: [
      "dictionary-data-records",
      dictionaryID,
      filters,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      const response = await DictionaryService.getRecords(dictionaryID, {
        page: pagination.pageIndex,
        size: pagination.pageSize,
        filters,
        sorting,
      });

      if (response.code === 0) {
        setDictionaryNotExist(true);
      }

      if (response.code !== 1) {
        throw new Error(`Ошибка при загрузке данных: ${response.text}`);
      }

      return response;
    },
  });

  const { data: { data: columnsRaw = [] } = {} } = queryColumns;
  const { data: { data: { count = 0, rows = [] } = {} } = {} } = queryRecords;

  const columns = columnsRaw.map<MRT_ColumnDef<any>>((column) => ({
    header: column.attributeName,
    accessorKey: column.attributeId,
  }));

  const table = useMaterialReactTable({
    columns: columns,
    data: rows,

    localization: MRT_Localization_RU,

    rowCount: count,

    initialState: { density: "compact" },

    state: {
      columnFilters: filters,
      isLoading: queryColumns.isLoading || queryRecords.isLoading,
      pagination,
      showAlertBanner: queryColumns.isError || queryRecords.isError,
      showProgressBars: queryColumns.isRefetching || queryRecords.isRefetching,
      sorting,
    },

    enableGrouping: false,
    enableStickyHeader: true,
    enableGlobalFilter: false,

    groupedColumnMode: "remove",

    manualFiltering: true,
    onColumnFiltersChange: setFilters,

    manualPagination: true,
    onPaginationChange: setPagination,

    manualSorting: true,
    onSortingChange: setSorting,

    muiToolbarAlertBannerProps:
      queryColumns.isError || queryRecords.isError
        ? { color: "error", children: "Возникла ошибка при загрузке данных" }
        : undefined,

    muiTableBodyRowProps: () => ({
      sx: {
        height: "50px",
      },
    }),

    renderTopToolbarCustomActions: () => (
      <Tooltip arrow title="Обновить">
        <IconButton onClick={() => queryRecords.refetch()}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    ),

    enableRowActions: true,
    renderRowActionMenuItems: ({ row }) => [
      <MenuItem key="edit" disabled>
        📋 Изменить
      </MenuItem>,
      <MenuItem key="delete" disabled>
        🚫 Удалить
      </MenuItem>,
    ],
  });

  if (dictionaryNotExist) {
    return (
      <PageError500 message={"Похоже, что такого словаря не существует!"} />
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "15px",
          marginBottom: "15px",
        }}
      >
        <Button type="dashed" label="📁 Добавить" disabled />
        <Button
          type="dashed"
          label="📤 Экспорт"
          onClick={() => exportToExcel(`Словарь_${dictionaryID}`, rows)}
        />
        <Button
          type="dashed"
          label="📤 Импорт"
          onClick={() => importRef.current?.openModal()}
        />
      </div>
      <MaterialReactTable table={table} />
      <DictionaryRecordImportModal
        apiRef={importRef}
        fetchData={() => queryRecords.refetch()}
        columns={columns}
        id={dictionaryID}
      />
    </div>
  );
};

export default DataDictionaryPage;
