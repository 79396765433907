import {
  DatePicker,
  Input,
  List,
  Modal,
  Popconfirm,
  Select,
  Switch,
  notification,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import "react-datasheet-grid/dist/style.css";
import { Context } from "../../";
import CalculationService from "../../entities/model/CalcService";
import DictionaryService from "../../entities/model/DictionaryService";
import FigureService from "../../entities/model/FigureService";
import ModelService from "../../entities/model/ModelService";
import ViewService from "../../entities/model/ViewService";
import Button from "../../shared/components/button";
import {
  DataSheetGrid,
  floatColumn,
  isoDateColumn,
  keyColumn,
  textColumn,
} from "../../shared/components/excelgrid";
import { Operation } from "../../shared/components/excelgrid/types";
import { LoaderFullScreen } from "../../shared/components/loader";
import { fuzzyIsIn } from "../../shared/helper/comparison";
import { exportToCSV, exportToExcel } from "../../shared/helper/excelExport";
import { map, pipe } from "../../shared/helper/fp";
import DownloadMenu from "./downloadMenu";

const renameObjectKeys = (
  obj: Record<string, unknown>,
  rename: Record<string, unknown>,
) =>
  pipe(
    obj,
    Object.entries,
    map(([key, value]: [string, unknown]) =>
      rename.hasOwnProperty(key) ? [rename[key], value] : [key, value],
    ),
    Object.fromEntries,
  ) as Record<string, unknown>;

const FEATURE_MARK_EMPTY_ATTRIBUTES = {
  active: true,
  config: {
    // value representing empty attribute
    replace: "",
    // this makes object behaves like a string,
    // but preserves it's uniqueness to avoid collisions when the
    // actual attribute's value matches the placeholder's value
    placeholder: {
      toString: () => "<ПУСТО>",
    },
  },
};

const TIME_LEVELS = [
  "NONE",
  "DAY",
  "TWEEK",
  "CWEEK",
  "MONTH",
  "QUARTER",
  "YEAR",
] as const;

type View = {
  uuid: string;
  name: string;
  description?: string;
  body: { [key: string]: any };
};

type ViewsProps = {
  data: View[];
  onOpen: (view: View) => void;
  onRemove: (view: View) => void;
};

const Views: React.FC<ViewsProps> = ({ data, onOpen, onRemove }) => (
  <List
    itemLayout="horizontal"
    dataSource={data}
    renderItem={(item: any, index) => (
      <List.Item key={index}>
        <List.Item.Meta
          title={item.name ?? item.uuid}
          description={item.description}
        />
        <Button type="text" label="🔍" onClick={() => onOpen(item)} />
        <Button type="text" label="❌" onClick={() => onRemove(item)} />
      </List.Item>
    )}
  />
);

const ViewerPage: React.FC = () => {
  const { store } = useContext(Context);
  store.setPageName("Просмотр данных");

  const [views, setViews] = useState<View[]>([]);
  const [viewModalState, setViewModalState] = useState<boolean>(false);

  const [viewName, setViewName] = useState<string>("");
  const [viewDescription, setViewDescription] = useState<string>();

  const [data, setData] = useState<any[]>();
  const [columns, setColumns] = useState<any[]>();
  const [changesColumns, setChangesColumns] = useState<any[]>();

  const [changes, setChanges] = useState<{ [key: string]: any }>({});
  const [changesModalState, setChangesModalState] = useState<boolean>(false);

  const [modalState, setModalState] = useState<boolean>(false);
  const [viewTimeLevel, setViewTimeLevel] =
    useState<(typeof TIME_LEVELS)[number]>("NONE");
  const [viewModel, setViewModel] = useState<string | undefined>();
  const [viewFigure, setViewFigure] = useState<string[]>([]);
  const [viewTimeFrom, setViewTimeFrom] = useState<string | undefined | null>();
  const [viewTimeTo, setViewTimeTo] = useState<string | undefined | null>();
  const [viewAttributes, setViewAttributes] = useState<string[]>([]);
  const [dateView, setDateView] = useState<string>("vertical");
  const [basePeriod, setBasePeriod] = useState<number>(0);

  const [filterOptions, setFilterOptions] = useState<any>({});
  const [filterValues, setFilterValues] = useState<any>({});

  const [gridKey, setGridKey] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);
  const [listModel, setListModel] = useState<any>();
  const [listFigures, setListFigures] = useState<any[]>([]);
  const [listAttributes, setListAttributes] = useState<any[]>([]);

  const [saveModalStatus, setSaveModalStatus] = useState<boolean>(false);

  const refreshViews = () => {
    ViewService.getAll()
      .then(({ data }) => setViews(data))
      .catch(() =>
        notification.error({ message: "Ошибка при загрузке ракурсов" }),
      );
  };

  const addView = (name: string, description?: string) => {
    const body = {
      viewTimeLevel,
      viewModel,
      viewFigure,
      viewTimeFrom,
      viewTimeTo,
      viewAttributes,
      dateView,
      basePeriod,
      filterValues,
    };

    ViewService.create(name, description, body)
      .then(refreshViews)
      .catch(() =>
        notification.error({ message: "Ошибка при сохранении ракурса" }),
      );
  };

  const removeView = (view: View) => {
    ViewService.delete(view.uuid).then(refreshViews);
  };

  const openView = (view: View) => {
    const { body } = view;

    setViewModel(body.viewModel);
    setViewFigure(body.viewFigure);
    setViewTimeLevel(body.viewTimeLevel);
    setViewTimeFrom(body.viewTimeFrom);
    setViewTimeTo(body.viewTimeTo);
    setViewAttributes(body.viewAttributes);
    setDateView(body.dateView);
    setFilterValues(body.filterValues);
  };

  // fetch views
  useEffect(() => {
    refreshViews();
  }, []);

  // fetch models
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await ModelService.getAll();
      setListModel(response.data);
      setLoading(false);
    })();
  }, []);

  // fetch figures
  useEffect(() => {
    (async () => {
      if (viewModel) {
        setLoading(true);
        const response = await FigureService.getAll(viewModel);
        setListFigures(response.data);
        setLoading(false);
      }
    })();
  }, [viewModel]);

  const onOkChangesModal = () => {
    setChangesModalState(false);

    if (viewModel === undefined)
      return notification.error({ message: "Не выбрана модель" });

    let data = Object.values(changes);

    if (FEATURE_MARK_EMPTY_ATTRIBUTES.active) {
      const { placeholder, replace } = FEATURE_MARK_EMPTY_ATTRIBUTES.config;

      data = data
        .map(Object.entries)
        .map((entries) =>
          entries.map(([key, value]) =>
            value === placeholder ? [key, replace] : [key, value],
          ),
        )
        .map(Object.fromEntries);
    }

    CalculationService.disaggregate(
      viewModel,
      viewAttributes,
      viewTimeLevel,
      data,
    )
      .then(() =>
        notification.success({ message: "Изменения успешно отправлены" }),
      )
      .then(() => setChanges({}))
      .then(() => onHandleLaunch())
      .catch(() =>
        notification.error({
          message: "Произошла ошибка при отправке изменений",
        }),
      );
  };

  // fetch available attributes
  useEffect(() => {
    (async () => {
      if (viewModel && viewFigure.length > 0) {
        setLoading(true);

        const options = await Promise.all(
          viewFigure.map(async (figure) => {
            const response = await FigureService.availableAttributes(
              viewModel,
              figure,
            );
            return response.data;
          }),
        );

        if (options.length === 1) {
          setListAttributes(options[0]);
        }

        if (options.length > 1) {
          let dict: any = [];

          for (const option of options) {
            for (const d of option) {
              dict.push(d);
            }
          }

          // Используем Set, чтобы убрать дубликаты
          const uniqueDict = Array.from(
            new Set(dict.map((item: any) => item.id)),
          );

          const idCounts: any = {};

          for (const option of options) {
            for (const d of option) {
              if (!idCounts[d.id]) {
                idCounts[d.id] = 1;
              } else {
                idCounts[d.id]++;
              }
            }
          }

          const commonObjects = uniqueDict
            .filter((id: any) => idCounts[id] === options.length)
            .map((id: any) => ({
              id,
              name: dict.find((item: any) => item.id === id).name,
              dictionaryId: dict.find((item: any) => item.id === id)
                .dictionaryId,
            }));

          setListAttributes(commonObjects);
        }

        setLoading(false);
      }
    })();
  }, [viewModel, viewFigure]);

  const onDataSheetGridChange = (newData: any[], events: Operation[]) => {
    if (data === undefined) {
      return setData(newData);
    }

    switch (dateView) {
      case "horizontal":
        events.forEach((event) => {
          if (event.type === "UPDATE") {
            const primaryKeyColumns = [...viewAttributes];
            const figureColumn = "Показатели";

            const changeEntryKeyEntries = primaryKeyColumns.map(
              (key: string) => [key, data[event.fromRowIndex][key]],
            );

            const itemsOnChange = _.differenceWith(
              Object.entries(newData[event.fromRowIndex]),
              Object.entries(data[event.fromRowIndex]),
              _.isEqual,
            );

            const newChanges = itemsOnChange.map(([key, item]) =>
              _.fromPairs([
                ...changeEntryKeyEntries,
                [viewTimeLevel, key],
                [data[event.fromRowIndex][figureColumn], item],
              ]),
            );

            if (itemsOnChange.length > 0) {
              setChanges((changes) => {
                const reducer = (
                  acc: typeof changes,
                  change: (typeof newChanges)[number],
                ) => {
                  const keys = [...primaryKeyColumns, viewTimeLevel];
                  const changeEntryKey = keys
                    .map((key) => [key, change[key]])
                    .toString();

                  const newChange =
                    acc[changeEntryKey] === undefined
                      ? change
                      : { ...acc[changeEntryKey], ...change };
                  return { ...acc, [changeEntryKey]: newChange };
                };

                return _.reduce(newChanges, reducer, changes);
              });
            }
          }
        });
        break;

      case "vertical":
        events.forEach((event) => {
          if (event.type === "UPDATE") {
            const primaryKeyColumns = [...viewAttributes, viewTimeLevel];
            const figureColumns = [...viewFigure];

            const changeEntryKeyEntries = primaryKeyColumns.map(
              (key: string) => [key, data[event.fromRowIndex][key]],
            );

            const changeEntryKey = Object.fromEntries(changeEntryKeyEntries);
            const changeEntryKeyHash = changeEntryKeyEntries.toString();

            const itemsOnChange = figureColumns
              .filter(
                (key) =>
                  data[event.fromRowIndex][key] !==
                  newData[event.fromRowIndex][key],
              )
              .map((key) => ({ key, item: newData[event.fromRowIndex][key] }));

            if (itemsOnChange.length > 0) {
              setChanges((changes) => {
                const newChange = itemsOnChange.reduce(
                  (acc, { key, item }) => ({ ...acc, [key]: item }),
                  {},
                );

                const change =
                  changes[changeEntryKeyHash] !== undefined
                    ? { ...changes[changeEntryKeyHash], ...newChange }
                    : newChange;

                return {
                  ...changes,
                  [changeEntryKeyHash]: { ...change, ...changeEntryKey },
                };
              });
            }
          }
        });
        break;

      default:
        throw new Error(`Unknown type of date view "${dateView}"`);
    }

    setData(newData);
  };

  const onHandleLaunch = async () => {
    if (viewModel) {
      setLoading(true);
      let commonData: any = [];

      let filter = [];
      if (viewTimeFrom?.substring(0, 10)) {
        filter.push({
          attribute: "DATE",
          expr: ">=",
          value: ["DAY", "TWEEK", "CWEEK"].includes(viewTimeLevel)
            ? viewTimeFrom?.substring(0, 10)
            : viewTimeFrom?.substring(0, 8) + "01",
        });
      }
      if (viewTimeTo?.substring(0, 10)) {
        filter.push({
          attribute: "DATE",
          expr: "<=",
          value: viewTimeTo?.substring(0, 10),
        });
      }

      let keys = Object.keys(filterValues);
      for (const key of keys) {
        if (filterValues[key]?.length > 0) {
          filter.push({ attribute: key, expr: "IN", value: filterValues[key] });
        }
      }

      for (const figure of viewFigure) {
        const response = await CalculationService.request(
          viewModel,
          viewAttributes,
          figure,
          viewTimeLevel,
          filter,
        );
        if (response && Array.isArray(response.data)) {
          commonData = [...commonData, ...response.data];
        }

        if (FEATURE_MARK_EMPTY_ATTRIBUTES.active) {
          const { placeholder, replace } = FEATURE_MARK_EMPTY_ATTRIBUTES.config;

          commonData = commonData
            .map(Object.entries)
            .map((entries: any[]) =>
              entries.map(([key, value]) =>
                value === replace ? [key, placeholder] : [key, value],
              ),
            )
            .map(Object.fromEntries);
        }
      }

      const timeMap: Record<string, string> = timeLevelOptions.reduce(
        (acc, time) => ({ ...acc, [time.value]: time.label }),
        {},
      );
      const attrMap: Record<string, string> = listAttributes.reduce(
        (acc, attr) => ({ ...acc, [attr.id]: attr.name }),
        {},
      );
      const figureMap: Record<string, string> = listFigures.reduce(
        (acc, figure) => ({ ...acc, [figure.id]: figure.name }),
        {},
      );

      const vertialColumns = [];

      for (const attribute of viewAttributes) {
        vertialColumns.push({
          ...keyColumn(attribute, textColumn),
          title: attrMap[attribute],
          disabled: true,
        });
      }

      if (viewTimeLevel !== "NONE") {
        vertialColumns.push({
          ...keyColumn(viewTimeLevel, isoDateColumn),
          title: timeMap[viewTimeLevel],
          disabled: true,
        });
      }

      for (const figure of viewFigure) {
        vertialColumns.push({
          ...keyColumn(figure, floatColumn),
          title: figureMap[figure],
        });
      }

      setChangesColumns(vertialColumns);

      if (dateView === "vertical") {
        const formattedData: any = {};
        const attrMap: any = {};

        for (const attr of listAttributes) {
          attrMap[attr.id] = attr.name;
        }

        for (const row of commonData) {
          const keyParts = viewAttributes.map((attribute) => row[attribute]);
          keyParts.push(row[viewTimeLevel]);
          const key = keyParts.join("");

          if (!formattedData[key]) {
            formattedData[key] = { ...row };
          }

          for (const figure of viewFigure) {
            if (figure in row) {
              formattedData[key][figure] = row[figure];
            }
          }
        }

        setData(Object.values(formattedData));
        setChanges({});

        setColumns(vertialColumns);
        setModalState(false);
      }
      if (dateView === "horizontal") {
        const transposedData: any[] = [];
        const dateRangeRaw: any = {};

        for (const record of commonData) {
          const dateValue = record[viewTimeLevel];
          dateRangeRaw[dateValue] = dateValue;

          for (const figure of viewFigure) {
            const keyList = [
              ...viewAttributes.map((attribute) => record[attribute]),
              figure,
            ];
            const key = keyList.join("-");

            const existingEntry = transposedData.find(
              (item) => item.key === key,
            );

            if (!existingEntry) {
              const newEntry: any = {
                key,
                Показатели: figure,
              };
              if (figure in record) {
                newEntry[record[viewTimeLevel]] = record[figure];
              }
              for (const attribute of viewAttributes) {
                newEntry[attribute] = record[attribute];
              }
              transposedData.push(newEntry);
            } else {
              if (figure in record) {
                existingEntry[dateValue] = record[figure];
              }
            }
          }
        }

        const cols = [];

        for (const attribute of viewAttributes) {
          const attr: any = listAttributes.find(
            (el: any) => el.id === attribute,
          );
          cols.push({
            ...keyColumn<any>(attribute, textColumn),
            title: attr.name,
            disabled: true,
          });
        }

        cols.push({
          ...keyColumn<any>("Показатели", textColumn),
          title: "Показатели",
          disabled: true,
        });

        const dateRange = Object.keys(dateRangeRaw).sort();

        for (const date of dateRange) {
          cols.push({
            ...keyColumn<any>(date, textColumn),
            title: date,
          });
        }

        setColumns(cols);
        setData(transposedData);
        setModalState(false);
      }

      setGridKey((prevKey) => prevKey + 1);
      setLoading(false);
    }
  };

  useEffect(() => {
    let periods = [];
    for (const figure of viewFigure) {
      const f = listFigures.filter((el: any) => el.id === figure);
      periods.push(f?.[0]?.level.period);
    }

    let min = 0;
    for (const period of periods) {
      if (period > min) min = period;
    }

    setBasePeriod(Number(min));
  }, [viewFigure, listFigures]);

  const handleFilterOptions = async () => {
    for (const attribute of viewAttributes) {
      const attr = listAttributes.find((el: any) => el.id === attribute);
      const response = await DictionaryService.filterOptions(
        attr.dictionaryId,
        attr.id,
        "",
      );
      if (response.code === 1) {
        setFilterOptions((prev: any) => {
          return { ...prev, [attr.id]: response.data };
        });
      }
    }
  };

  const menu = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "15px",
        marginBottom: "15px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "15px",
          width: "100%",
          justifyContent: "flex-start",
        }}
      >
        <Button
          type="primary"
          label="Настроить отображение"
          onClick={() => setModalState(true)}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "0px",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <Button
          size="large"
          type="link"
          label="⬇"
          onClick={() => setSaveModalStatus(true)}
        />
        <Button size="large" type="link" label="🔄" onClick={onHandleLaunch} />
        <Button
          size="large"
          type="text"
          label="💾"
          disabled={!Object.keys(changes).length}
          onClick={() => setChangesModalState(true)}
        />
      </div>
    </div>
  );

  return (
    <>
      {menu}
      {columns && (
        <DataSheetGrid
          key={gridKey}
          value={data}
          onChange={onDataSheetGridChange}
          columns={columns}
          lockRows
        />
      )}
      {loading && <LoaderFullScreen />}
      <Modal
        title={"Просмотр изменений"}
        open={changesModalState}
        width={"80vw"}
        okText="Отправить"
        okType="dashed"
        onCancel={() => setChangesModalState(false)}
        onOk={onOkChangesModal}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <DataSheetGrid
            key={gridKey}
            value={Object.values(changes)}
            columns={changesColumns?.map((column) => ({
              ...column,
              disabled: true,
            }))}
            lockRows={true}
          />
        </div>
      </Modal>
      <Modal
        open={viewModalState}
        onCancel={() => setViewModalState(false)}
        width="800px"
        title="Управление ракурсами"
        footer={[]}
        zIndex={10000000}
      >
        <Views
          data={views}
          onOpen={(view) => {
            openView(view);
            setViewModalState(false);
            setModalState(false);
          }}
          onRemove={(view) => {
            removeView(view);
          }}
        />
      </Modal>
      <Modal
        open={modalState}
        onCancel={() => setModalState(false)}
        width={"800px"}
        footer={
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Button
                type="text"
                label="📄"
                size="small"
                onClick={() => setViewModalState(true)}
              />
              <Popconfirm
                title="Сохранить ракурс"
                okText="Сохранить"
                cancelButtonProps={{ style: { display: "none" } }}
                description={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      marginTop: "10px",
                      width: "300px",
                    }}
                  >
                    <div>
                      <span style={{ fontWeight: "bold" }}>Название</span>
                      <Input
                        value={viewName}
                        onChange={(e) => setViewName(e.target.value)}
                      />
                    </div>
                    <div>
                      <span style={{ fontWeight: "bold" }}>Описание</span>
                      <Input
                        value={viewDescription}
                        onChange={(e) => setViewDescription(e.target.value)}
                      />
                    </div>
                  </div>
                }
                onConfirm={() => {
                  addView(viewName, viewDescription);
                }}
              >
                <Button type="text" label="💾" size="small" />
              </Popconfirm>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                type="default"
                label="Cancel"
                size="middle"
                onClick={() => setModalState(false)}
              />
              <Button
                type="primary"
                label="OK"
                size="middle"
                onClick={onHandleLaunch}
              />
            </div>
          </div>
        }
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontWeight: "bold" }}>Шаг 1. Выбрать модель</span>
            <Select
              options={
                listModel &&
                listModel.map((el: any) => ({
                  value: el.id,
                  label: `${el.name} (${el.id})`,
                }))
              }
              value={viewModel}
              onChange={(el) => {
                setViewModel(el);
                setViewTimeLevel("NONE");
                setViewFigure([]);
                setViewTimeFrom(undefined);
                setViewTimeTo(undefined);
                setViewAttributes([]);
                setDateView("vertical");
                setBasePeriod(0);
              }}
              showSearch={true}
              filterOption={(
                input,
                option?: { label: string; value: string },
              ) => fuzzyIsIn(input, option?.label ?? "")}
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontWeight: "bold" }}>
              Шаг 2. Выбрать показатели
            </span>
            <Select
              mode="multiple"
              options={
                listFigures &&
                listFigures.map((el: any) => ({ value: el.id, label: el.name }))
              }
              value={viewFigure}
              onChange={(el) => setViewFigure(el)}
              filterOption={(text, option) => fuzzyIsIn(text, option!.label)}
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontWeight: "bold" }}>
              Шаг 3. Настроить временную шкалу
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "15px",
                alignItems: "center",
              }}
            >
              <Select
                style={{ width: "200px" }}
                options={timeLevelOptions.filter((el) => el.code >= basePeriod)}
                value={viewTimeLevel}
                onChange={(level) => setViewTimeLevel(level)}
                showSearch={true}
                filterOption={(
                  input,
                  option?: { label: string; value: string },
                ) => fuzzyIsIn(input, option?.label ?? "")}
              />
              {viewTimeLevel !== "NONE" && (
                <>
                  от{" "}
                  <DatePicker
                    placeholder="Выберите дату"
                    value={
                      _.isNil(viewTimeFrom) ? viewTimeFrom : dayjs(viewTimeFrom)
                    }
                    onChange={(date?: Dayjs | null) =>
                      setViewTimeFrom(date?.toISOString())
                    }
                    picker={datePicker[viewTimeLevel]}
                    format={{ format: "DD.MM.YYYY" }}
                  />{" "}
                  до{" "}
                  <DatePicker
                    placeholder="Выберите дату"
                    value={_.isNil(viewTimeTo) ? viewTimeTo : dayjs(viewTimeTo)}
                    onChange={(date?: Dayjs | null) =>
                      setViewTimeTo(date?.toISOString())
                    }
                    picker={datePicker[viewTimeLevel]}
                    format={{ format: "DD.MM.YYYY" }}
                  />
                </>
              )}
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontWeight: "bold" }}>
              Шаг 4. Настроить уровень отображения
            </span>
            <Select
              mode="multiple"
              placeholder="Выберите атрибуты"
              options={
                listAttributes &&
                listAttributes.map((el: any) => ({
                  value: el.id,
                  label: el.name,
                }))
              }
              value={viewAttributes}
              onChange={(el) => setViewAttributes(el)}
              filterOption={(text, option) => fuzzyIsIn(text, option!.label)}
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontWeight: "bold" }}>Шаг 5. Прочие настройки</span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "15px",
                alignItems: "center",
              }}
            >
              <span>Горизонтальная линия времени</span>
              <Switch
                value={dateView === "horizontal"}
                onChange={(status) => {
                  status ? setDateView("horizontal") : setDateView("vertical");
                }}
              />
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontWeight: "bold" }}>Шаг 6. Фильтры</span>
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <Button
                type="dashed"
                label="Обновить значения фильтров"
                onClick={handleFilterOptions}
              />
              <Button
                type="dashed"
                label={`Сбросить фильтры (${Object.keys(filterValues).length})`}
                onClick={() => setFilterValues({})}
              />
            </div>
            {viewAttributes?.map((attributeId: string, ind: number) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "15px",
                  alignItems: "center",
                }}
                key={ind}
              >
                <span style={{ width: "150px" }}>
                  {
                    listAttributes.find((el: any) => el.id === attributeId)
                      ?.name
                  }
                </span>
                <Select
                  style={{ width: "100%", minWidth: "300px" }}
                  key={`s_${attributeId}`}
                  options={filterOptions[attributeId]}
                  mode="multiple"
                  onChange={(el) => {
                    setFilterValues((prev: any) => {
                      return { ...prev, [attributeId]: el };
                    });
                  }}
                  value={filterValues[attributeId]}
                  showSearch={true}
                  filterOption={(
                    input,
                    option?: { label: string; value: string },
                  ) => fuzzyIsIn(input, option?.label ?? "")}
                />
              </div>
            ))}
          </div>
        </div>
      </Modal>
      <Modal
        title="Настройки загрузки"
        open={saveModalStatus}
        footer={[]}
        onCancel={() => setSaveModalStatus(false)}
      >
        <DownloadMenu
          onDownload={({ format, columnsType }) => {
            const exporter = (() => {
              switch (format) {
                case "xlsx":
                  return exportToExcel;
                case "csv":
                  return exportToCSV;
              }
            })();

            const [dateMapping, nameMapping]: [
              Record<string, string>,
              Record<string, string>,
            ] = (() => {
              switch (columnsType) {
                case "id": {
                  const dateMapping = Object.fromEntries(
                    TIME_LEVELS.map((level) => [level, "DATE"]),
                  );
                  const nameMapping = Object.fromEntries(
                    viewFigure.map((figure) => [figure, `F_${figure}`]),
                  );
                  return [dateMapping, nameMapping];
                }

                case "name": {
                  const dateMapping = timeLevelOptions.reduce(
                    (index, value) => {
                      index[value.value] = value.label;
                      return index;
                    },
                    {} as any,
                  );

                  const nameMapping = [
                    ...listAttributes,
                    ...listFigures,
                  ].reduce((index, value) => {
                    index[value.id] = value.name;
                    return index;
                  }, {} as any);

                  return [dateMapping, nameMapping];
                }
              }
            })();

            const transformed = data
              ?.map((row) => renameObjectKeys(row, dateMapping))
              ?.map((row) => renameObjectKeys(row, nameMapping));

            exporter("DATASHEET", transformed);
          }}
        />
      </Modal>
    </>
  );
};

export default ViewerPage;

const timeLevelOptions = [
  { value: "NONE", label: "Нет", code: 0 },
  { value: "DAY", label: "День", code: 1 },
  { value: "TWEEK", label: "Тех. неделя", code: 2 },
  { value: "CWEEK", label: "Кал. неделя", code: 3 },
  { value: "MONTH", label: "Месяц", code: 4 },
  { value: "QUARTER", label: "Квартал", code: 5 },
  { value: "YEAR", label: "Год", code: 6 },
];

const datePicker = {
  DAY: "date",
  TWEEK: "week",
  CWEEK: "week",
  MONTH: "month",
  QUARTER: "quarter",
  YEAR: "year",
} as const;
