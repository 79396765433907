import Reorder from "@mui/icons-material/Reorder";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Edge, Node } from "@xyflow/react";
import { Button, Input, Popconfirm, Select, message } from "antd";
import Empty from "antd/lib/empty";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided,
  ResponderProvided,
} from "react-beautiful-dnd";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";
import IntegrationFieldsManager from "../utils/fields";

interface Props {
  id: string;
  nodes: Node[];
  edges: Edge[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
}

const { TextArea } = Input;

const MappingWindow: React.FC<Props> = ({
  id,
  nodes,
  edges,
  setNodes,
  setEdges,
}) => {
  const [nodeName, setNodeName] = useState<string>(id);
  const [editNodeNameStatus, setEditNodeNameStatus] = useState<boolean>(false);

  const [SourceId, setSourceId] = useState<string | null>(null);

  const [lFields, setLFields] = useState<MappingField[]>([]);
  const [rFields, setRFields] = useState<MappingField[]>([]);
  const [rFieldsDict, setRFieldsDict] = useState<{
    [key: string]: MappingField;
  }>({});
  const [selectedTransform, setSelectedTransform] = useState<string | null>(
    null,
  );

  const [whereCond, setWhereCond] = useState<string>("");
  const [editWhereCondStatus, setEditWhereCondStatus] =
    useState<boolean>(false);

  const [fieldsEditList, setFieldsEditList] = useState<string[]>([]);

  const onChangeNodeId = () => {
    IntegrationManager.changeNodeId(
      id,
      nodeName,
      nodes,
      () => {},
      setNodes,
      setEdges,
      setEditNodeNameStatus,
    );
  };

  const handleDragEnd = (result: DropResult, provided?: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    setRFields((prev: any) => {
      const temp = [...prev];
      const d = temp[result.destination!.index];
      temp[result.destination!.index] = temp[result.source.index];
      temp[result.source.index] = d;
      return temp;
    });
  };

  useEffect(() => {
    const node: any = nodes.find((node) => node.id === id);

    if (node) {
      setSelectedTransform(null);
      setWhereCond(node.data.filter || "");
      setNodeName(id);
      IntegrationManager.initial(
        id,
        () => {},
        nodes,
        edges,
        setLFields,
        setRFields,
        setSourceId,
        () => {},
      );
    }
  }, [id]);

  useEffect(() => {
    if (rFields) {
      setNodes((prev) => {
        const res = prev.map((rec) => {
          if (rec.id === id) {
            rec.data.formula = rFields;
          }
          return rec;
        });
        return res;
      });
      let res: { [key: string]: MappingField } = {};
      for (const field of rFields) {
        res[field.uuid] = field;
      }
      setRFieldsDict(res);
    }
  }, [rFields]);

  useEffect(() => {
    setNodes((prev) => {
      const res = prev.map((rec) => {
        if (rec.id === id) {
          rec.data.filter = whereCond;
        }
        return rec;
      });
      return res;
    });
  }, [whereCond]);

  const [textAreaValue, setTextAreaValue] = useState("");

  useEffect(() => {
    if (selectedTransform) {
      setTextAreaValue(rFieldsDict[selectedTransform]?.formula || "");
    } else {
      setTextAreaValue("");
    }
  }, [selectedTransform, rFieldsDict]);

  const handleTextAreaChange = (e: any) => {
    const newValue = e.target.value;
    setTextAreaValue(newValue);
    // Теперь обновляем глобальное состояние
    setRFields((prev) =>
      prev.map((field) => {
        if (field.uuid === selectedTransform) {
          return { ...field, formula: newValue };
        }
        return field;
      }),
    );
  };

  const [counter, setCounter] = useState<number>(0);

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Наименование ноды
          </div>
          <Input
            value={nodeName}
            status={
              nodeName.toString().match(/^[A-Za-z][A-Za-z0-9\-_]*$/)
                ? ""
                : "error"
            }
            disabled={!editNodeNameStatus}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => {
              if (!el.target.value.match(/^[A-Za-z][A-Za-z0-9\-_]*$/)) {
                message.warning(
                  "Вводимое наименование содержит недопустимые символы",
                );
              }
              setNodeName(el.target.value);
            }}
            allowClear
          />
          {!editNodeNameStatus && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditNodeNameStatus(true)}
            >
              ✏️
            </Button>
          )}
          {editNodeNameStatus && (
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <Button style={{ width: "50px" }} onClick={onChangeNodeId}>
                💾
              </Button>
              <Button
                style={{ width: "50px" }}
                onClick={() => {
                  setNodeName(id);
                  setEditNodeNameStatus(false);
                }}
              >
                🔄
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Фильтр</div>
          <Input.TextArea
            value={whereCond}
            disabled={!editWhereCondStatus}
            style={{ width: "600px", color: "black" }}
            onChange={(el) => setWhereCond(el.target.value)}
            allowClear
          />
          {!editWhereCondStatus && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditWhereCondStatus(true)}
            >
              ✏️
            </Button>
          )}
          {editWhereCondStatus && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditWhereCondStatus(false)}
            >
              💾
            </Button>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            height: "500px",
            overflow: "auto",
          }}
        >
          <div>
            <div style={{ fontWeight: "bold" }}>
              ⤵️ Входящий поток: {SourceId}
            </div>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      <Button
                        type="dashed"
                        onClick={() =>
                          IntegrationFieldsManager.moveAllFieldsToRight({
                            sourceFields: lFields,
                            setTargetFields: setRFields,
                            counter,
                            setCounter,
                          })
                        }
                      >
                        ↘
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lFields?.length > 0 ? (
                    lFields.map((el: MappingField, ind: number) => (
                      <TableRow key={ind}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {IntegrationFieldsManager.getSourceFieldStatus(
                            el.id,
                            rFields,
                          )}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.id}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.desc}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.type}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          <Button
                            type="dashed"
                            onClick={() =>
                              IntegrationFieldsManager.moveFieldToTarget(
                                el.id,
                                lFields,
                                setRFields,
                              )
                            }
                          >
                            ➡
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={100}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет входящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "bold" }}>
              ⤴️ Исходящий поток: {nodeName}
            </div>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "225px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Действие
                    </TableCell>
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(droppableProvided: DroppableProvided) => (
                      <TableBody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {rFields?.length > 0 ? (
                          rFields.map((el: MappingField, ind: number) => (
                            <Draggable
                              key={el.uuid}
                              draggableId={el.uuid}
                              index={ind}
                            >
                              {(
                                draggableProvided: DraggableProvided,
                                snapshot: DraggableStateSnapshot,
                              ) => (
                                <TableRow
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                >
                                  <TableCell>
                                    <div {...draggableProvided.dragHandleProps}>
                                      <Reorder />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{ padding: "10px", height: "32px" }}
                                  >
                                    {el.formula && el.formula.length > 0
                                      ? "✅"
                                      : "⭕"}
                                  </TableCell>
                                  <TableCell
                                    style={{ padding: "10px", height: "25px" }}
                                  >
                                    <Input
                                      value={rFieldsDict[el.uuid]?.id || ""}
                                      onChange={(e) => {
                                        const newValue = e.target.value;
                                        setRFields((prev) =>
                                          prev.map((field) => {
                                            if (field.uuid === el.uuid) {
                                              return { ...field, id: newValue };
                                            }
                                            return field;
                                          }),
                                        );
                                      }}
                                      disabled={
                                        !fieldsEditList.includes(el.uuid)
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{ padding: "10px", height: "25px" }}
                                  >
                                    <Input
                                      value={rFieldsDict[el.uuid]?.desc || ""}
                                      onChange={(e) => {
                                        const newValue = e.target.value;
                                        setRFields((prev) =>
                                          prev.map((field) => {
                                            if (field.uuid === el.uuid) {
                                              return {
                                                ...field,
                                                desc: newValue,
                                              };
                                            }
                                            return field;
                                          }),
                                        );
                                      }}
                                      disabled={
                                        !fieldsEditList.includes(el.uuid)
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{ padding: "10px", height: "25px" }}
                                  >
                                    <Select
                                      popupMatchSelectWidth={110}
                                      style={{ width: "110px" }}
                                      value={rFieldsDict[el.uuid]?.type || ""}
                                      onChange={(e) => {
                                        const newValue = e;
                                        setRFields((prev) =>
                                          prev.map((field: any) => {
                                            if (field.uuid === el.uuid) {
                                              return {
                                                ...field,
                                                type: newValue,
                                              };
                                            }
                                            return field;
                                          }),
                                        );
                                      }}
                                      options={__optionType}
                                      disabled={
                                        !fieldsEditList.includes(el.uuid)
                                      }
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{ padding: "10px", height: "25px" }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "5px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Button
                                        onClick={() =>
                                          setSelectedTransform(el.uuid)
                                        }
                                        disabled={fieldsEditList.includes(
                                          el.uuid,
                                        )}
                                      >
                                        🔎
                                      </Button>
                                      {!fieldsEditList.includes(el.uuid) && (
                                        <Button
                                          onClick={() =>
                                            IntegrationFieldsManager.enableFieldEditMode(
                                              el.uuid,
                                              setFieldsEditList,
                                            )
                                          }
                                        >
                                          ✏️
                                        </Button>
                                      )}
                                      {fieldsEditList.includes(el.uuid) && (
                                        <Button
                                          onClick={() =>
                                            IntegrationFieldsManager.disableFieldEditMode(
                                              el.uuid,
                                              rFields,
                                              setFieldsEditList,
                                            )
                                          }
                                        >
                                          💾
                                        </Button>
                                      )}
                                      <Popconfirm
                                        title={`Вы действительно хотите удалить поле ${el.id}?`}
                                        okText="Да"
                                        cancelText="Нет"
                                        onConfirm={() =>
                                          IntegrationFieldsManager.removeField(
                                            el.uuid,
                                            setRFields,
                                          )
                                        }
                                      >
                                        <Button>❌</Button>
                                      </Popconfirm>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              )}
                            </Draggable>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={100}>
                              <Empty
                                imageStyle={{ height: "50px" }}
                                description="Нет исходящих полей"
                              />
                            </TableCell>
                          </TableRow>
                        )}
                        {droppableProvided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </div>
            <Button
              type="dashed"
              style={{ marginTop: "5px", width: "100%" }}
              onClick={() =>
                IntegrationFieldsManager.addField(
                  rFields,
                  setRFields,
                  setFieldsEditList,
                )
              }
            >
              Добавить поле
            </Button>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span>
            Параметр мэппинга:{" "}
            {rFields && rFields.find((el) => el.uuid === selectedTransform)?.id}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              height: "100px",
            }}
          >
            <TextArea
              size="large"
              style={{ height: "100px", resize: "none" }}
              disabled={!selectedTransform}
              value={textAreaValue}
              onChange={handleTextAreaChange}
            />
            <Button
              style={{ width: "100px", height: "100px", fontSize: "36px" }}
              onClick={() => console.log(nodes)}
            >
              👁
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MappingWindow;

const __optionType = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];
