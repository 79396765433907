import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Edge, Node } from "@xyflow/react";
import { Button, Input, Select, message } from "antd";
import Empty from "antd/lib/empty";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";

type Props = {
  id: string;
  nodes: Node[];
  edges: Edge[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
};

const { TextArea } = Input;

const FilterWindow: React.FC<Props> = ({
  id,
  nodes,
  edges,
  setNodes,
  setEdges,
}) => {
  const [nodeName, setNodeName] = useState<string>(id);
  const [editNodeNameStatus, setEditNodeNameStatus] = useState<boolean>(false);

  const [rFields, setRFields] = useState<MappingField[]>([]);
  const [rFieldsDict, setRFieldsDict] = useState<{
    [key: string]: MappingField;
  }>({});
  const [selectedTransform, setSelectedTransform] = useState<string | null>(
    null,
  );

  const onChangeNodeId = () => {
    IntegrationManager.changeNodeId(
      id,
      nodeName,
      nodes,
      () => {},
      setNodes,
      setEdges,
      setEditNodeNameStatus,
    );
  };

  useEffect(() => {
    setSelectedTransform(null);
    setNodeName(id);

    const node = nodes.find((node) => node.id === id)!;

    const fields: any[] = [];

    const leftEdge = edges.find((edge) => edge.target === id);
    if (leftEdge) {
      const leftNode = nodes.find((node) => node.id === leftEdge.source)!;

      ((leftNode.data.formula as any[]) ?? []).forEach((field) => {
        const findF = [...((node.data.formula as any[]) ?? [])].find(
          (f) => f.id === field.id,
        );
        const newF = findF ? { ...field, ...findF } : field;
        fields.push(newF);
      });
    }

    setRFields(fields);
  }, [id]);

  useEffect(() => {
    if (rFields && rFields.length > 0) {
      setNodes((prev) => {
        const res = prev.map((rec) => {
          if (rec.id === id) {
            rec.data.formula = rFields;
          }
          return rec;
        });
        return res;
      });
      let res: { [key: string]: MappingField } = {};
      for (const field of rFields) {
        res[field.uuid] = field;
      }
      setRFieldsDict(res);
    }
  }, [rFields]);

  const [textAreaValue, setTextAreaValue] = useState("");

  useEffect(() => {
    if (selectedTransform) {
      setTextAreaValue(rFieldsDict[selectedTransform]?.filter || "");
    } else {
      setTextAreaValue("");
    }
  }, [selectedTransform, rFieldsDict]);

  const handleTextAreaChange = (e: any) => {
    const newValue = e.target.value;
    setTextAreaValue(newValue);
    // Теперь обновляем глобальное состояние
    setRFields((prev) =>
      prev.map((field) => {
        if (field.uuid === selectedTransform) {
          return { ...field, filter: newValue };
        }
        return field;
      }),
    );
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Наименование ноды
          </div>
          <Input
            value={nodeName}
            status={
              nodeName.toString().match(/^[A-Za-z][A-Za-z0-9\-_]*$/)
                ? ""
                : "error"
            }
            disabled={!editNodeNameStatus}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => {
              if (!el.target.value.match(/^[A-Za-z][A-Za-z0-9\-_]*$/)) {
                message.warning(
                  "Вводимое наименование содержит недопустимые символы",
                );
              }
              setNodeName(el.target.value);
            }}
            allowClear
          />
          {!editNodeNameStatus && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditNodeNameStatus(true)}
            >
              ✏️
            </Button>
          )}
          {editNodeNameStatus && (
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <Button style={{ width: "50px" }} onClick={onChangeNodeId}>
                💾
              </Button>
              <Button
                style={{ width: "50px" }}
                onClick={() => {
                  setNodeName(id);
                  setEditNodeNameStatus(false);
                }}
              >
                🔄
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            height: "500px",
            overflow: "auto",
          }}
        >
          <div>
            <span style={{ fontWeight: "bold" }}>
              ⤴️ Входящий/Исходящий поток: {nodeName}
            </span>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "20px",
                      padding: "10px",
                      height: "32px",
                    }}
                  ></TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Идентификатор
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "250px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Описание
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Тип
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Действие
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rFields?.length > 0 ? (
                  rFields.map((el: MappingField, ind: number) => (
                    <TableRow key={ind}>
                      <TableCell style={{ padding: "10px", height: "32px" }}>
                        {el.filter && el.filter.length > 0 ? "✅" : "⭕"}
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Input
                          value={rFieldsDict[el.uuid]?.id || ""}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setRFields((prev) =>
                              prev.map((field) => {
                                if (field.uuid === el.uuid) {
                                  return { ...field, id: newValue };
                                }
                                return field;
                              }),
                            );
                          }}
                          disabled={true}
                        />
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Input
                          value={rFieldsDict[el.uuid]?.desc || ""}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setRFields((prev) =>
                              prev.map((field) => {
                                if (field.uuid === el.uuid) {
                                  return { ...field, desc: newValue };
                                }
                                return field;
                              }),
                            );
                          }}
                          disabled={true}
                        />
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Select
                          style={{ width: "100%" }}
                          value={rFieldsDict[el.uuid]?.type || ""}
                          onChange={(e) => {
                            const newValue = e;
                            setRFields((prev) =>
                              prev.map((field: any) => {
                                if (field.uuid === el.uuid) {
                                  return { ...field, type: newValue };
                                }
                                return field;
                              }),
                            );
                          }}
                          options={__optionType}
                          disabled={true}
                        />
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Button onClick={() => setSelectedTransform(el.uuid)}>
                          🔎
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={100}>
                      <Empty
                        imageStyle={{ height: "50px" }}
                        description="Нет входящих полей"
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span>
            Параметр фильтра:{" "}
            {rFields && rFields.find((el) => el.uuid === selectedTransform)?.id}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              height: "100px",
            }}
          >
            <TextArea
              size="large"
              style={{ height: "100px", resize: "none" }}
              disabled={!selectedTransform}
              value={textAreaValue}
              onChange={handleTextAreaChange}
            />
            <Button
              style={{ width: "100px", height: "100px", fontSize: "36px" }}
              onClick={() =>
                IntegrationManager.initial(
                  id,
                  () => {},
                  nodes,
                  edges,
                  () => {},
                  setRFields,
                  () => {},
                  setNodeName,
                )
              }
            >
              👁
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterWindow;

const __optionType = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];
