import ExclamationCircleFilled from "@ant-design/icons/lib/icons/ExclamationCircleFilled";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton } from "@mui/material";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Modal, Tooltip } from "antd";
import notification from "antd/lib/notification";
import type {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { useContext, useMemo, useState } from "react";
import { Context } from "../..";
import ObjectService from "../../entities/model/ObjectService";
import Button from "../../shared/components/button";
import CreateMenu from "./createMenu";
import Menu from "./menu";

const { confirm } = Modal;

type GetObjectsResponse = {
  code: number;
  text?: string;
  data: {
    count: number;
    columns: { id: string; name: string; key: boolean }[];
    data: Record<string, unknown>[];
  };
};

type Props = {};

const ObjectViewerPage: React.FC<Props> = () => {
  const { store } = useContext(Context);
  store.setPageName("Просмотр объектов");

  const [menuState, setMenuState] = useState(false);
  const [createMenuState, setCreateMenuState] = useState(false);

  const [model, setModel] = useState<string>();
  const [level, setLevel] = useState<string>();

  const onMenuOk = ({ model, level }: { model: string; level: string }) => {
    setModel(model);
    setLevel(level);
    setMenuState(false);
  };

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState<MRT_ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const {
    data: { count, columns, data } = { count: 0, columns: [], data: [] },
    isError,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery<GetObjectsResponse["data"]>({
    queryKey: [
      "objects-data",
      model,
      level,
      filters,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    refetchOnWindowFocus: false,
    retry: false,
    placeholderData: keepPreviousData,
    queryFn: async () => {
      if (!model || !level) return { columns: [], data: [] };

      const response = await ObjectService.getObjects(model, level, {
        page: pagination.pageIndex,
        size: pagination.pageSize,
        filters,
        sorting,
      });

      if (response.code !== 1) {
        throw new Error(`Не удалось загрузить объекты: "${response.text}"`);
      }

      return response.data;
    },
  });

  const primaryKey = useMemo(() => columns.filter(({ key }) => key), [columns]);

  const { data: attributeValues = {} } = useQuery<Record<string, unknown[]>>({
    queryKey: ["attribute-values", model, level, primaryKey],
    refetchOnWindowFocus: false,
    retry: false,
    queryFn: async () => {
      if (!model || !level || primaryKey.length === 0) return {};

      const promises = primaryKey.map(async ({ id }) => {
        const response = await ObjectService.getAttributeValues(
          model,
          level,
          id,
        );
        return { id, response };
      });

      const responses = await Promise.all(promises);

      const isError = responses.reduce(
        (isError, response) => isError || response.response.code !== 1,
        false,
      );

      if (isError) {
        throw new Error(
          `Не удалось загрузить значения по одному или нескольким атрибутам`,
        );
      }

      const data = responses.reduce(
        (acc, { id, response }) => ({ ...acc, [id]: response.data }),
        {},
      );

      return data;
    },
  });

  const MRT_columns: MRT_ColumnDef<any>[] = columns.map(({ id, name }) => ({
    accessorKey: id,
    header: name,
  }));

  const table = useMaterialReactTable({
    data,
    columns: MRT_columns,

    initialState: {
      density: "compact",
    },

    rowCount: count,

    state: {
      columnFilters: filters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },

    localization: MRT_Localization_RU,

    enableGrouping: false,
    groupedColumnMode: "remove",

    enableStickyHeader: true,
    enableGlobalFilter: false,

    enableRowSelection: true,

    muiToolbarAlertBannerProps: isError
      ? { color: "error", children: "Возникла ошибка при загрузке данных" }
      : undefined,

    manualFiltering: true,
    onColumnFiltersChange: setFilters,

    manualPagination: true,
    onPaginationChange: setPagination,

    manualSorting: true,
    onSortingChange: setSorting,

    renderTopToolbarCustomActions: () => (
      <div style={{ display: "flex", alignItems: "start" }}>
        <Tooltip arrow title="Обновить">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>

        <Tooltip arrow title="Удалить">
          <IconButton
            disabled={table.getSelectedRowModel().rows.length === 0}
            onClick={() => {
              confirm({
                title:
                  "Вы уверены, что хотите удалить выбранные объекты планирования?",
                icon: <ExclamationCircleFilled />,
                content:
                  "Данное действие безвозвратно удалит выбранные объекты и соответствующие им данные.",
                async onOk() {
                  if (!model || !level) {
                    throw new Error("Необходимо выбрать модель и уровень");
                  }

                  const primaryKeys = columns
                    .filter(({ key }) => key)
                    .map(({ id }) => id);

                  const rows = table.getSelectedRowModel().rows;

                  const objects = rows
                    .map((row) => data[row.index])
                    .map(Object.entries)
                    .map((entries) =>
                      entries.filter(([key, _]) => primaryKeys.includes(key)),
                    )
                    .map(Object.fromEntries);

                  await ObjectService.deleteObjects(model, level, objects);

                  table.setRowSelection({});
                  refetch();
                },
              });
            }}
          >
            <DeleteForeverOutlinedIcon />
          </IconButton>
        </Tooltip>

        <Tooltip arrow title="Добавить">
          <IconButton
            disabled={!model || !level || isLoading || isError}
            onClick={() => setCreateMenuState(true)}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </div>
    ),
  });

  const onCreateMenuOk = async ({
    objects,
  }: {
    objects: Record<string, string>[];
  }) => {
    if (!model || !level) return;

    // objects have to contain primary keys
    for (const object of objects) {
      for (const { id, key } of columns) {
        if (key) {
          if (!object[id]) {
            notification.error({ message: `Все поля должны быть заполнены` });
            return;
          }
        }
      }
    }

    const response = await ObjectService.createObjects(model, level, objects);

    if (response.code !== 1) {
      notification.error({
        message: `Произошла ошибка при добавлении объектов планирования: "${response.text}"`,
      });
      return;
    }

    setCreateMenuState(false);
    refetch();
  };

  return (
    <>
      <Menu
        state={menuState}
        onOk={onMenuOk}
        onCancel={() => setMenuState(false)}
      />

      {model && level && (
        <CreateMenu
          state={createMenuState}
          onOk={onCreateMenuOk}
          onCancel={() => setCreateMenuState(false)}
          columns={primaryKey}
          values={attributeValues}
        />
      )}

      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            gap: "15px",
            width: "100%",
            justifyContent: "flex-start",
          }}
        >
          <Button
            type="primary"
            label="Настроить отображение"
            onClick={() => setMenuState(true)}
          />
        </div>
        <div>
          <MaterialReactTable table={table} />
        </div>
      </div>
    </>
  );
};

export default ObjectViewerPage;
