import { Button, Checkbox, Input, Select } from "antd";
import { useContext } from "react";
import { Context } from "../../..";

const CreateOperatorPage: React.FC = () => {
  const { store } = useContext(Context);
  store.setPageName("Создание оператора");

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <span>1. Идентификатор</span>
        <Input style={{ width: "300px" }} />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <span>2. Оператор</span>
        <Input style={{ width: "300px" }} />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <span>3. Модель</span>
        <Select style={{ width: "300px" }} />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <span>4. Настройки</span>
        <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <span>Показатель-источник</span>
            <Select style={{ width: "200px" }} />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <span>Показатель-цель</span>
            <Select style={{ width: "200px" }} />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <span>Режим очистки цели</span>
            <Checkbox />
          </div>
        </div>
      </div>
      <div>
        <Button size="large" type="primary">
          Создать
        </Button>
      </div>
    </div>
  );
};

export default CreateOperatorPage;
