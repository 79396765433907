import { getConnectedEdges, Handle, useNodeId, useStore } from "@xyflow/react";
import { useMemo, useState } from "react";
import "./customHandle.css";

const selector = (s: any) => ({
  nodeLookup: s.nodeLookup,
  edges: s.edges,
});

const CustomHandle = (props: any) => {
  const { nodeLookup, edges } = useStore(selector);
  const [connected, setConnected] = useState<boolean>(false);
  const nodeId = useNodeId();
  let count: number = 0;

  const isHandleConnectable = useMemo(() => {
    setConnected(false);
    const node = nodeLookup.get(nodeId);
    const connectedEdges = getConnectedEdges([node], edges);

    connectedEdges.map((el: any) => {
      if (el.source === nodeId && props.type === "source") {
        setConnected(true);
      }
    });

    if (typeof props.isConnectable === "function") {
      return props.isConnectable({ node, connectedEdges });
    }

    if (typeof props.isConnectable === "number") {
      let asInputCount = 0;
      for (const conn of connectedEdges) {
        if (conn.target === nodeId) {
          asInputCount++;

          if (props.type === "target") {
            setConnected(true);
          }
        }
      }

      return asInputCount < props.isConnectable;
    }

    return props.isConnectable;
  }, [nodeLookup, edges, nodeId, props.isConnectable]);

  return (
    <Handle
      {...props}
      isConnectable={isHandleConnectable}
      style={{
        height: "16px",
        marginLeft: "4px",
        marginRight: "4px",
        marginTop: "8px",
        width: "8px",
        borderRadius:
          props.type === "source"
            ? "100% 0 0 100% / 50% 0 0 50%"
            : "0 100% 100% 0 / 0 50% 50% 0",
        backgroundColor: "#FFFFFF",
        opacity: connected ? "70%" : "30%",
      }}
    ></Handle>
  );
};

export default CustomHandle;
