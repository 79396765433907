import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Edge, Node } from "@xyflow/react";
import { Button, Input, Select, message, notification } from "antd";
import Empty from "antd/lib/empty";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import LevelService from "../../../../../entities/model/LevelService";
import ModelService from "../../../../../entities/model/ModelService";
import VersionService from "../../../../../entities/model/VersionService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";
import IntegrationFieldsManager from "../utils/fields";

const NEW_VERSION_ID = "__NEW__";
const NEW_VERSION_NAME = "➕ Новая версия";

const DEFAULT_VERSION_ID = "__BASE__";
const DEFAULT_VERSION_NAME = "🧱 Базовая";

enum VersionType {
  NEW = "new",
  BASE = "base",
  VARIABLE = "variable",
  CONSTANT = "constant",
}

type Props = {
  id: string;
  nodes: Node[];
  edges: Edge[];
  variables: any[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
  setVariables: Dispatch<SetStateAction<any[]>>;
};

const { TextArea } = Input;

const PlanXFigureOutputWindow: React.FC<Props> = ({
  id,
  nodes,
  edges,
  variables,
  setNodes,
  setEdges,
}) => {
  const [nodeName, setNodeName] = useState<string>(id);
  const [editNodeNameStatus, setEditNodeNameStatus] = useState<boolean>(false);

  const [SourceId, setSourceId] = useState<string | null>(null);

  const [lFields, setLFields] = useState<MappingField[]>([]);
  const [rFields, setRFields] = useState<MappingField[]>([]);
  const [rFieldsDict, setRFieldsDict] = useState<{
    [key: string]: MappingField;
  }>({});
  const [selectedTransform, setSelectedTransform] = useState<string | null>(
    null,
  );

  const [fields, setFields] = useState<MappingField[]>([]);

  const onChangeNodeId = () => {
    IntegrationManager.changeNodeId(
      id,
      nodeName,
      nodes,
      () => {},
      setNodes,
      setEdges,
      setEditNodeNameStatus,
    );
  };

  useEffect(() => {
    setSelectedTransform(null);
    handleEdges();
    setNodeName(id);
    const node: any = nodes.find((el) => el.id === id)?.data;
    if (node) {
      setFields(node.formula);
      setModelSelected(node.model);
      setLevelSelected(node.level);
      const version =
        !node.version || node.version === ""
          ? DEFAULT_VERSION_ID
          : node.version;
      setVersionSelected(version.value);
    }
  }, [id]);

  const [modelOptions, setModelOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [modelSelected, setModelSelected] = useState<string>("");

  const [levelOptions, setLevelOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [levelSelected, setLevelSelected] = useState<string>("");

  const [versions, setVersions] = useState<{ label: string; value: string }[]>(
    [],
  );
  const [versionSelected, setVersionSelected] =
    useState<string>(DEFAULT_VERSION_ID);

  const versionOptions = useMemo(
    () => [
      { label: NEW_VERSION_NAME, value: NEW_VERSION_ID, type: VersionType.NEW },
      {
        label: DEFAULT_VERSION_NAME,
        value: DEFAULT_VERSION_ID,
        type: VersionType.BASE,
      },
      ...variables.map((variable) => ({
        label: `🧮 ${variable.desc}`,
        value: variable.id,
        type: VersionType.VARIABLE,
      })),
      ...versions.map((version) => ({
        ...version,
        type: VersionType.CONSTANT,
      })),
    ],
    [versions, variables],
  );

  console.log({
    nodes,
    edges,
    variables,
    versions,
    versionOptions,
    versionSelected,
  });

  const getModelList = async () => {
    const getModels = await ModelService.getAll();
    if (getModels.code === 1) {
      setModelOptions((prevOptions: any) =>
        getModels.data.map((el: any) => ({
          label: el.name,
          value: el.id,
        })),
      );
    } else {
      notification.error({
        message: getModels.text,
        description: `Код ответа: ${getModels.code}`,
      });
    }
  };

  useEffect(() => {
    getModelList();
  }, []);

  const getLevelList = async () => {
    if (!modelSelected || modelSelected === "") {
      return;
    }
    const getLevels = await LevelService.getAll(modelSelected);
    if (getLevels.code === 1) {
      setLevelOptions((prevOptions: any) =>
        getLevels.data.map((el: any) => ({
          label: el.name,
          value: el.id,
        })),
      );
    }
  };

  useEffect(() => {
    getLevelList();
  }, [modelSelected]);

  const getVersionList = async () => {
    if (
      !modelSelected ||
      modelSelected === "" ||
      !levelSelected ||
      levelSelected === ""
    ) {
      return;
    }

    const getVersions = await VersionService.getAll({
      modelId: modelSelected,
      levelId: levelSelected,
    });

    if (getVersions.code === 1) {
      setVersions(
        getVersions.data.map((version: any) => ({
          label: version.name,
          value: version.id,
        })),
      );
    }
  };

  useEffect(() => {
    getVersionList();
  }, [modelSelected, levelSelected]);

  const getFields = async () => {
    if (!levelSelected || levelSelected === "") {
      return;
    }

    const getAttributes = await LevelService.getAttributes(
      modelSelected,
      levelSelected,
    );
    if (getAttributes.code !== 1) {
      return;
    }
    const keyAttributes = getAttributes.data.filter((el: any) => el.key);

    const getFigures = await LevelService.getFigures(
      modelSelected,
      levelSelected,
    );
    if (getFigures.code !== 1) {
      return;
    }

    const getPeriod = await LevelService.getPeriod(
      modelSelected,
      levelSelected,
    );
    if (getPeriod.code !== 1) {
      return;
    }

    const fields: MappingField[] = [];
    for (const key of keyAttributes) {
      fields.push({
        uuid: uuidv4(),
        id: key.attributeId,
        desc: key.attributeName,
        type: "STRING",
      });
    }

    if (getPeriod.data > 0) {
      fields.push({
        uuid: uuidv4(),
        id: "DATE",
        desc: "Дата",
        type: "DATE",
      });
    }

    for (const figure of getFigures.data) {
      fields.push({
        uuid: figure.uuid,
        id: "F_" + figure.id,
        desc: figure.name,
        type: "DECIMAL",
      });
    }

    setFields(fields);
  };

  useEffect(() => {
    getFields();
  }, [levelSelected]);

  useEffect(() => {
    if (rFields) {
      setNodes((prev) => {
        const res = prev.map((rec) => {
          if (rec.id === id) {
            rec.data.formula = rFields;
          }
          return rec;
        });
        return res;
      });
      let res: { [key: string]: MappingField } = {};
      for (const field of rFields) {
        res[field.uuid] = field;
      }
      setRFieldsDict(res);
    }
  }, [rFields]);

  const handleEdges = () => {
    setNodeName(id);
    const fNode: any = nodes.find((el) => el.id === id)?.data;
    if (!fNode) {
      // message.error('Не обнаружена нода. Возможно, вы ее удалили перед тем как открыть.');
      return;
    }
    setRFields(fNode.formula);
    const findEdges = edges.find((el) => el.target === id);
    if (findEdges) {
      setSourceId(findEdges.source);
      const findSourceNode: any = nodes.find(
        (el) => el.id === findEdges.source,
      );
      if (findSourceNode?.data.formula) {
        setLFields(findSourceNode?.data.formula);
      } else {
        message.warning(
          `Обратите внимание, что в ноде ${findEdges.source} не заданы поля`,
        );
      }
    } else {
      message.warning(
        `Обратите внимание, что в эту ноду (${id}) не входит поток данных`,
      );
    }
  };

  useEffect(() => {
    setNodes((prev) => {
      const res = prev.map((rec) => {
        if (rec.id === id) {
          rec.data.formula = fields;
          rec.data.model = modelSelected;
          rec.data.level = levelSelected;
          if (versionSelected && versionSelected !== "") {
            rec.data.version = versionOptions.find(
              (option) => option.value === versionSelected,
            );
          }
        }
        return rec;
      });
      return res;
    });

    if (fields) {
      setRFields((prev) => {
        return fields.map((el) => {
          const filter = prev.find((z) => el.id === z.id);
          if (filter) {
            el.formula = filter.formula;
          }
          return el;
        });
      });
    }
  }, [fields, modelSelected, levelSelected, versionSelected]);

  const [textAreaValue, setTextAreaValue] = useState("");

  useEffect(() => {
    if (selectedTransform) {
      setTextAreaValue(rFieldsDict[selectedTransform]?.formula || "");
    } else {
      setTextAreaValue("");
    }
  }, [selectedTransform, rFieldsDict]);

  const handleTextAreaChange = (e: any) => {
    const newValue = e.target.value;
    setTextAreaValue(newValue);
    // Теперь обновляем глобальное состояние
    setRFields((prev) =>
      prev.map((field) => {
        if (field.uuid === selectedTransform) {
          return { ...field, formula: newValue };
        }
        return field;
      }),
    );
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Наименование ноды
          </div>
          <Input
            value={nodeName}
            status={
              nodeName.toString().match(/^[A-Za-z][A-Za-z0-9\-_]*$/)
                ? ""
                : "error"
            }
            disabled={!editNodeNameStatus}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => {
              if (!el.target.value.match(/^[A-Za-z][A-Za-z0-9\-_]*$/)) {
                message.warning(
                  "Вводимое наименование содержит недопустимые символы",
                );
              }
              setNodeName(el.target.value);
            }}
            allowClear
          />
          {!editNodeNameStatus && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditNodeNameStatus(true)}
            >
              ✏️
            </Button>
          )}
          {editNodeNameStatus && (
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <Button style={{ width: "50px" }} onClick={onChangeNodeId}>
                💾
              </Button>
              <Button
                style={{ width: "50px" }}
                onClick={() => {
                  setNodeName(id);
                  setEditNodeNameStatus(false);
                }}
              >
                🔄
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Модель</div>
          <Select
            value={modelSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setModelSelected(el)}
            options={modelOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Уровень</div>
          <Select
            value={levelSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setLevelSelected(el)}
            options={levelOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Версия</div>
          <Select
            value={versionSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(version) => setVersionSelected(version)}
            options={versionOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            height: "500px",
            overflow: "auto",
          }}
        >
          <div>
            <span style={{ fontWeight: "bold" }}>
              ⤵️ Входящий поток: {SourceId}
            </span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      <Button
                        type="dashed"
                        onClick={() =>
                          IntegrationFieldsManager.mapAllFieldsToRight({
                            sourceFields: lFields,
                            setTargetFields: setRFields,
                          })
                        }
                      >
                        ↘
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lFields?.length > 0 ? (
                    lFields.map((el: MappingField, ind: number) => (
                      <TableRow key={ind}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {IntegrationFieldsManager.getSourceFieldStatus(
                            el.id,
                            rFields,
                          )}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.id}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.desc}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.type}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          <Button
                            type="dashed"
                            onClick={() =>
                              IntegrationFieldsManager.mapFieldToTarget(
                                el.id,
                                lFields,
                                setRFields,
                              )
                            }
                          >
                            ➡
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={100}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет входящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>
              ⤴️ Исходящий поток: {nodeName}
            </span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Действие
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rFields?.length > 0 ? (
                    rFields.map((el: MappingField, ind: number) => (
                      <TableRow key={ind}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.formula && el.formula.length > 0 ? "✅" : "⭕"}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Input
                            value={rFieldsDict[el.uuid]?.id || ""}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRFields((prev) =>
                                prev.map((field) => {
                                  if (field.uuid === el.uuid) {
                                    return { ...field, id: newValue };
                                  }
                                  return field;
                                }),
                              );
                            }}
                            disabled={true}
                          />
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Input
                            value={rFieldsDict[el.uuid]?.desc || ""}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRFields((prev) =>
                                prev.map((field) => {
                                  if (field.uuid === el.uuid) {
                                    return { ...field, desc: newValue };
                                  }
                                  return field;
                                }),
                              );
                            }}
                            disabled={true}
                          />
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Select
                            style={{ width: "100%" }}
                            value={rFieldsDict[el.uuid]?.type || ""}
                            onChange={(e) => {
                              const newValue = e;
                              setRFields((prev) =>
                                prev.map((field: any) => {
                                  if (field.uuid === el.uuid) {
                                    return { ...field, type: newValue };
                                  }
                                  return field;
                                }),
                              );
                            }}
                            options={__optionType}
                            disabled={true}
                          />
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Button onClick={() => setSelectedTransform(el.uuid)}>
                            🔎
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={100}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет исходящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span>
            Параметр мэппинга:{" "}
            {rFields && rFields.find((el) => el.uuid === selectedTransform)?.id}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              height: "100px",
            }}
          >
            <TextArea
              size="large"
              style={{ height: "100px", resize: "none" }}
              disabled={!selectedTransform}
              value={textAreaValue}
              onChange={handleTextAreaChange}
            />
            <Button
              style={{ width: "100px", height: "100px", fontSize: "36px" }}
              onClick={handleEdges}
            >
              👁
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanXFigureOutputWindow;

const __optionType = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];
