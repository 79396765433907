import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Edge, Node } from "@xyflow/react";
import { Button, Input, Select, message } from "antd";
import Empty from "antd/lib/empty";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import FlatFileService from "../../../../../entities/model/FlatFileService";
import { IFileFormat } from "../../../../../entities/types/IFileFormat";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";
import IntegrationFieldsManager from "../utils/fields";

type Props = {
  id: string;
  nodes: Node[];
  edges: Edge[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
};

interface Format {
  value: string;
  label: string;
}

const { TextArea } = Input;

const TargetFlatFileWindow: React.FC<Props> = ({
  id,
  nodes,
  edges,
  setNodes,
  setEdges,
}) => {
  const [nodeName, setNodeName] = useState<string>(id);
  const [editNodeNameStatus, setEditNodeNameStatus] = useState<boolean>(false);

  const [sourceId, setSourceId] = useState<string | null>(null);

  const [lFields, setLFields] = useState<MappingField[]>([]);
  const [rFields, setRFields] = useState<MappingField[]>([]);
  const [rFieldsDict, setRFieldsDict] = useState<{
    [key: string]: MappingField;
  }>({});
  const [selectedTransform, setSelectedTransform] = useState<string | null>(
    null,
  );

  const [fileFormats, setFileFormats] = useState<IFileFormat[]>([]);
  const [formatOption, setFormatOption] = useState<string | null>(null);
  const [pathOption, setPathOption] = useState<string | null>(null);
  const [editFormatStatus, setEditFormatStatus] = useState<boolean>(false);
  const [editPathStatus, setEditPathStatus] = useState<boolean>(false);

  const [formatOptions, setFormatOptions] = useState<Format[]>([]);

  const onChangeNodeId = () => {
    IntegrationManager.changeNodeId(
      id,
      nodeName,
      nodes,
      () => {},
      setNodes,
      setEdges,
      setEditNodeNameStatus,
    );
  };

  // load file formats
  useEffect(() => {
    const fetchFileFormats = async () => {
      const response = await FlatFileService.getAll();
      if (response.code === 1) {
        const options: Format[] = response.data.rows.map((el: any) => ({
          label: el.id,
          value: el.id,
        }));
        setFileFormats(response.data.rows);
        setFormatOptions(options);
      } else {
        message.error(
          "Ошибка при попытке получения форматов файла. Обратитесь к администратору.",
        );
      }
    };

    fetchFileFormats();
  }, []);

  // reset and fill component state that depends on id
  useEffect(() => {
    setEditNodeNameStatus(false);
    setNodeName("");
    setSourceId(null);
    setLFields([]);
    setRFields([]);
    setSelectedTransform(null);
    setFormatOption(null);
    setPathOption(null);
    setEditFormatStatus(false);
    setEditPathStatus(false);

    {
      const node: any = nodes.find((node) => node.id === id)?.data;

      if (!node) {
        // message.error('Не обнаружена нода. Возможно, вы ее удалили перед тем как открыть.');
        return;
      }

      setNodeName(node.label);
      setPathOption(node.path);
      setFormatOption(node.format);
      setRFields(node.formula || []);

      const edge = edges.find((edge) => edge.target === id);

      if (!edge) {
        message.warning(
          `Обратите внимание, что в эту ноду (${id}) не входит поток данных`,
        );
        return;
      }

      setSourceId(edge.source);

      const { formula }: any = nodes.find(
        (node) => node.id === edge.source,
      )!.data;

      if (!formula) {
        message.warning(
          `Обратите внимание, что в ноде ${edge.source} не заданы поля`,
        );
        return;
      }

      setLFields(formula);
    }
  }, [id, nodes, edges]);

  useEffect(() => {
    setRFieldsDict(
      rFields.reduce((acc, field) => ({ ...acc, [field.uuid]: field }), {}),
    );
  }, [rFields]);

  // HACK: updates nodes without triggering component update
  //       if there were setNodes, the component would go into an infinite state update
  useEffect(() => {
    for (const node of nodes) {
      if (node.id === id) {
        node.data.formula = rFields;
      }
    }
  }, [id, rFields]);

  const handleFormatChange = () => {
    setNodes((prev) => {
      const res = prev.map((rec) => {
        if (rec.id === id) {
          const find = fileFormats.find((el) => el.id === formatOption);
          if (find) {
            rec.data.formula = find.fields;
          }
          rec.data.format = formatOption;
        }
        return rec;
      });
      return res;
    });
    const fNode: any = nodes.find((el) => el.id === id)?.data;
    setRFields(fNode.formula);
    setEditFormatStatus(false);
  };

  const handlePathChange = () => {
    setNodes((prev) => {
      const res = prev.map((rec) => {
        if (rec.id === id) {
          rec.data.path = pathOption;
        }
        return rec;
      });
      return res;
    });
    setEditPathStatus(false);
  };

  const [textAreaValue, setTextAreaValue] = useState("");

  useEffect(() => {
    if (selectedTransform) {
      setTextAreaValue(rFieldsDict[selectedTransform]?.formula || "");
    } else {
      setTextAreaValue("");
    }
  }, [selectedTransform, rFieldsDict]);

  const handleTextAreaChange = (e: any) => {
    const newValue = e.target.value;
    setTextAreaValue(newValue);
    // Теперь обновляем глобальное состояние
    setRFields((prev) =>
      prev.map((field) => {
        if (field.uuid === selectedTransform) {
          return { ...field, formula: newValue };
        }
        return field;
      }),
    );
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Наименование ноды
          </div>
          <Input
            value={nodeName}
            status={
              nodeName.toString().match(/^[A-Za-z][A-Za-z0-9\-_]*$/)
                ? ""
                : "error"
            }
            disabled={!editNodeNameStatus}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => {
              if (!el.target.value.match(/^[A-Za-z][A-Za-z0-9\-_]*$/)) {
                message.warning(
                  "Вводимое наименование содержит недопустимые символы",
                );
              }
              setNodeName(el.target.value);
            }}
            allowClear
          />
          {!editNodeNameStatus && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditNodeNameStatus(true)}
            >
              ✏️
            </Button>
          )}
          {editNodeNameStatus && (
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <Button style={{ width: "50px" }} onClick={onChangeNodeId}>
                💾
              </Button>
              <Button
                style={{ width: "50px" }}
                onClick={() => {
                  setNodeName(id);
                  setEditNodeNameStatus(false);
                }}
              >
                🔄
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Формат</div>
          <Select
            value={formatOption}
            style={{ width: "350px", color: "black" }}
            optionFilterProp="children"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={formatOptions}
            disabled={!editFormatStatus}
            onChange={setFormatOption}
            className="fileformat__select"
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
          {!editFormatStatus && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditFormatStatus(true)}
            >
              ✏️
            </Button>
          )}
          {editFormatStatus && (
            <Button style={{ width: "50px" }} onClick={handleFormatChange}>
              💾
            </Button>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Путь</div>
          <Input
            value={pathOption ? pathOption : ""}
            disabled={!editPathStatus}
            style={{ width: "350px", color: "black" }}
            onChange={(el) => setPathOption(el.target.value)}
          />
          {!editPathStatus && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditPathStatus(true)}
            >
              ✏️
            </Button>
          )}
          {editPathStatus && (
            <Button style={{ width: "50px" }} onClick={handlePathChange}>
              💾
            </Button>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            height: "500px",
            overflow: "auto",
          }}
        >
          <div>
            <span style={{ fontWeight: "bold" }}>
              ⤵️ Входящий поток: {sourceId}
            </span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      <Button
                        type="dashed"
                        onClick={() =>
                          IntegrationFieldsManager.mapAllFieldsToRight({
                            sourceFields: lFields,
                            setTargetFields: setRFields,
                          })
                        }
                      >
                        ↘
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lFields?.length > 0 ? (
                    lFields.map((el: MappingField, ind: number) => (
                      <TableRow key={ind}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {IntegrationFieldsManager.getSourceFieldStatus(
                            el.id,
                            rFields,
                          )}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.id}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.desc}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.type}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          <Button
                            type="dashed"
                            onClick={() =>
                              IntegrationFieldsManager.mapFieldToTarget(
                                el.id,
                                lFields,
                                setRFields,
                              )
                            }
                          >
                            ➡
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={100}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет исходящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>
              ⤴️ Исходящий поток: {nodeName}
            </span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Действие
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rFields?.length > 0 ? (
                    rFields.map((el: MappingField, ind: number) => (
                      <TableRow key={ind}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.formula && el.formula.length > 0 ? "✅" : "⭕"}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Input
                            value={rFieldsDict[el.uuid]?.id || ""}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRFields((prev) =>
                                prev.map((field) => {
                                  if (field.uuid === el.uuid) {
                                    return { ...field, id: newValue };
                                  }
                                  return field;
                                }),
                              );
                            }}
                            disabled={true}
                          />
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Input
                            value={rFieldsDict[el.uuid]?.desc || ""}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRFields((prev) =>
                                prev.map((field) => {
                                  if (field.uuid === el.uuid) {
                                    return { ...field, desc: newValue };
                                  }
                                  return field;
                                }),
                              );
                            }}
                            disabled={true}
                          />
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Select
                            style={{ width: "100%" }}
                            value={rFieldsDict[el.uuid]?.type || ""}
                            onChange={(e) => {
                              const newValue = e;
                              setRFields((prev) =>
                                prev.map((field: any) => {
                                  if (field.uuid === el.uuid) {
                                    return { ...field, type: newValue };
                                  }
                                  return field;
                                }),
                              );
                            }}
                            options={__optionType}
                            disabled={true}
                          />
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Button onClick={() => setSelectedTransform(el.uuid)}>
                            🔎
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={100}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет входящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span>
            Параметр мэппинга:{" "}
            {rFields && rFields.find((el) => el.uuid === selectedTransform)?.id}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              height: "100px",
            }}
          >
            <TextArea
              size="large"
              style={{ height: "100px", resize: "none" }}
              disabled={!selectedTransform}
              value={textAreaValue}
              onChange={handleTextAreaChange}
            />
            <Button
              style={{ width: "100px", height: "100px", fontSize: "36px" }}
              onClick={() => console.log({ nodes, edges })}
            >
              👁
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TargetFlatFileWindow;

const __optionType = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];
