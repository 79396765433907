export interface IAttribute {
  id: string;
  name: string;
  type: string;
  reference: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
}

export class Attribute implements IAttribute {
  id: string;
  name: string;
  type: string;
  reference: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;

  constructor(model: any) {
    this.id = model.id;
    this.name = model.name;
    this.type = model.type;
    this.reference = model.reference;
    this.createdAt = model.createdAt;
    this.updatedAt = model.updatedAt;
    this.createdBy = model.createdBy;
    this.updatedBy = model.updatedBy;
  }
}

export interface IAttributeSimple {
  id: string;
  name: string;
}

export class AttributeSimple implements IAttributeSimple {
  id: string;
  name: string;

  constructor(model: any) {
    this.id = model.id;
    this.name = model.name;
  }
}

export interface IAttributeCreate {
  id: string;
  name: string;
  description: string;
  type: string;
  reference: string;
}

export class AttributeCreate implements IAttributeCreate {
  id: string;
  name: string;
  description: string;
  type: string;
  reference: string;

  constructor(model: any) {
    this.id = model.id;
    this.name = model.name;
    this.description = model.description;
    this.type = model.type;
    this.reference = model.reference;
  }
}
