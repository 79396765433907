const colors = {
  targetFlatFile: "#900020",
  flatFile: "#8db600",
  join: "#175A93",
  mapping: "#2C893A",
  filter: "#521D69",
  group: "#008075",
  rowGen: "#BE2922",
  knowledgeSpace: "#644AFF",
  planx: "#BE2922",
  postgres: "#0099cc",
  clickhouse: "#D67A00",
  rabbitmq: "orange",
  launchFlow: "#33e1ff",
  note: "#FDD89250",
  extrapolation: "#8a3e4c",
};

export function nodeColor(node: any) {
  switch (node.type) {
    case "flatFile":
      return colors.flatFile;
    case "targetFlatFile":
      return colors.flatFile;
    case "join":
      return colors.join;
    case "mapping":
      return colors.mapping;
    case "filter":
      return colors.filter;
    case "groupBy":
      return colors.group;
    case "rowGen":
      return colors.rowGen;
    case "knowledgeSpaceClassInput":
      return colors.knowledgeSpace;
    case "knowledgeSpaceClassOutput":
      return colors.knowledgeSpace;
    case "knowledgeSpaceDictionaryInput":
      return colors.knowledgeSpace;
    case "planxInput":
      return colors.planx;
    case "planxFigureOutput":
      return colors.planx;
    case "planxOutput":
      return colors.planx;
    case "postgresInput":
      return colors.postgres;
    case "postgresOutput":
      return colors.postgres;
    case "clickhouseInput":
      return colors.clickhouse;
    case "clickhouseOutput":
      return colors.clickhouse;
    case "note":
      return colors.note;
    case "rabbitmq":
      return colors.rabbitmq;
    case "launchFlow":
      return colors.launchFlow;
    case "extrapolation":
      return colors.extrapolation;
    default:
      return "#ff0072";
  }
}

export default colors;
