import { Edge, Node } from "@xyflow/react";
import { message } from "antd";
import { SetStateAction } from "react";

class IntegrationManager {
  static initial(
    nodeId: string | number,
    setNodeId: SetStateAction<any>,
    nodes: Node[],
    edges: Edge[],
    setSourceFields: SetStateAction<any>,
    setTargetFields: SetStateAction<any>,
    setSourceId: SetStateAction<any>,
    setNodeName: SetStateAction<any>,
  ) {
    // Устанавливаем заголовки
    setNodeId(nodeId);
    setNodeName(nodeId);
    // Ищем ноду в списке нод
    const fNode: any = nodes.find((el) => el.id === nodeId)?.data;
    if (!fNode) {
      // return message.error('Не обнаружена нода. Возможно, вы ее удалили перед тем как открыть.');
    }

    // Если нода найдена, то нужно установить целевые столбцы
    setTargetFields(fNode.formula);

    const findEdges = edges.find((el) => el.target === nodeId);
    if (findEdges) {
      setSourceId(findEdges.source);
      const findSourceNode = nodes.find((el) => el.id === findEdges.source);
      if (findSourceNode?.data.formula) {
        setSourceFields(findSourceNode?.data.formula);
      } else {
        return message.warning(
          `Обратите внимание, что в ноде ${findEdges.source} не заданы поля`,
        );
      }
    } else {
      return message.warning(
        `Обратите внимание, что в эту ноду (${nodeId}) не входит поток данных`,
      );
    }
  }

  static resetNodeName(
    nodeId: string | number,
    setNodeName: any,
    setEditNodeNameStatus: any,
  ) {
    setNodeName(nodeId);
    setEditNodeNameStatus(false);
  }

  static changeNodeId(
    nodeId: string | number,
    nodeName: string | number,
    nodes: Node[],
    setNodeId: any,
    setNodes: any,
    setEdges: any,
    setEditNodeNameStatus: any,
  ) {
    if (nodeId === nodeName) {
      return;
    }

    const find = nodes.some((node) => node.id === nodeName);
    if (find) {
      message.error("Уже существует нода с таким именем");
      return;
    }

    setNodes((prev: any) => {
      const res = prev.map((rec: any) => {
        if (rec.id === nodeId) {
          rec.id = nodeName.toString();
          rec.data.label = nodeName.toString();
          setNodeId(nodeName);
        }

        if (rec.type === "join" && rec.data.mainTable === nodeId) {
          rec.data.mainTable = nodeName.toString();
        }

        if (rec.type === "join" && rec.data.secondaryTable === nodeId) {
          rec.data.secondaryTable = nodeName.toString();
        }

        return rec;
      });
      return res;
    });

    setEdges((prev: any) => {
      const res = prev.map((rec: any) => {
        if (rec.source === nodeId) {
          const last = rec.source;
          rec.source = nodeName.toString();
          // Дальше логика по обновлению формул
          setNodes((previ: any) => {
            const resi = previ.map((reci: any) => {
              if (reci.id === rec.target) {
                if (reci.data.formula) {
                  reci.data.formula = reci.data.formula.map((eli: any) => {
                    if (eli.formula && eli.formula !== "") {
                      eli.formula = eli.formula.replaceAll(
                        last,
                        nodeName.toString(),
                      );
                    }
                    if (eli.filter && eli.filter !== "") {
                      eli.filter = eli.filter.replaceAll(
                        last,
                        nodeName.toString(),
                      );
                    }
                    return eli;
                  });
                }
              }
              return reci;
            });
            return resi;
          });
        }
        if (rec.target === nodeId) {
          rec.target = nodeName.toString();
        }
        return rec;
      });
      return res;
    });

    setEditNodeNameStatus(false);
    message.success(`Успешно переименовано с ${nodeId} на ${nodeName}`);
  }

  static handleSave(targetFields: any, setNodes: SetStateAction<any>) {}
}

export default IntegrationManager;
