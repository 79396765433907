import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Edge, Node } from "@xyflow/react";
import { Button, Input, Select, message, notification } from "antd";
import Empty from "antd/lib/empty";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import KSService from "../../../../../entities/model/KSService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";

interface Input {
  id: string;
  nodes: Node[];
  edges: Edge[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
}

const { TextArea } = Input;

const KnowledgeSpaceDictionaryInput: React.FC<Input> = ({
  id,
  nodes,
  edges,
  setNodes,
  setEdges,
}) => {
  const [nodeName, setNodeName] = useState<string>(id);
  const [editNodeNameStatus, setEditNodeNameStatus] = useState<boolean>(false);

  const [fields, setFields] = useState<MappingField[]>([]);

  const onChangeNodeId = () => {
    IntegrationManager.changeNodeId(
      id,
      nodeName,
      nodes,
      () => {},
      setNodes,
      setEdges,
      setEditNodeNameStatus,
    );
  };

  useEffect(() => {
    setNodeName(id);
    const node: any = nodes.find((el) => el.id === id)?.data;
    if (node) {
      setFields(node.formula);
      setKSSystemSelected(node.system);
      setKSProjectSelected(node.project);
      setKSDictionarySelected(node.dictionary);
    }
    KSgetSystemList();
  }, [id]);

  const [ksSystemOptions, setKSSystemOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [ksSystemSelected, setKSSystemSelected] = useState<string>("");

  const [ksProjectOptions, setKSProjectOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [ksProjectSelected, setKSProjectSelected] = useState<string>("");

  const [ksDictionaryOptions, setKSDictionaryOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [ksDictionarySelected, setKSDictionarySelected] = useState<string>("");

  const KSgetSystemList = async () => {
    const getSystems = await KSService.getAll();
    setKSSystemOptions(
      getSystems.data.map((el: any) => ({ label: el.id, value: el.id })),
    );
  };

  const KSgetProjectList = async () => {
    if (ksSystemSelected && ksSystemSelected !== "") {
      const getProjects = await KSService.getProjects(ksSystemSelected);
      if (getProjects.code === 1) {
        setKSProjectOptions((prevOptions: any) =>
          getProjects.data.map((el: any) => ({
            label: el.name,
            value: el.uuid,
          })),
        );
      } else {
        notification.error({
          message: getProjects.text,
          description: `Код ответа: ${getProjects.code}`,
        });
      }
    }
  };

  useEffect(() => {
    KSgetProjectList();
  }, [ksSystemSelected]);

  const KSgetDictionaryList = async () => {
    const getDictionaries = await KSService.getDictionaries(
      ksSystemSelected,
      ksProjectSelected,
    );
    if (getDictionaries.code === 1) {
      setKSDictionaryOptions((prevOptions: any) =>
        getDictionaries.data.map((el: any) => ({
          label: el.name,
          value: el.uuid,
        })),
      );
    }
  };

  useEffect(() => {
    KSgetDictionaryList();
  }, [ksProjectSelected]);

  useEffect(() => {
    setFields([
      {
        uuid: uuidv4(),
        type: "STRING",
        id: "UUID",
      },
      {
        uuid: uuidv4(),
        type: "STRING",
        id: "NAME",
      },
      {
        uuid: uuidv4(),
        type: "STRING",
        id: "DESCRIPTION",
      },
    ]);
  }, [ksDictionarySelected]);

  useEffect(() => {
    setNodes((prev) => {
      const res = prev.map((rec) => {
        if (rec.id === id) {
          rec.data.formula = fields;
          rec.data.system = ksSystemSelected;
          rec.data.project = ksProjectSelected;
          rec.data.dictionary = ksDictionarySelected;
        }
        return rec;
      });
      return res;
    });
  }, [fields]);

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Наименование ноды
          </div>
          <Input
            value={nodeName}
            status={
              nodeName.toString().match(/^[A-Za-z][A-Za-z0-9\-_]*$/)
                ? ""
                : "error"
            }
            disabled={!editNodeNameStatus}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => {
              if (!el.target.value.match(/^[A-Za-z][A-Za-z0-9\-_]*$/)) {
                message.warning(
                  "Вводимое наименование содержит недопустимые символы",
                );
              }
              setNodeName(el.target.value);
            }}
            allowClear
          />
          {!editNodeNameStatus && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditNodeNameStatus(true)}
            >
              ✏️
            </Button>
          )}
          {editNodeNameStatus && (
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <Button style={{ width: "50px" }} onClick={onChangeNodeId}>
                💾
              </Button>
              <Button
                style={{ width: "50px" }}
                onClick={() => {
                  setNodeName(id);
                  setEditNodeNameStatus(false);
                }}
              >
                🔄
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Подключение KS
          </div>
          <Select
            value={ksSystemSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setKSSystemSelected(el)}
            options={ksSystemOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Проект KS</div>
          <Select
            value={ksProjectSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setKSProjectSelected(el)}
            options={ksProjectOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Словарь KS</div>
          <Select
            value={ksDictionarySelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el: any) => setKSDictionarySelected(el)}
            options={ksDictionaryOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Идентификатор
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Описание</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Тип</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields?.length ? (
              fields.map((el: MappingField, ind: number) => (
                <TableRow key={ind}>
                  <TableCell>{el.id}</TableCell>
                  <TableCell>{el.desc}</TableCell>
                  <TableCell>{el.type}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={100}>
                  <Empty
                    imageStyle={{ height: "50px" }}
                    description="Нет исходящих полей"
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default KnowledgeSpaceDictionaryInput;
