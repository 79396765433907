import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Edge, Node, useReactFlow } from "@xyflow/react";
import {
  Button,
  Divider,
  Empty,
  Input,
  InputRef,
  Modal,
  Select,
  Space,
  Tooltip,
  Upload,
  UploadProps,
  message,
  notification,
} from "antd";
import ELK from "elkjs/lib/elk.bundled.js";
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import FlowService from "../../../entities/model/FlowService";
import UserPopover from "../../../shared/components/popovers/user";
import { fuzzyIsIn } from "../../../shared/helper/comparison";
import IntegrationFlowLaunch from "../integrationTaskv4/flowLaunch";
import IntegrationFlowLogs from "./flowLogs";
import ModalFlowVariables, { IFlowVariableField } from "./modalVariables";

interface IIntegrationConstructorHeader {
  project: string | null;
  flowChangedAt: string | undefined;
  flowChangedBy: string | undefined;

  setFlowId: Dispatch<SetStateAction<string | null>>;
  setProject: Dispatch<SetStateAction<string | null>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setVariables: Dispatch<SetStateAction<IFlowVariableField[]>>;

  fetchData: () => Promise<void>;

  variables: IFlowVariableField[];
  flowId: string | null;
  nodes: Node[];
  edges: Edge[];
  startFlowId: string;

  setSidebarState: Dispatch<SetStateAction<boolean>>;
  sidebarState: boolean;

  undoCount: number;
  setUndoCount: Dispatch<SetStateAction<number>>;
  historical: any;
  lastState: any;
}

const IntegrationConstructorHeader: React.FC<IIntegrationConstructorHeader> = ({
  project,
  flowChangedBy,
  flowChangedAt,
  setFlowId,
  setEdges,
  setProject,
  setNodes,
  setVariables,
  variables,
  flowId,
  nodes,
  edges,
  startFlowId,
  setSidebarState,
  sidebarState,
  fetchData,
  undoCount,
  setUndoCount,
  historical,
  lastState,
}) => {
  const [launchModalState, setLaunchModalState] = useState<boolean>(false);
  const [logsModalState, setLogsModalState] = useState<boolean>(false);
  const [importModalState, setImportModalState] = useState<boolean>(false);
  const [variableModalState, setVariableModalState] = useState<boolean>(false);

  const openLaunchDrawer = () => {
    setLaunchModalState(true);
  };

  const closeLaunchDrawer = () => {
    setLaunchModalState(false);
  };

  const openLogsModal = () => {
    setLogsModalState(true);
  };

  const closeLogsModal = () => {
    setLogsModalState(false);
  };

  const openImportModal = () => {
    setImportModalState(true);
  };

  const closeImportModal = () => {
    setImportModalState(false);
  };

  const openVariableModal = () => {
    setVariableModalState(true);
  };

  const { getLayoutedElements } = useLayoutedElements();

  const handleSave = async () => {
    if (!flowId || flowId === "") {
      return message.error("Укажите идентификатор потока!");
    }

    let redirect: boolean = false;

    if (startFlowId !== flowId && startFlowId && startFlowId !== "new") {
      const response = await FlowService.rename(startFlowId, flowId);
      if (response.code === 1) {
        redirect = true;
        notification.success({ message: "Успешно переименовано" });
      } else {
        notification.error({
          message: response.text,
          description: `Код ответа: ${response.code}`,
        });
      }
    }

    const project_string = project ? project : "";

    setEdges((prev) => {
      return prev.filter((edge) => {
        const sourceCheck = nodes.find((el) => el.id === edge.source);
        const targetCheck = nodes.find((el) => el.id === edge.target);
        if (sourceCheck && targetCheck) {
          return true;
        }
      });
    });

    if (startFlowId === "new") {
      const response = await FlowService.create(
        flowId,
        project_string,
        nodes,
        edges,
        variables,
      );
      if (response.code === 1) {
        window.location.href = `/integration/constructor/${flowId}`;
        return notification.success({ message: "Успешно создано" });
      } else {
        return notification.error({
          message: response.text,
          description: `Код ответа: ${response.code}`,
        });
      }
    } else {
      const response = await FlowService.update(
        flowId,
        project_string,
        nodes,
        edges,
        variables,
      );
      if (response.code === 1) {
        if (redirect) {
          window.location.href = `/integration/constructor/${flowId}`;
        }
        await fetchData();
        return notification.success({ message: "Успешно сохранено" });
      } else {
        if (redirect) {
          window.location.href = `/integration/constructor/${flowId}`;
        }
        return notification.error({
          message: response.text,
          description: `Код ответа: ${response.code}`,
        });
      }
    }
  };

  const { data: projects_raw = [] } = useQuery<string[]>({
    queryKey: ["flow-projects"],
    queryFn: async () => {
      const response = await FlowService.getProjects();

      if (response.code !== 1) {
        throw new Error(
          `Ошибка при попытке загрузки списка заданий интеграции: "${response.text}"`,
        );
      }

      return response.data;
    },
  });

  const [newProject, setNewProject] = useState("");
  const [projects, setProjects] = useState(projects_raw);
  useEffect(() => setProjects(projects_raw), []);
  const inputRef = useRef<InputRef>(null);

  const addProject = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    e.preventDefault();
    setProjects([...projects, newProject]);
    setNewProject("");
    setProject(newProject);
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const undo = () => {
    console.log(historical, undoCount);
    setUndoCount((prev) => {
      if (historical.length === 0 || historical.length - 2 - prev < 0) {
        message.warning("Более глубокая история изменений отсутствует.");
        return prev;
      }

      const version = historical[historical.length - 2 - prev];
      console.log("versiyaaa", version);

      setNodes(version.nodes);
      setEdges(version.edges);

      return prev + 1;
    });
  };

  const redo = () => {
    console.log(historical, undoCount);
    setUndoCount((prev) => {
      // Ensure there are redo actions available
      if (historical.length === 0 || prev <= 0) {
        setNodes(lastState.nodes);
        setEdges(lastState.edges);
        message.warning("Нет изменений для повтора.");
        return prev;
      }

      // Calculate the redo version based on the current undoCount
      const version = historical[historical.length - prev];

      // Update the state with the redo version
      setNodes(version.nodes);
      setEdges(version.edges);

      // Increment the undo count to reflect the redo operation
      return prev - 1;
    });
  };

  const Copy = async () => {
    const copyNodes = nodes.filter((e) => e.selected);
    const copyEdges = edges.filter((e) => e.selected);

    const object = {
      type: "IBPMASTER_INTEGRATION_COPYPASTE",
      nodes: copyNodes,
      edges: copyEdges,
    };

    console.log(nodes);

    try {
      await navigator.clipboard.writeText(JSON.stringify(object));
      message.success(`Копирование ${copyNodes.length} нод произошло успешно!`);
    } catch (text: any) {
      message.error("Не удалось произвести копирование. Ошибка доступа.");
    }
  };

  const Paste = async () => {
    try {
      const rawText = await navigator.clipboard.readText();
      const o = JSON.parse(rawText);

      if (
        Object.hasOwn(o, "type") &&
        Object.hasOwn(o, "nodes") &&
        Object.hasOwn(o, "edges") &&
        o.type === "IBPMASTER_INTEGRATION_COPYPASTE"
      ) {
        if (typeof o.nodes !== "object" || o.nodes.length === 0) {
          return message.warning("Вставлено 0 нод!");
        }

        const pasteEdges: Edge[] = [];
        const pasteNodes: Node[] = [];

        for (const node of o.nodes) {
          let i: number = 0;
          let stopper = false;
          let label: string = node.data.label;
          while (!stopper) {
            const lableToFind = `${label}${i > 0 ? `[${i}]` : ""}`;
            const find = nodes.some((e) => e.data.label === lableToFind);
            if (find) {
              i++;
              continue;
            } else {
              label = lableToFind;
              stopper = true;
            }
          }

          const id = `${node.type}_${uuidv4()}`;

          // в соединениях надо заменить старый идентификатор на новый
          o.edges = o.edges.map((el: any) => {
            if (el.source === node.id) {
              el.source = id;
            }
            if (el.target === node.id) {
              el.target = id;
            }
            return el;
          });

          pasteNodes.push({
            id,
            selected: true,
            type: node.type,
            position: node.position,
            data: {
              ...node.data,
              label,
            },
          });
        }

        for (const edge of o.edges) {
          pasteEdges.push({
            id: `xy-edge-pasted-${uuidv4()}`,
            selected: true,
            source: edge.source,
            sourceHandle: edge.sourceHandle,
            target: edge.target,
            targetHandle: edge.targetHandle,
            type: edge.type,
          });
        }

        setNodes((prev) => [...prev, ...pasteNodes]);
        setEdges((prev) => [...prev, ...pasteEdges]);

        message.success(`Вставка ${pasteNodes.length} нод прошла успешно!`);
      } else {
        message.warning("Вставка не произошла!");
      }
    } catch (text: any) {
      message.error("Не удалось произвести вставку. Некорректный формат.");
    }
  };

  // // Функция, которая будет вызываться при нажатии Ctrl + C или Command + C
  // const handleKeyboardCopyPaste = (event: any) => {
  //   // Проверяем, что нажаты Ctrl + C или Command + C
  //   if ((event.ctrlKey || event.metaKey) && event.key === "c") {
  //     event.preventDefault(); // Предотвращаем стандартное действие (копирование)
  //     Copy();
  //   }

  //   if ((event.ctrlKey || event.metaKey) && event.key === "v") {
  //     event.preventDefault(); // Предотвращаем стандартное действие (копирование)
  //     Paste();
  //   }
  // };

  // useEffect(() => {
  //   // Добавляем слушатель события keydown при монтировании компонента
  //   document.addEventListener("keydown", handleKeyboardCopyPaste);

  //   // Удаляем слушатель события при размонтировании компонента
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyboardCopyPaste);
  //   };
  // }, []);

  return (
    <>
      <aside
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "15px",
          width: "100%",
          background: "#292d39",
          padding: "0px 15px 0px 15px",
          borderRadius: "8px 8px 0px 0px",
          color: "white",
          height: "40px",
          overflow: "auto",
          backgroundColor: "#292D39",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
          }}
        >
          {!sidebarState && (
            <IntegrationConstructorHeaderElement
              iconUrl="/img/shape_list.svg"
              onClick={() => setSidebarState((prev) => !prev)}
              description="Показать доступные ноды"
              iconHeight={16}
              background={"#999999"}
            />
          )}
          {sidebarState && (
            <IntegrationConstructorHeaderElement
              iconUrl="/img/shape_close.svg"
              onClick={() => setSidebarState((prev) => !prev)}
              description="Скрыть панель с нодами"
              iconHeight={16}
              background={"#37AB49"}
            />
          )}
          <IntegrationConstructorHeaderElement
            iconUrl="/img/shape_save.svg"
            onClick={handleSave}
            description="Сохранить"
          />
          <IntegrationConstructorHeaderElement
            iconUrl="/img/shape_play.svg"
            onClick={() =>
              startFlowId === "new"
                ? message.warning("Сначала поток должен быть создан!")
                : openLaunchDrawer()
            }
            disabled={startFlowId === "new" ? true : false}
            description="Запустить"
          />
          <IntegrationConstructorHeaderElement
            iconUrl="/img/shape_undo.svg"
            onClick={undo}
            iconHeight={12}
            description="Отменить последнее действие"
          />
          <IntegrationConstructorHeaderElement
            iconUrl="/img/shape_redo.svg"
            onClick={redo}
            iconHeight={12}
            description="Вернуть последнее действие"
          />
          <IntegrationConstructorHeaderElement
            iconUrl="/img/shape_devicelist.svg"
            onClick={openLogsModal}
            iconHeight={22}
            description="Логи запусков"
          />
          <IntegrationConstructorHeaderElement
            iconUrl="/img/shape_scenario.svg"
            onClick={() =>
              getLayoutedElements({
                "elk.algorithm": "layered",
                "elk.direction": "RIGHT",
              })
            }
            iconHeight={22}
            description="Автовыравнивание нод"
          />
          <IntegrationConstructorHeaderElement
            iconUrl="/img/shape_variable.svg"
            onClick={openVariableModal}
            iconHeight={34}
            description="Переменные потока"
          />
          <IntegrationConstructorHeaderElement
            iconUrl="/img/shape_unarchive.svg"
            onClick={() =>
              ExportFlow({
                filename: startFlowId ? startFlowId : "DraftFlow",
                data: { flowId, project, nodes, edges, variables },
              })
            }
            iconHeight={30}
            description="Экспорт настроек потока"
          />
          <IntegrationConstructorHeaderElement
            iconUrl="/img/shape_move_to_inbox.svg"
            onClick={openImportModal}
            iconHeight={24}
            description="Импорт настроек потока"
          />
          <IntegrationConstructorHeaderElement
            iconUrl="/img/shape_move_to_inbox.svg"
            onClick={() => Copy()}
            iconHeight={24}
            description="Копировать"
          />
          <IntegrationConstructorHeaderElement
            iconUrl="/img/shape_move_to_inbox.svg"
            onClick={() => Paste()}
            iconHeight={24}
            description="Вставить"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <>
            <span style={{ fontWeight: "bold" }}>Идентификатор: </span>

            <Input
              style={{ width: "300px", height: "30px", fontWeight: "bold" }}
              value={flowId ? flowId : ""}
              placeholder="Введите идентификатор потока"
              onChange={(el: any) => setFlowId(el.target.value.toUpperCase())}
            />
          </>
          <>
            <span style={{ fontWeight: "bold" }}>Проект: </span>
            <Select
              style={{ width: "200px", height: "30px" }}
              placeholder="Выберите проект"
              value={project}
              options={projects.map((item) => ({ label: item, value: item }))}
              onChange={(project) => setProject(project)}
              showSearch={true}
              filterOption={(
                input,
                option?: { label: string; value: string },
              ) => fuzzyIsIn(input, option?.label ?? "")}
              dropdownRender={(menu) => (
                <>
                  <Space style={{ padding: "4px 8px 0" }}>
                    <Input
                      placeholder="Новый проект"
                      ref={inputRef}
                      value={newProject}
                      onChange={(e) => setNewProject(e.target.value)}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                    <Button
                      type="dashed"
                      icon={<PlusOutlined />}
                      onClick={addProject}
                    />
                  </Space>
                  <Divider style={{ margin: "8px 0" }} />
                  {menu}
                </>
              )}
            />
          </>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
          }}
        >
          <span style={{ fontWeight: "bold" }}>Последнее сохранение: </span>
          {!flowChangedAt && "Поток еще не был сохранен!"}
          {flowChangedAt}
          {flowChangedBy && (
            <UserPopover userId={flowChangedBy} color="white" />
          )}
        </div>
      </aside>
      <IntegrationFlowLaunch
        open={launchModalState}
        onClose={closeLaunchDrawer}
        flowId={flowId}
      />
      <Modal
        open={logsModalState}
        onCancel={closeLogsModal}
        width="1400px"
        footer={(_, { CancelBtn }) => (
          <>
            <CancelBtn />
          </>
        )}
        cancelText="Закрыть"
        closable={false}
      >
        {startFlowId && startFlowId !== "new" && (
          <IntegrationFlowLogs flowId={startFlowId} />
        )}
        {startFlowId === "new" && <Empty description="Поток еще создан" />}
      </Modal>
      <Modal
        open={importModalState}
        onCancel={closeImportModal}
        footer={(_, { CancelBtn }) => (
          <>
            <CancelBtn />
          </>
        )}
        cancelText="Закрыть"
        closable={false}
      >
        <ImportFlow
          setEdges={setEdges}
          setNodes={setNodes}
          setProject={setProject}
          setVariables={setVariables}
          closeModal={closeImportModal}
        />
      </Modal>
      <ModalFlowVariables
        modalState={variableModalState}
        setModalState={setVariableModalState}
        variables={variables}
        setVariables={setVariables}
      />
    </>
  );
};

export default IntegrationConstructorHeader;

interface IIntegrationConstructorHeaderElement {
  iconUrl: string;
  onClick?: MouseEventHandler<HTMLDivElement>;

  iconWidth?: number;
  iconHeight?: number;

  disabled?: boolean;

  description: string;
  background?: string;
}

const IntegrationConstructorHeaderElement: React.FC<
  IIntegrationConstructorHeaderElement
> = ({ iconUrl, onClick, iconHeight, disabled, description, background }) => (
  <Tooltip placement="bottom" title={description}>
    <div
      style={{
        width: background ? "42px" : "30px",
        height: "30px",
        borderRadius: "4px",
        padding: "2px, 4px, 2px, 4px",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: disabled ? "not-allowed" : "pointer",
        backgroundColor: background ? background : "none",
      }}
      onClick={disabled ? undefined : onClick}
    >
      <img src={iconUrl} style={{ height: iconHeight ? iconHeight : "24px" }} />
    </div>
  </Tooltip>
);

interface IExportFlow {
  data: any;
  filename: string;
}

const ExportFlow = ({ data, filename }: IExportFlow) => {
  const dataString = JSON.stringify(data);
  const fileData = new Blob([dataString], { type: "text/json" }); // Создайте объект Blob
  const fileName = `${filename}.json`;

  window.webkitURL.createObjectURL(fileData);
  const link = document.createElement("a");
  link.href = window.webkitURL.createObjectURL(fileData);
  link.download = fileName;
  link.click();
  window.webkitURL.revokeObjectURL(link.href);
};

interface IImportFlow {
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
  setVariables: Dispatch<SetStateAction<IFlowVariableField[]>>;
  setProject: Dispatch<SetStateAction<string | null>>;

  closeModal: any;
}
const ImportFlow: React.FC<IImportFlow> = ({
  setNodes,
  setEdges,
  setVariables,
  setProject,
  closeModal,
}) => {
  const { Dragger } = Upload;

  const props: UploadProps = {
    name: "file",
    multiple: false,
    beforeUpload: async (file) => {
      const value = JSON.parse(await file.text());
      if (!value.flowId || !value.nodes || !value.edges) {
        return message.error(
          "Загружаемый файл поврежден, так как не содержит необходимых данных!",
        );
      }
      setNodes(value.nodes);
      setEdges(value.edges);
      setVariables(value.variables);
      setProject(value.project);
      message.success("Успешно импортировано!");
      closeModal();
    },
  };
  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Нажмите или перетащите JSON файл потока для импорта
      </p>
      <p className="ant-upload-hint">
        Обратите внимание, что это действие перезапишет все текущие ноды. Для
        принятия изменений потребуется сохранить поток.
      </p>
    </Dragger>
  );
};

const elk = new ELK();

const useLayoutedElements = () => {
  const { getNodes, setNodes, getEdges, fitView } = useReactFlow();
  const defaultOptions = {
    "elk.algorithm": "layered",
    "elk.layered.spacing.nodeNodeBetweenLayers": 100,
    "elk.spacing.nodeNode": 100,
  };

  const getLayoutedElements = useCallback((options: any) => {
    const layoutOptions = { ...defaultOptions, ...options };
    const graph: any = {
      id: "root",
      layoutOptions: layoutOptions,
      children: getNodes().map((e: any) => ({
        ...e,
        width: e.measured.width,
        height: e.measured.height,
      })),
      edges: getEdges(),
    };

    elk.layout(graph).then(({ children }: any) => {
      children.forEach((node: any) => {
        node.position = { x: node.x, y: node.y };
      });

      setNodes(children);
      window.requestAnimationFrame(() => {
        fitView();
      });
    });
  }, []);

  return { getLayoutedElements };
};
