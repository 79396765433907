import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Edge, Node } from "@xyflow/react";
import { Button, Input, Select, Switch, message, notification } from "antd";
import Empty from "antd/lib/empty";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TransferService from "../../../../../entities/model/TransferService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";
import IntegrationFieldsManager from "../utils/fields";

type Props = {
  id: string;
  nodes: Node[];
  edges: Edge[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
};

const { TextArea } = Input;

const PostgresOutput: React.FC<Props> = ({
  id,
  nodes,
  edges,
  setNodes,
  setEdges,
}) => {
  const [nodeName, setNodeName] = useState<string>(id);
  const [editNodeNameStatus, setEditNodeNameStatus] = useState<boolean>(false);

  const [SourceId, setSourceId] = useState<string | null>(null);

  const [lFields, setLFields] = useState<MappingField[]>([]);
  const [rFields, setRFields] = useState<MappingField[]>([]);
  const [rFieldsDict, setRFieldsDict] = useState<{
    [key: string]: MappingField;
  }>({});
  const [selectedTransform, setSelectedTransform] = useState<string | null>(
    null,
  );

  const [fields, setFields] = useState<MappingField[]>([]);

  const onChangeNodeId = () => {
    IntegrationManager.changeNodeId(
      id,
      nodeName,
      nodes,
      () => {},
      setNodes,
      setEdges,
      setEditNodeNameStatus,
    );
  };

  useEffect(() => {
    setSelectedTransform(null);
    setNodeName(id);
    const node: any = nodes.find((el) => el.id === id)?.data;
    if (node) {
      handleEdges();
      setFields(node.formula);
      setPgConnSelected(node.pgConn);
      setPgSchemaSelected(node.pgSchema);
      setPgTableSelected(node.pgTable);
      setPgTableRewrite(node.pgRewrite);
      setPgTableRewriteFilter(node.pgRewriteFilter ?? "");
      getPgConn();
    }
  }, [id]);

  const [pgConnOptions, setPgConnOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [pgConnSelected, setPgConnSelected] = useState<string>("");

  const [pgSchemaOptions, setPgSchemaOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [pgSchemaSelected, setPgSchemaSelected] = useState<string>("");

  const [pgTableOptions, setPgTableOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [pgTableSelected, setPgTableSelected] = useState<string>("");
  const [pgTableRewrite, setPgTableRewrite] = useState<boolean>(true);
  const [pgTableRewriteFilter, setPgTableRewriteFilter] = useState<string>("");

  const getPgConn = async () => {
    const getSystems = await TransferService.getAll({
      filters: [{ id: "system", value: "POSTGRES" }],
    });
    setPgConnOptions(
      getSystems.data.rows.map((el: any) => ({ label: el.id, value: el.id })),
    );
  };

  const getPgSchema = async () => {
    if (!pgConnSelected) {
      return;
    }
    const getSchemas = await TransferService.getPostgresSchemas(pgConnSelected);
    if (getSchemas.code === 1) {
      setPgSchemaOptions(
        getSchemas.data.map((el: any) => ({ label: el, value: el })),
      );
    } else {
      notification.warning({ message: "Не удалось получить схемы" });
    }
  };
  useEffect(() => {
    getPgSchema();
  }, [pgConnSelected]);

  const getPgTable = async () => {
    if (!pgConnSelected || !pgSchemaSelected) {
      return;
    }
    const getTables = await TransferService.getPostgresTables(
      pgConnSelected,
      pgSchemaSelected,
    );
    if (getTables.code === 1) {
      setPgTableOptions(
        getTables.data.map((el: any) => ({ label: el, value: el })),
      );
    } else {
      notification.warning({ message: "Не удалось получить таблицы" });
    }
  };
  useEffect(() => {
    getPgTable();
  }, [pgSchemaSelected]);

  const getPgField = async () => {
    if (!pgConnSelected || !pgSchemaSelected || !pgTableSelected) {
      return;
    }
    const getFields = await TransferService.getPostgresFields(
      pgConnSelected,
      pgSchemaSelected,
      pgTableSelected,
    );

    if (getFields.code === 1) {
      setFields(
        getFields.data.map((el: any) => ({
          uuid: uuidv4(),
          id: el.columnName,
          type: el.dataType,
        })),
      );
    } else {
      notification.warning({ message: "Не удалось получить таблицы" });
    }
  };
  useEffect(() => {
    getPgField();
  }, [pgTableSelected]);

  useEffect(() => {
    if (rFields) {
      setNodes((prev) => {
        const res = prev.map((rec) => {
          if (rec.id === id) {
            rec.data.formula = rFields;
          }
          return rec;
        });
        return res;
      });
      let res: { [key: string]: MappingField } = {};
      for (const field of rFields) {
        res[field.uuid] = field;
      }
      setRFieldsDict(res);
    }
  }, [rFields]);

  const handleEdges = () => {
    setNodeName(id);
    const fNode: any = nodes.find((el) => el.id === id)?.data;
    if (!fNode) {
      // message.error('Не обнаружена нода. Возможно, вы ее удалили перед тем как открыть.');
      return;
    }
    console.log(fNode);
    setRFields(fNode.formula);
    const findEdges = edges.find((el) => el.target === id);
    if (findEdges) {
      setSourceId(findEdges.source);
      const findSourceNode: any = nodes.find(
        (el) => el.id === findEdges.source,
      );
      if (findSourceNode?.data.formula) {
        setLFields(findSourceNode?.data.formula);
      } else {
        message.warning(
          `Обратите внимание, что в ноде ${findEdges.source} не заданы поля`,
        );
      }
    } else {
      message.warning(
        `Обратите внимание, что в эту ноду (${id}) не входит поток данных`,
      );
    }
  };

  useEffect(() => {
    setNodes((prev) => {
      const res = prev.map((rec) => {
        if (rec.id === id) {
          rec.data.formula = fields;
          rec.data.pgConn = pgConnSelected;
          rec.data.pgSchema = pgSchemaSelected;
          rec.data.pgTable = pgTableSelected;
          rec.data.pgRewrite = pgTableRewrite;
          rec.data.pgRewriteFilter = pgTableRewriteFilter;
        }
        return rec;
      });
      return res;
    });

    if (fields) {
      setRFields((prev) => {
        return fields.map((el) => {
          const filter = prev?.find((z) => el.id === z.id);
          if (filter) {
            el.formula = filter.formula;
          }
          return el;
        });
      });
    }
  }, [fields, pgTableRewrite, pgTableRewriteFilter]);

  const [textAreaValue, setTextAreaValue] = useState("");

  useEffect(() => {
    if (selectedTransform) {
      setTextAreaValue(rFieldsDict[selectedTransform]?.formula || "");
    } else {
      setTextAreaValue("");
    }
  }, [selectedTransform, rFieldsDict]);

  const handleTextAreaChange = (e: any) => {
    const newValue = e.target.value;
    setTextAreaValue(newValue);
    // Теперь обновляем глобальное состояние
    setRFields((prev) =>
      prev.map((field) => {
        if (field.uuid === selectedTransform) {
          return { ...field, formula: newValue };
        }
        return field;
      }),
    );
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Наименование ноды
          </div>
          <Input
            value={nodeName}
            status={
              nodeName.toString().match(/^[A-Za-z][A-Za-z0-9\-_]*$/)
                ? ""
                : "error"
            }
            disabled={!editNodeNameStatus}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => {
              if (!el.target.value.match(/^[A-Za-z][A-Za-z0-9\-_]*$/)) {
                message.warning(
                  "Вводимое наименование содержит недопустимые символы",
                );
              }
              setNodeName(el.target.value);
            }}
            allowClear
          />
          {!editNodeNameStatus && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditNodeNameStatus(true)}
            >
              ✏️
            </Button>
          )}
          {editNodeNameStatus && (
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <Button style={{ width: "50px" }} onClick={onChangeNodeId}>
                💾
              </Button>
              <Button
                style={{ width: "50px" }}
                onClick={() => {
                  setNodeName(id);
                  setEditNodeNameStatus(false);
                }}
              >
                🔄
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Подключение Postgres
          </div>
          <Select
            value={pgConnSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setPgConnSelected(el)}
            options={pgConnOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Схема</div>
          <Select
            value={pgSchemaSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setPgSchemaSelected(el)}
            options={pgSchemaOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Таблица</div>
          <Select
            value={pgTableSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setPgTableSelected(el)}
            options={pgTableOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Очищать перед записью
          </div>
          <Switch
            checked={pgTableRewrite}
            onChange={(x) => {
              setPgTableRewrite(x);
              setPgTableRewriteFilter("");
            }}
          />
          <Input
            disabled={!pgTableRewrite}
            onChange={({ target: { value } }) => setPgTableRewriteFilter(value)}
            value={pgTableRewriteFilter}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            height: "500px",
            overflow: "auto",
          }}
        >
          <div>
            <span style={{ fontWeight: "bold" }}>
              ⤵️ Входящий поток: {SourceId}
            </span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lFields?.length > 0 ? (
                    lFields.map((el: MappingField, ind: number) => (
                      <TableRow key={ind}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {IntegrationFieldsManager.getSourceFieldStatus(
                            el.id,
                            rFields,
                          )}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.id}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.desc}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.type}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет входящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>
              ⤴️ Исходящий поток: {nodeName}
            </span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Действие
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rFields?.length > 0 ? (
                    rFields.map((el: MappingField, ind: number) => (
                      <TableRow key={ind}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.formula && el.formula.length > 0 ? "✅" : "⭕"}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Input
                            value={rFieldsDict[el.uuid]?.id || ""}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRFields((prev) =>
                                prev.map((field) => {
                                  if (field.uuid === el.uuid) {
                                    return { ...field, id: newValue };
                                  }
                                  return field;
                                }),
                              );
                            }}
                            disabled={true}
                          />
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Input
                            value={rFieldsDict[el.uuid]?.desc || ""}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRFields((prev) =>
                                prev.map((field) => {
                                  if (field.uuid === el.uuid) {
                                    return { ...field, desc: newValue };
                                  }
                                  return field;
                                }),
                              );
                            }}
                            disabled={true}
                          />
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Select
                            style={{ width: "100%" }}
                            value={rFieldsDict[el.uuid]?.type || ""}
                            onChange={(e) => {
                              const newValue = e;
                              setRFields((prev) =>
                                prev.map((field: any) => {
                                  if (field.uuid === el.uuid) {
                                    return { ...field, type: newValue };
                                  }
                                  return field;
                                }),
                              );
                            }}
                            options={__optionType}
                            disabled={true}
                          />
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Button onClick={() => setSelectedTransform(el.uuid)}>
                            🔎
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={100}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет исходящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span>
            Параметр мэппинга:{" "}
            {rFields && rFields.find((el) => el.uuid === selectedTransform)?.id}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              height: "100px",
            }}
          >
            <TextArea
              size="large"
              style={{ height: "100px", resize: "none" }}
              disabled={!selectedTransform}
              value={textAreaValue}
              onChange={handleTextAreaChange}
            />
            <Button
              style={{ width: "100px", height: "100px", fontSize: "36px" }}
              onClick={handleEdges}
            >
              👁
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostgresOutput;

const __optionType = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];
