import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Button, Card, Modal, Space, Tag, Typography } from "antd";
import axios from "axios";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import { Context } from "../..";

const { Title, Text } = Typography;

const fetchData = async (url: string) => {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch {
    return null; // Возвращаем null в случае ошибки
  }
};

const checkService = async (service: string) => {
  const baseUrl = `${process.env.REACT_APP_API_URL}/${service}/monitoring`;

  // Выполняем запросы параллельно, но обрабатываем ошибки на уровне каждого запроса
  const statusPromise = fetchData(`${baseUrl}/health`);
  const cpuPromise = fetchData(`${baseUrl}/cpu`);
  const memoryPromise = fetchData(`${baseUrl}/memory`);

  const [status, cpu, memory] = await Promise.all([
    statusPromise,
    cpuPromise,
    memoryPromise,
  ]);

  if (status === null) {
    // Если запрос на статус не удался
    return { status: "down" };
  }

  // Если запросы на CPU и память вернули null, просто возвращаем их как null
  const cpuData = cpu !== null ? cpu.toFixed(0) : "unavailable";
  const memoryData =
    memory !== null
      ? {
          total: memory.total.toFixed(2),
          usage: memory.usage.toFixed(2),
          percent: ((memory.usage / memory.total) * 100).toFixed(2),
        }
      : "unavailable";

  return {
    status: status ? "running" : "error",
    cpu: cpuData,
    memory: memoryData,
  };
};

const SystemHealthPage: React.FC = () => {
  const { store } = useContext(Context);
  useEffect(() => store.setPageName("Мониторинг состояния"), [store]);

  const [service, setService] = useState<any>({
    user: { status: "hz", cpu: 0, memory: { total: 0, usage: 0, percent: 0 } },
    transfer: {
      status: "hz",
      cpu: 0,
      memory: { total: 0, usage: 0, percent: 0 },
    },
    model: { status: "hz", cpu: 0, memory: { total: 0, usage: 0, percent: 0 } },
    math: { status: "hz", cpu: 0, memory: { total: 0, usage: 0, percent: 0 } },
  });

  const checkStatus = async () => {
    const user = await checkService("user");
    const model = await checkService("model");
    const math = await checkService("math");
    const transfer = await checkService("transfer");

    setService({
      user,
      model,
      math,
      transfer,
    });
  };

  useEffect(() => {
    checkStatus();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
      <ServiceStatus
        name={"Модель"}
        status={service.model.status}
        currentVersion={"1.0"}
        latestVersion={"1.0"}
        errorMessage=""
        cpuHistory={[
          {
            time: format(new Date(), "yyyy-MM-dd HH:MM"),
            cpu: service.model.cpu,
          },
        ]}
        memoryHistory={[
          {
            time: format(new Date(), "yyyy-MM-dd HH:MM"),
            memory: service.model.memory.percent,
          },
        ]}
      />
      <ServiceStatus
        name={"Математика"}
        status={service.math.status}
        currentVersion={"1.0"}
        latestVersion={"1.0"}
        errorMessage=""
        cpuHistory={[
          {
            time: format(new Date(), "yyyy-MM-dd HH:MM"),
            cpu: service.math.cpu,
          },
        ]}
        memoryHistory={[
          {
            time: format(new Date(), "yyyy-MM-dd HH:MM"),
            memory: service.math.memory.percent,
          },
        ]}
      />
      <ServiceStatus
        name={"Пользователи"}
        status={service.user.status}
        currentVersion={"1.0"}
        latestVersion={"1.0"}
        errorMessage=""
        cpuHistory={[
          {
            time: format(new Date(), "yyyy-MM-dd HH:MM"),
            cpu: service.user.cpu,
          },
        ]}
        memoryHistory={[
          {
            time: format(new Date(), "yyyy-MM-dd HH:MM"),
            memory: service.user.memory.percent,
          },
        ]}
      />
      <ServiceStatus
        name={"Интеграция"}
        status={service.transfer.status}
        currentVersion={"1.0"}
        latestVersion={"1.0"}
        errorMessage=""
        cpuHistory={[
          {
            time: format(new Date(), "yyyy-MM-dd HH:MM"),
            cpu: service.transfer.cpu,
          },
        ]}
        memoryHistory={[
          {
            time: format(new Date(), "yyyy-MM-dd HH:MM"),
            memory: service.transfer.memory.percent,
          },
        ]}
      />
    </div>
  );
};

export default SystemHealthPage;

const ServiceStatus = ({
  name,
  status,
  currentVersion,
  latestVersion,
  errorMessage,
  requestHistory,
  cpuHistory,
  memoryHistory,
}: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedChart, setSelectedChart] = useState<string | null>(null);

  const showModal = (chartType: string) => {
    setSelectedChart(chartType);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedChart(null);
  };

  const renderChart = () => {
    const width = 1400;
    const height = 650;

    switch (selectedChart) {
      //   case "requests":
      //     return (
      //       <LineChart width={width} height={height} data={requestHistory}>
      //         <CartesianGrid strokeDasharray="3 3" />
      //         <XAxis dataKey="time" />
      //         <YAxis />
      //         <Tooltip />
      //         <Line
      //           type="monotone"
      //           dataKey="requests"
      //           stroke="#8884d8"
      //           dot={true}
      //         />
      //       </LineChart>
      //     );
      case "cpu":
        return (
          <BarChart width={width} height={height} data={cpuHistory}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis
              width={70}
              domain={[0, "dataMax"]} // Автоматическая настройка верхней границы
              tickFormatter={(value) => `${value} ms`} // Форматирование меток оси Y
            />
            <Tooltip formatter={(value) => `${value} ms`} />
            <Bar dataKey="cpu" fill="#82ca9d" />
          </BarChart>
        );
      case "memory":
        return (
          <BarChart width={width} height={height} data={memoryHistory}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis
              width={70}
              domain={[0, 100]}
              tickFormatter={(value) => `${value} %`}
            />
            <Tooltip />
            <Bar dataKey="memory" fill="#ff7300" />
          </BarChart>
        );
      default:
        return null;
    }
  };

  let statusTag;
  let statusIcon;
  let statusColor;

  switch (status) {
    case "running":
      statusTag = "Работает";
      statusIcon = <CheckCircleOutlined />;
      statusColor = "green";
      break;
    case "error":
      statusTag = "Есть ошибки";
      statusIcon = <WarningOutlined />;
      statusColor = "orange";
      break;
    case "down":
      statusTag = "Не работает";
      statusIcon = <CloseCircleOutlined />;
      statusColor = "red";
      break;
    default:
      statusTag = "Неизвестный статус";
      statusIcon = <WarningOutlined />;
      statusColor = "gray";
      break;
  }

  return (
    <>
      <Card style={{ width: "350px", margin: "16px auto", overflow: "auto" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title level={4} style={{ margin: "8px 0" }}>
            {name}
          </Title>
          <Tag color={statusColor} icon={statusIcon} style={{ height: "20px" }}>
            {statusTag}
          </Tag>
        </div>
        <Space direction="vertical" size="small">
          <Text>
            Версия: {currentVersion} (акт. {latestVersion})
          </Text>
          {/* <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Title level={5} style={{ margin: 0 }}>
                Запросы
              </Title>
              <Button
                onClick={() => showModal("requests")}
                type="link"
                style={{ padding: 0 }}
              >
                Развернуть
              </Button>
            </div>
            <LineChart width={300} height={150} data={requestHistory}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="requests"
                stroke="#8884d8"
                dot={true}
              />
            </LineChart>
          </div> */}

          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Title level={5} style={{ margin: 0 }}>
                CPU
              </Title>
              <Button
                onClick={() => showModal("cpu")}
                type="link"
                style={{ padding: 0 }}
              >
                Развернуть
              </Button>
            </div>
            <BarChart width={300} height={200} data={cpuHistory}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" />
              <YAxis
                width={70}
                domain={[0, "dataMax"]} // Автоматическая настройка верхней границы
                tickFormatter={(value) => `${value} ms`} // Форматирование меток оси Y
              />
              <Tooltip formatter={(value) => `${value} ms`} />
              <Bar dataKey="cpu" fill="#82ca9d" />
            </BarChart>
          </div>

          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Title level={5} style={{ margin: 0 }}>
                RAM
              </Title>
              <Button
                onClick={() => showModal("memory")}
                type="link"
                style={{ padding: 0 }}
              >
                Развернуть
              </Button>
            </div>
            <BarChart width={300} height={200} data={memoryHistory}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" />
              <YAxis
                width={70}
                domain={[0, 100]}
                tickFormatter={(value) => `${value} %`}
              />
              <Tooltip />
              <Bar dataKey="memory" fill="#ff7300" />
            </BarChart>
          </div>

          {status === "error" && errorMessage && (
            <Text type="danger">Сообщение об ошибке: {errorMessage}</Text>
          )}
        </Space>
      </Card>

      <Modal
        title={
          selectedChart === "requests"
            ? "Количество запросов"
            : selectedChart === "cpu"
              ? "Использование CPU"
              : "Использование памяти"
        }
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="1480px"
        style={{ top: 20 }}
      >
        {renderChart()}
      </Modal>
    </>
  );
};

const __demoRequestHistory = [
  { time: "2024-08-19", requests: 450 },
  { time: "2024-08-20", requests: 500 },
  { time: "2024-08-21", requests: 470 },
];

const __demoCpuHistory = [
  { time: "2024-08-19", cpu: 1051 },
  { time: "2024-08-20", cpu: 1051 },
  { time: "2024-08-21", cpu: 1051 },
];

const __demoMemoryHistory = [
  { time: "2024-08-19", memory: 86.61 },
  { time: "2024-08-20", memory: 86.84 },
  { time: "2024-08-21", memory: 86.84 },
];

const CustomLabel = ({ x, y, value }: any) => (
  <text x={x} y={y} dy={-10} fill="#666" textAnchor="middle">
    {`${value} MB`}
  </text>
);
