import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeTypes,
  getBezierPath,
  useReactFlow,
} from "@xyflow/react";
import { Button, Popconfirm, message } from "antd";
import { useCallback } from "react";
import { useHistory } from "..";

const ButtonEdge: React.FC<any> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
}) => {
  const { historyRecord } = useHistory();
  const { setEdges, getNodes, getEdges } = useReactFlow();

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const onConfirm = useCallback(
    (event: any) => {
      event.stopPropagation();
      setEdges((edges) => edges.filter((edge) => edge.id !== id));
      historyRecord({
        nodes: getNodes(),
        edges: getEdges(),
        comment: "OnEdgesChange",
      });
      message.success(`Связь удалена`);
    },
    [id, setEdges],
  );

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={{ strokeWidth: 2, stroke: "#FF0072" }}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: "all",
          }}
          className="nodrag nopan"
        >
          <Popconfirm
            title="Действительно хотите удалить связь?"
            onConfirm={onConfirm}
            okText="Да"
            cancelText="Нет"
          >
            <Button style={{ padding: 0 }}>❌</Button>
          </Popconfirm>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export const edgeTypes: EdgeTypes = {
  buttonedge: ButtonEdge,
};
