import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Edge, Node } from "@xyflow/react";
import { Button, Input, InputNumber, Select, message } from "antd";
import Empty from "antd/lib/empty";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";

interface Input {
  id: string;
  nodes: Node[];
  edges: Edge[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
}

const { TextArea } = Input;

const RowGenWindow: React.FC<Input> = ({
  id,
  nodes,
  edges,
  setNodes,
  setEdges,
}) => {
  const [nodeName, setNodeName] = useState<string>(id);
  const [editNodeNameStatus, setEditNodeNameStatus] = useState<boolean>(false);

  const [editNumGenerator, setEditNumGenerator] = useState<boolean>(false);
  const [numGenerator, setNumGenerator] = useState<number>(0);

  const [editStartNumGenerator, setEditStartNumGenerator] =
    useState<boolean>(false);
  const [startNumGenerator, setStartNumGenerator] = useState<number>(0);

  const [lFields, setLFields] = useState<MappingField[]>([]);
  const [rFields, setRFields] = useState<MappingField[]>([]);
  const [rFieldsDict, setRFieldsDict] = useState<{
    [key: string]: MappingField;
  }>({});
  const [selectedTransform, setSelectedTransform] = useState<string | null>(
    null,
  );

  const onChangeNodeId = () => {
    IntegrationManager.changeNodeId(
      id,
      nodeName,
      nodes,
      () => {},
      setNodes,
      setEdges,
      setEditNodeNameStatus,
    );
  };

  useEffect(() => {
    setNodeName(id);
    setSelectedTransform(null);
    handleEdges();
  }, [id]);

  useEffect(() => {
    if (rFields && rFields.length > 0) {
      setNodes((prev) => {
        const res = prev.map((rec) => {
          if (rec.id === id) {
            rec.data.formula = rFields;
          }
          return rec;
        });
        return res;
      });
      let res: { [key: string]: MappingField } = {};
      for (const field of rFields) {
        res[field.uuid] = field;
      }
      setRFieldsDict(res);
    }
  }, [rFields]);

  useEffect(() => {
    handleMoveAll();
  }, [lFields]);

  const handleEdges = () => {
    setNodeName(id);
    const fNode: any = nodes.find((el) => el.id === id)?.data;
    if (!fNode) {
      // message.error('Не обнаружена нода. Возможно, вы ее удалили перед тем как открыть.');
      return;
    }
    const findEdges = edges.find((el) => el.target === id);
    if (findEdges) {
      const findSourceNode: any = nodes.find(
        (el) => el.id === findEdges.source,
      );
      if (findSourceNode?.data.formula) {
        setLFields(findSourceNode?.data.formula);
      } else {
        message.warning(
          `Обратите внимание, что в ноде ${findEdges.source} не заданы поля`,
        );
      }
    } else {
      message.warning(
        `Обратите внимание, что в эту ноду (${id}) не входит поток данных`,
      );
    }

    setNumGenerator(fNode.generateNumber ? fNode.generateNumber : 0);
    setStartNumGenerator(fNode.generateStart ? fNode.generateStart : 0);
  };

  const handleMoveAll = () => {
    // Получаем блоки справа
    const find: any = nodes.find((el) => el.id === id);
    const rF: MappingField[] = find?.data.formula;

    let result: MappingField[] = [];
    for (const el of lFields) {
      // Есть слева, есть справа
      if (rF) {
        const rRow = rF.find((z) => z.id === el.id);
        if (rRow) {
          result.push(rRow);
        }
        // Есть слева, нет справа
        if (!rRow) {
          result.push({ ...el, formula: "", uuid: uuidv4() });
        }
      } else {
        result.push({ ...el, formula: "", uuid: uuidv4() });
      }
    }
    setRFields(result);
  };

  const handleNumberChange = () => {
    setNodes((prev) => {
      const res = prev.map((rec) => {
        if (rec.id === id) {
          rec.data.generateNumber = numGenerator;
        }
        return rec;
      });
      return res;
    });
    setEditNumGenerator(false);
  };

  const handleStartChange = () => {
    setNodes((prev) => {
      const res = prev.map((rec) => {
        if (rec.id === id) {
          rec.data.generateStart = startNumGenerator;
        }
        return rec;
      });
      return res;
    });
    setEditStartNumGenerator(false);
  };

  const [textAreaValue, setTextAreaValue] = useState("");

  useEffect(() => {
    if (selectedTransform) {
      setTextAreaValue(rFieldsDict[selectedTransform]?.formula || "");
    } else {
      setTextAreaValue("");
    }
  }, [selectedTransform, rFieldsDict]);

  const handleTextAreaChange = (e: any) => {
    const newValue = e.target.value;
    setTextAreaValue(newValue);
    // Теперь обновляем глобальное состояние
    setRFields((prev) =>
      prev.map((field) => {
        if (field.uuid === selectedTransform) {
          return { ...field, formula: newValue };
        }
        return field;
      }),
    );
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Наименование ноды
          </div>
          <Input
            value={nodeName}
            status={
              nodeName.toString().match(/^[A-Za-z][A-Za-z0-9\-_]*$/)
                ? ""
                : "error"
            }
            disabled={!editNodeNameStatus}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => {
              if (!el.target.value.match(/^[A-Za-z][A-Za-z0-9\-_]*$/)) {
                message.warning(
                  "Вводимое наименование содержит недопустимые символы",
                );
              }
              setNodeName(el.target.value);
            }}
            allowClear
          />
          {!editNodeNameStatus && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditNodeNameStatus(true)}
            >
              ✏️
            </Button>
          )}
          {editNodeNameStatus && (
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <Button style={{ width: "50px" }} onClick={onChangeNodeId}>
                💾
              </Button>
              <Button
                style={{ width: "50px" }}
                onClick={() => {
                  setNodeName(id);
                  setEditNodeNameStatus(false);
                }}
              >
                🔄
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Кол-во значений
          </div>
          <InputNumber
            value={numGenerator}
            disabled={!editNumGenerator}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setNumGenerator(Number(el))}
          />
          {!editNumGenerator && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditNumGenerator(true)}
            >
              ✏️
            </Button>
          )}
          {editNumGenerator && (
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <Button style={{ width: "50px" }} onClick={handleNumberChange}>
                💾
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Стартовое значение
          </div>
          <InputNumber
            value={startNumGenerator}
            disabled={!editStartNumGenerator}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setStartNumGenerator(Number(el))}
          />
          {!editStartNumGenerator && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditStartNumGenerator(true)}
            >
              ✏️
            </Button>
          )}
          {editStartNumGenerator && (
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <Button style={{ width: "50px" }} onClick={handleStartChange}>
                💾
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            height: "500px",
            overflow: "auto",
          }}
        >
          <div style={{ width: "700px" }}>
            <span style={{ fontWeight: "bold" }}>
              ⤴️ Входящий/Исходящий поток: {nodeName}
            </span>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "20px",
                      padding: "10px",
                      height: "32px",
                    }}
                  ></TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Идентификатор
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "250px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Описание
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Тип
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Действие
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rFields?.length > 0 ? (
                  rFields.map((el: MappingField, ind: number) => (
                    <TableRow key={ind}>
                      <TableCell style={{ padding: "10px", height: "32px" }}>
                        {el.formula && el.formula.length > 0 ? "✅" : "⭕"}
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Input
                          value={rFieldsDict[el.uuid]?.id || ""}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setRFields((prev) =>
                              prev.map((field) => {
                                if (field.uuid === el.uuid) {
                                  return { ...field, id: newValue };
                                }
                                return field;
                              }),
                            );
                          }}
                          disabled={true}
                        />
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Input
                          value={rFieldsDict[el.uuid]?.desc || ""}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setRFields((prev) =>
                              prev.map((field) => {
                                if (field.uuid === el.uuid) {
                                  return { ...field, desc: newValue };
                                }
                                return field;
                              }),
                            );
                          }}
                          disabled={true}
                        />
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Select
                          style={{ width: "100%" }}
                          value={rFieldsDict[el.uuid]?.type || ""}
                          onChange={(e) => {
                            const newValue = e;
                            setRFields((prev) =>
                              prev.map((field: any) => {
                                if (field.uuid === el.uuid) {
                                  return { ...field, type: newValue };
                                }
                                return field;
                              }),
                            );
                          }}
                          options={__optionType}
                          disabled={true}
                        />
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Button onClick={() => setSelectedTransform(el.uuid)}>
                          🔎
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={100}>
                      <Empty
                        imageStyle={{ height: "50px" }}
                        description="Нет исходящих полей"
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span>
            Параметр фильтра:{" "}
            {rFields && rFields.find((el) => el.uuid === selectedTransform)?.id}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              height: "100px",
            }}
          >
            <TextArea
              size="large"
              style={{ height: "100px", resize: "none" }}
              disabled={!selectedTransform}
              value={textAreaValue}
              onChange={handleTextAreaChange}
            />
            <Button
              style={{ width: "100px", height: "100px", fontSize: "36px" }}
              onClick={handleEdges}
            >
              👁
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RowGenWindow;

const __testFields: MappingField[] = [
  { uuid: "1111", id: "PRDID", desc: "Идентификатор продукта", type: "STRING" },
  { uuid: "1112", id: "CHANID", desc: "Канал", type: "STRING" },
  {
    uuid: "1113",
    id: "SHIPTOID",
    desc: "Идентификатор продукта",
    type: "STRING",
  },
  { uuid: "1114", id: "REQQTY", desc: "Заказанный объем", type: "DECIMAL" },
  { uuid: "1115", id: "PRICE", desc: "", type: "DECIMAL" },
];

const __optionType = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];
