import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Button, notification, Popconfirm, Tag } from "antd";
import type {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { Context } from "../../..";
import FlatFileService from "../../../entities/model/FlatFileService";
import { IFileFormatList } from "../../../entities/types/IFileFormat";
import FFCreateModal from "./createModal";
import FFEditModal from "./editModal";

type Response = {
  code: number;
  text: string;
  data: {
    count: number;
    rows: IFileFormatList[];
  };
};

const IntegrationFileFormatsPage = () => {
  const { store } = useContext(Context);
  store.setPageName("Интеграция: Формат файла");

  const modalCreateRef: any = useRef();
  const modalEditRef: any = useRef();

  const [selectedFormat, setSelectedFormat] = useState<string | null>(null);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState<MRT_ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "id", desc: false },
  ]);

  const {
    data: { data: { rows, count } } = { data: { rows: [], count: 0 } },
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<Response>({
    placeholderData: keepPreviousData,
    queryKey: [
      "integration-file-formats",
      filters,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      const response = await FlatFileService.getAll({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        filters,
        sorting,
      });

      if (response.code !== 1) {
        throw new Error(
          `Ошибка при попытке загрузки списка форматов файлов: "${response.text}"`,
        );
      }

      return response;
    },
  });

  const deleteFileFormat = useCallback(
    async (id: string) => {
      const response = await FlatFileService.delete(id);
      if (response.code === 1) {
        notification.warning({ message: `Формат файла ${id} успешно удален` });
        refetch();
      } else {
        notification.error({
          message: "Возникла ошибка при удалении формата",
          description: `Код ответа: ${response.code}`,
        });
      }
    },
    [refetch],
  );

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: "Идентификатор",
        accessorKey: "id",
        enableFilterMatchHighlighting: false,
      },
      {
        header: "Описание",
        accessorKey: "desc",
      },
      {
        header: "Теги",
        accessorKey: "tags",
        enableSorting: false,
        Cell: (e) => (
          <>
            {e.row.original.tags.map((tag: string) => (
              <Tag>{tag}</Tag>
            ))}
          </>
        ),
      },
      {
        header: "Действие",
        enableGrouping: false,
        Cell: ({ row }) => (
          <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
            <Button
              size="small"
              onClick={() => {
                setSelectedFormat(row.original.id);
                modalEditRef.current?.openEditModal();
              }}
            >
              ✏
            </Button>
            <Popconfirm
              title={`Вы действительно хотите удалить формат файла ${row.original.id}?`}
              okText="Да"
              cancelText="Нет"
              onConfirm={() => deleteFileFormat(row.original.id)}
            >
              <Button size="small">❌</Button>
            </Popconfirm>
          </div>
        ),
      },
    ],
    [deleteFileFormat],
  );

  const table = useMaterialReactTable({
    data: rows,
    columns: columns,
    initialState: { density: "compact" },
    localization: MRT_Localization_RU,

    enableColumnResizing: false,
    enableStickyHeader: true,
    enableGlobalFilter: false,

    enableGrouping: true,
    groupedColumnMode: "remove",

    layoutMode: "grid",

    muiToolbarAlertBannerProps: isError
      ? { color: "error", children: "Возникла ошибка при загрузке данных" }
      : undefined,

    muiTableBodyRowProps: () => ({
      sx: {
        height: "50px",
      },
    }),

    manualFiltering: true,
    onColumnFiltersChange: setFilters,

    manualPagination: true,
    onPaginationChange: setPagination,

    manualSorting: true,
    onSortingChange: setSorting,

    rowCount: count,

    state: {
      columnFilters: filters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <div>
      <div
        className="ManageTable"
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "15px",
          alignItems: "center",
        }}
      >
        <Button
          size="small"
          onClick={() => {
            modalCreateRef.current?.openCreateModal();
          }}
        >
          ➕
        </Button>
        <Button size="small" onClick={() => refetch()}>
          🔄
        </Button>
      </div>
      <div style={{ marginTop: "15px" }}>
        <MaterialReactTable table={table} />
      </div>
      <FFCreateModal apiRef={modalCreateRef} fetchData={() => refetch()} />
      <FFEditModal
        apiRef={modalEditRef}
        fetchData={() => refetch()}
        fileFormat={selectedFormat}
      />
    </div>
  );
};

export default IntegrationFileFormatsPage;
