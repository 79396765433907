import { NodeResizer, Position, useReactFlow } from "@xyflow/react";
import { ColorPicker, Divider, Input } from "antd";
import "./blocks.css";
import colors from "./colors";
import CustomHandle from "./handlers/customHandle";

const basicElementStyle: any = {
  display: "flex",
  flexDirection: "column",
  zIndex: 2,

  height: "60px",
  width: "240px",

  gap: "7px",

  borderRadius: "8px",

  color: "white",
};

interface Settings {
  nodeName: string;
  nodeBusinessName: string;
  color: string;

  iconUri: string;

  inputs: number;
  outputs: number;

  data: any;
}

const Type_Node = (settings: Settings) => {
  return (
    <div className="block-body">
      {settings.inputs > 0 && (
        <CustomHandle
          type="target"
          position={Position.Left}
          id={`${settings.nodeName}_input`}
          isConnectable={settings.inputs}
        />
      )}
      <div
        style={{
          ...basicElementStyle,
          background: settings.color,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",

            height: "20px",
            paddingLeft: "5px",
            backgroundColor: "#444042",
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          <img
            src={settings.iconUri}
            style={{ filter: "grayscale(1)", width: "14px", opacity: 0.9 }}
          />
          <span style={{ fontSize: "10px" }}>
            {settings.nodeBusinessName.toUpperCase()}
          </span>
        </div>
        <span
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            paddingLeft: "10px",
            paddingRight: "10px",
            fontWeight: "bold",
          }}
        >
          {settings.data.label}
        </span>
        {/* <div
          style={{
            paddingLeft: '15px',
            paddingRight: '15px',

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end'
          }}
        >
          <img
            className={'block_button'}
            src={'/img/blocks/block_settings.svg'}
            style={{ width: '15px' }}
          />
        </div> */}
      </div>
      {settings.outputs > 0 && (
        <CustomHandle
          type="source"
          position={Position.Right}
          id={`${settings.nodeName}_output`}
        />
      )}
    </div>
  );
};

export const Type_Mapping = ({ data }: any) => {
  return Type_Node({
    nodeName: "Mapping",
    nodeBusinessName: "Выбор",
    iconUri: "/img/blocks/mapping.svg",
    color: colors.mapping,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_GroupBy = ({ data }: any) => {
  return Type_Node({
    nodeName: "Group",
    nodeBusinessName: "Группировка",
    iconUri: "/img/blocks/group.svg",
    color: colors.group,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_Join = ({ data }: any) => {
  return Type_Node({
    nodeName: "Join",
    nodeBusinessName: "Соединение",
    iconUri: "/img/blocks/join.svg",
    color: colors.join,
    inputs: 2,
    outputs: 1,
    data,
  });
};

export const Type_RowGen = ({ data }: any) => {
  return Type_Node({
    nodeName: "Generator",
    nodeBusinessName: "Генератор",
    iconUri: "/img/blocks/rowgen.svg",
    color: colors.rowGen,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_Extrapolation = ({ data }: any) => {
  return Type_Node({
    nodeName: "Forecast",
    nodeBusinessName: "Прогноз",
    iconUri: "/img/blocks/rowgen.svg",
    color: colors.extrapolation,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_Filter = ({ data }: any) => {
  return Type_Node({
    nodeName: "Filter",
    nodeBusinessName: "Фильтр",
    iconUri: "/img/blocks/filter.svg",
    color: colors.filter,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_FlatFile = ({ data }: any) => {
  return Type_Node({
    nodeName: "FlatFileInput",
    nodeBusinessName: "Плоский файл: вход",
    iconUri: "/img/blocks/mapping.svg",
    color: colors.flatFile,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_KnowledgeSpaceClassInput = ({ data }: any) => {
  return Type_Node({
    nodeName: "KSClassInput",
    nodeBusinessName: "KS Класс: вход",
    iconUri: "/img/blocks/ks.svg",
    color: colors.knowledgeSpace,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_KnowledgeSpaceDictionaryInput = ({ data }: any) => {
  return Type_Node({
    nodeName: "KSDictionaryInput",
    nodeBusinessName: "KS Словарь: вход",
    iconUri: "/img/blocks/ks.svg",
    color: colors.knowledgeSpace,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_PostgresInput = ({ data }: any) => {
  return Type_Node({
    nodeName: "PostgresInput",
    nodeBusinessName: "Postgres: вход",
    iconUri: "/img/blocks/postgres.svg",
    color: colors.postgres,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_ClickhouseInput = ({ data }: any) => {
  return Type_Node({
    nodeName: "ClickhouseInput",
    nodeBusinessName: "Clickhouse: вход",
    iconUri: "/img/blocks/clickhouse.svg",
    color: colors.clickhouse,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_TargetFlatFile = ({ data }: any) => {
  return Type_Node({
    nodeName: "FlatFileOutput",
    nodeBusinessName: "Плоский файл: выход",
    iconUri: "/img/blocks/mapping.svg",
    color: colors.flatFile,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_KnowledgeSpaceClassOutput = ({ data }: any) => {
  return Type_Node({
    nodeName: "KSClassOutput",
    nodeBusinessName: "KS Класс: выход",
    iconUri: "/img/blocks/ks.svg",
    color: colors.knowledgeSpace,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_PlanXInput = ({ data }: any) => {
  return Type_Node({
    nodeName: "PXInput",
    nodeBusinessName: "Planx: вход",
    iconUri: "/img/blocks/planx.svg",
    color: colors.planx,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_PlanXOutput = ({ data }: any) => {
  return Type_Node({
    nodeName: "PXOutput",
    nodeBusinessName: "Planx: выход",
    iconUri: "/img/blocks/planx.svg",
    color: colors.planx,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_PostgresOutput = ({ data }: any) => {
  return Type_Node({
    nodeName: "PostgresOutput",
    nodeBusinessName: "Postgres: выход",
    iconUri: "/img/blocks/postgres.svg",
    color: colors.postgres,
    inputs: 1,
    outputs: 1,

    data,
  });
};

export const Type_RabbitOutput = ({ data }: any) => {
  return Type_Node({
    nodeName: "RabbitMQOutput",
    nodeBusinessName: "RabbitMQ: выход",
    iconUri: "/img/blocks/rabbitmq.svg",
    color: colors.rabbitmq,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_LaunchFlow = ({ data }: any) => {
  return Type_Node({
    nodeName: "LaunchFlow",
    nodeBusinessName: "Launcher: выход",
    iconUri: "/img/shape_merge.svg",
    color: colors.launchFlow,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export function Type_Note({ id, data, selected }: any) {
  const { setNodes, getNode } = useReactFlow();

  const node = getNode(id)!;

  const { color, label } = data;

  const setColor = (color: string) =>
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id ? { ...node, data: { ...node.data, color } } : node,
      ),
    );

  const setLabel = (label: string) =>
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id ? { ...node, data: { ...node.data, label } } : node,
      ),
    );

  return (
    <div className="note-body">
      <NodeResizer minWidth={100} minHeight={50} isVisible={selected} />
      <div
        style={{
          ...basicElementStyle,
          width: node.width!,
          height: node.height!,
          background: color,
          padding: 5,
          justifyContent: "start",
        }}
      >
        <span
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            alignItems: "start",
          }}
        >
          <div
            style={{
              display: "flex",
              maxWidth: "25px",
              flexDirection: "column",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <ColorPicker
              className="custom-drag-handle"
              size="small"
              value={color}
              onChange={(_, hex) => setColor(hex)}
              styles={{
                popupOverlayInner: {
                  width: 468 + 24,
                },
              }}
              presets={[
                {
                  label: "Recommended",
                  colors: presetColors,
                },
              ]}
              panelRender={(_, { components: { Picker, Presets } }) => (
                <div
                  className="custom-panel"
                  style={{
                    display: "flex",
                    width: 468,
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <Presets />
                  </div>
                  <Divider
                    type="vertical"
                    style={{
                      height: "auto",
                    }}
                  />
                  <div
                    style={{
                      width: 234,
                    }}
                  >
                    <Picker />
                  </div>
                </div>
              )}
            />
          </div>
          <Input.TextArea
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            autoSize={{ minRows: 1, maxRows: 25 }}
            variant="borderless"
            style={{ color: getFontColor(color), padding: 0 }}
          />
        </span>
      </div>
    </div>
  );
}

const presetColors = [
  "#000000",
  "#000000E0",
  "#000000A6",
  "#00000073",
  "#00000040",
  "#00000026",
  "#0000001A",
  "#00000012",
  "#0000000A",
  "#00000005",
  "#F5222D",
  "#FA8C16",
  "#FADB14",
  "#8BBB11",
  "#52C41A",
  "#13A8A8",
  "#1677FF",
  "#2F54EB",
  "#722ED1",
  "#EB2F96",
  "#F5222D4D",
  "#FA8C164D",
  "#FADB144D",
  "#8BBB114D",
  "#52C41A4D",
  "#13A8A84D",
  "#1677FF4D",
  "#2F54EB4D",
  "#722ED14D",
  "#EB2F964D",
];

function getFontColor(backgroundColor: string) {
  // Функция для преобразования HEX в RGB
  function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
      hex.length > 7 ? hex.slice(0, -2) : hex,
    );
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          a: hex.length > 7 ? parseInt(hex.slice(-2), 16) : parseInt("E1", 16),
        }
      : null;
  }

  // Функция для расчета яркости цвета
  function luminance(r: number, g: number, b: number, a: number) {
    var c = [r, g, b].map(function (v) {
      v /= 255;
      return v <= 0.03928
        ? (v / 12.92) * (a / 100)
        : Math.pow((v + 0.055) / 1.055, 2.4) * (a / 100);
    });
    return c[0] * 0.2126 + c[1] * 0.7152 + c[2] * 0.0722;
  }

  // Преобразуем HEX в RGB
  var rgb = hexToRgb(backgroundColor);

  // Рассчитываем яркость цвета
  if (rgb) {
    const brightness = luminance(rgb.r, rgb.g, rgb.b, rgb.a);

    // Determine text color based on brightness and alpha
    if (rgb.a < 50) {
      return "black";
    }

    if (rgb.a > 150) {
      return "white";
    }

    if (brightness > 0.18) {
      if (rgb.a < 50) {
        return "white"; // Semi-transparent background, use white text
      } else {
        return "black"; // Opaque background, use black text
      }
    }
    //  else {
    //   // Check contrast ratio for low-opacity backgrounds (0-5%)
    //   if (rgb.a <= 50) {
    //     if(brightness === 0){
    //       return 'black'
    //     }
    //     const contrastRatio = luminance(255, 255, 255, 1) / brightness;

    //     if (contrastRatio < 4.5) {
    //       return 'black'; // Use black text for better contrast
    //     }
    //   }
    //   return 'white'; // Dark background, use white text
    // }
  }
}
