import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Edge, Node } from "@xyflow/react";
import { Button, Input, Select, Switch, message, notification } from "antd";
import Empty from "antd/lib/empty";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import KSService from "../../../../../entities/model/KSService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { getCyrillic } from "../../../../../shared/helper/cyrillic";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";
import IntegrationFieldsManager from "../utils/fields";

interface Input {
  id: string;
  nodes: Node[];
  edges: Edge[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
}

interface Format {
  value: string;
  label: string;
}

const { TextArea } = Input;

const KnowledgeSpaceClassOutput: React.FC<Input> = ({
  id,
  nodes,
  edges,
  setNodes,
  setEdges,
}) => {
  const [nodeName, setNodeName] = useState<string>(id);
  const [editNodeNameStatus, setEditNodeNameStatus] = useState<boolean>(false);

  const [SourceId, setSourceId] = useState<string | null>(null);

  const [lFields, setLFields] = useState<MappingField[]>([]);
  const [rFields, setRFields] = useState<MappingField[]>([]);
  const [rFieldsDict, setRFieldsDict] = useState<{
    [key: string]: MappingField;
  }>({});
  const [selectedTransform, setSelectedTransform] = useState<string | null>(
    null,
  );

  const [fields, setFields] = useState<MappingField[]>([]);

  const onChangeNodeId = () => {
    IntegrationManager.changeNodeId(
      id,
      nodeName,
      nodes,
      () => {},
      setNodes,
      setEdges,
      setEditNodeNameStatus,
    );
  };

  useEffect(() => {
    setSelectedTransform(null);
    handleEdges();
    setNodeName(id);
    const node: any = nodes.find((el) => el.id === id)?.data;
    if (node) {
      setFields(node.formula);
      setKSSystemSelected(node.system);
      setKSProjectSelected(node.project);
      setKSModelSelected(node.model);
      setKSClassSelected(node.class);
      setKSDatasetSelected(node.dataset);
      setKSTimeDependentSelected(node.timeDependent);
      setKSFolderSelected(node.folder);
    }
    KSgetSystemList();
  }, [id]);

  const [ksSystemOptions, setKSSystemOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [ksSystemSelected, setKSSystemSelected] = useState<string>("");

  const [ksProjectOptions, setKSProjectOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [ksProjectSelected, setKSProjectSelected] = useState<string>("");

  const [ksClassOptions, setKSClassOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [ksClassSelected, setKSClassSelected] = useState<string>("");

  const [ksModelOptions, setKSModelOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [ksModelSelected, setKSModelSelected] = useState<string>("");

  const [ksDatasetOptions, setKSDatasetOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [ksDatasetSelected, setKSDatasetSelected] = useState<string>("");

  const [ksTimeDependentSelected, setKSTimeDependentSelected] =
    useState<boolean>(false);

  const [ksFolderOptions, setKSFolderOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [ksFolderSelected, setKSFolderSelected] = useState<string>("");

  const KSgetSystemList = async () => {
    const getSystems = await KSService.getAll();
    setKSSystemOptions(
      getSystems.data.map((el: any) => ({ label: el.id, value: el.id })),
    );
  };

  const KSgetProjectList = async () => {
    if (ksSystemSelected && ksSystemSelected !== "") {
      const getProjects = await KSService.getProjects(ksSystemSelected);
      if (getProjects.code === 1) {
        setKSProjectOptions((prevOptions: any) =>
          getProjects.data.map((el: any) => ({
            label: el.name,
            value: el.uuid,
          })),
        );
      } else {
        notification.error({
          message: getProjects.text,
          description: `Код ответа: ${getProjects.code}`,
        });
      }
    }
  };

  useEffect(() => {
    KSgetProjectList();
  }, [ksSystemSelected]);

  const KSgetClassList = async () => {
    const getClasses = await KSService.getClasses(
      ksSystemSelected,
      ksProjectSelected,
    );
    if (getClasses.code === 1) {
      setKSClassOptions((prevOptions: any) =>
        getClasses.data.map((el: any) => ({
          label: el.name,
          value: el.uuid,
        })),
      );
    }
  };

  useEffect(() => {
    KSgetClassList();
  }, [ksProjectSelected]);

  const KSgetModelList = async () => {
    const getModels = await KSService.getModels(
      ksSystemSelected,
      ksProjectSelected,
    );
    if (getModels.code === 1) {
      setKSModelOptions((prevOptions: any) =>
        getModels.data.map((el: any) => ({
          label: el.name,
          value: el.uuid,
        })),
      );
    }
  };

  useEffect(() => {
    KSgetModelList();
  }, [ksProjectSelected]);

  const KSgetFigures = async () => {
    const getFigures = await KSService.getFigures(
      ksSystemSelected,
      ksProjectSelected,
      ksClassSelected,
    );
    if (getFigures.code === 1) {
      setFields(
        getFigures.data
          .filter((el: any) => el.canBeTimed === ksTimeDependentSelected)
          .map((el: any) => ({
            uuid: el.uuid,
            id: getCyrillic(el.name.replace(/[^A-Za-zА-Яа-яёЁ0-9]/g, "")),
            //.replaceAll(' ', ''),
            desc: el.name,
            type: el.dataType === "number" ? "DECIMAL" : "STRING",
            figure: true,
          })),
      );
      if (ksTimeDependentSelected) {
        setFields((prev) => [
          {
            uuid: uuidv4(),
            id: "_Date",
            type: "DATE",
            desc: "Дата для показателей со временем",
            figure: false,
          },
          {
            uuid: uuidv4(),
            id: "_Object",
            type: "STRING",
            desc: "Наименование объекта",
            figure: false,
          },
          ...prev,
        ]);
      } else {
        setFields((prev) => [
          {
            uuid: uuidv4(),
            id: "_Object",
            type: "STRING",
            desc: "Наименование объекта",
          },
          ...prev,
        ]);
      }
    }
  };

  useEffect(() => {
    KSgetFigures();
  }, [ksClassSelected, ksTimeDependentSelected]);

  useEffect(() => {
    if (rFields) {
      setNodes((prev) => {
        const res = prev.map((rec) => {
          if (rec.id === id) {
            rec.data.formula = rFields;
          }
          return rec;
        });
        return res;
      });
      let res: { [key: string]: MappingField } = {};
      for (const field of rFields) {
        res[field.uuid] = field;
      }
      setRFieldsDict(res);
    }
  }, [rFields]);

  const KSgetDataset = async () => {
    if (
      ksSystemSelected &&
      ksSystemSelected !== "" &&
      ksProjectSelected &&
      ksProjectSelected !== "" &&
      ksModelSelected &&
      ksModelSelected !== ""
    ) {
      const getDatasets = await KSService.getDatasets(
        ksSystemSelected,
        ksProjectSelected,
        ksModelSelected,
      );
      if (getDatasets.code === 1) {
        setKSDatasetOptions((prevOptions: any) =>
          getDatasets.data.map((el: any) => ({
            label: el.name,
            value: el.uuid,
          })),
        );
      }
    }
  };

  useEffect(() => {
    KSgetDataset();
  }, [ksModelSelected]);

  // const KSgetFolders = async () => {
  //   if (
  //     ksSystemSelected &&
  //     ksSystemSelected !== "" &&
  //     ksProjectSelected &&
  //     ksProjectSelected !== "" &&
  //     ksModelSelected &&
  //     ksModelSelected !== ""
  //   ) {
  //     const getFolders = await KSService.getFoldersModel(
  //       ksSystemSelected,
  //       ksProjectSelected,
  //       ksModelSelected
  //     );
  //     if (getFolders.code === 1) {
  //       setKSFolderOptions(
  //         getFolders.data.map((el: any) => ({
  //           label: el.name,
  //           value: el.uuid,
  //         }))
  //       );
  //     }
  //   }
  // };

  // useEffect(() => {
  //   KSgetFolders();
  // }, [ksModelSelected]);

  const handleEdges = () => {
    setNodeName(id);
    const fNode: any = nodes.find((el) => el.id === id)?.data;
    if (!fNode) {
      // message.error('Не обнаружена нода. Возможно, вы ее удалили перед тем как открыть.');
      return;
    }
    setRFields(fNode.formula);
    const findEdges = edges.find((el) => el.target === id);
    if (findEdges) {
      setSourceId(findEdges.source);
      const findSourceNode: any = nodes.find(
        (el) => el.id === findEdges.source,
      );
      if (findSourceNode?.data.formula) {
        setLFields(findSourceNode?.data.formula);
      } else {
        message.warning(
          `Обратите внимание, что в ноде ${findEdges.source} не заданы поля`,
        );
      }
    } else {
      message.warning(
        `Обратите внимание, что в эту ноду (${id}) не входит поток данных`,
      );
    }
  };

  useEffect(() => {
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id !== id
          ? node
          : {
              ...node,
              data: {
                ...node.data,
                formula: fields,
                system: ksSystemSelected,
                project: ksProjectSelected,
                model: ksModelSelected,
                class: ksClassSelected,
                dataset: ksDatasetSelected,
                timeDependent: ksTimeDependentSelected,
                folder: ksFolderSelected,
              },
            },
      ),
    );
  }, [
    setNodes,
    id,
    fields,
    ksSystemSelected,
    ksProjectSelected,
    ksModelSelected,
    ksClassSelected,
    ksDatasetSelected,
    ksTimeDependentSelected,
    ksFolderSelected,
  ]);

  useEffect(() => {
    if (fields) {
      setRFields((prev) => {
        return fields.map((el) => {
          const filter = prev.find((z) => el.id === z.id);
          if (filter) {
            el.formula = filter.formula;
          }
          return el;
        });
      });
    }
  }, [fields]);

  const [textAreaValue, setTextAreaValue] = useState("");

  useEffect(() => {
    if (selectedTransform) {
      setTextAreaValue(rFieldsDict[selectedTransform]?.formula || "");
    } else {
      setTextAreaValue("");
    }
  }, [selectedTransform, rFieldsDict]);

  const handleTextAreaChange = (e: any) => {
    const newValue = e.target.value;
    setTextAreaValue(newValue);
    // Теперь обновляем глобальное состояние
    setRFields((prev) =>
      prev.map((field) => {
        if (field.uuid === selectedTransform) {
          return { ...field, formula: newValue };
        }
        return field;
      }),
    );
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Наименование ноды
          </div>
          <Input
            value={nodeName}
            status={
              nodeName.toString().match(/^[A-Za-z][A-Za-z0-9\-_]*$/)
                ? ""
                : "error"
            }
            disabled={!editNodeNameStatus}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => {
              if (!el.target.value.match(/^[A-Za-z][A-Za-z0-9\-_]*$/)) {
                message.warning(
                  "Вводимое наименование содержит недопустимые символы",
                );
              }
              setNodeName(el.target.value);
            }}
            allowClear
          />
          {!editNodeNameStatus && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditNodeNameStatus(true)}
            >
              ✏️
            </Button>
          )}
          {editNodeNameStatus && (
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <Button style={{ width: "50px" }} onClick={onChangeNodeId}>
                💾
              </Button>
              <Button
                style={{ width: "50px" }}
                onClick={() => {
                  setNodeName(id);
                  setEditNodeNameStatus(false);
                }}
              >
                🔄
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Подключение KS
          </div>
          <Select
            value={ksSystemSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setKSSystemSelected(el)}
            options={ksSystemOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Проект KS</div>
          <Select
            value={ksProjectSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setKSProjectSelected(el)}
            options={ksProjectOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Модель KS</div>
          <Select
            value={ksModelSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setKSModelSelected(el)}
            options={ksModelOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Датасет KS</div>
          <Select
            value={ksDatasetSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setKSDatasetSelected(el)}
            options={ksDatasetOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Класс KS</div>
          <Select
            value={ksClassSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setKSClassSelected(el)}
            options={ksClassOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Путь создания объектов
          </div>
          <Input
            value={ksFolderSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setKSFolderSelected(el.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Времязависимые
          </div>
          <Switch
            checked={ksTimeDependentSelected}
            onChange={(el) => setKSTimeDependentSelected(el)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            height: "500px",
            overflow: "auto",
          }}
        >
          <div>
            <span style={{ fontWeight: "bold" }}>
              ⤵️ Входящий поток: {SourceId}
            </span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lFields?.length > 0 ? (
                    lFields.map((el: MappingField, ind: number) => (
                      <TableRow key={ind}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {IntegrationFieldsManager.getSourceFieldStatus(
                            el.id,
                            rFields,
                          )}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.id}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.desc}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.type}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет входящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>
              ⤴️ Исходящий поток: {nodeName}
            </span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Действие
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rFields?.length > 0 ? (
                    rFields.map((el: MappingField, ind: number) => (
                      <TableRow key={ind}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.formula && el.formula.length > 0 ? "✅" : "⭕"}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Input
                            value={rFieldsDict[el.uuid]?.id || ""}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRFields((prev) =>
                                prev.map((field) => {
                                  if (field.uuid === el.uuid) {
                                    return { ...field, id: newValue };
                                  }
                                  return field;
                                }),
                              );
                            }}
                            disabled={true}
                          />
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Input
                            value={rFieldsDict[el.uuid]?.desc || ""}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRFields((prev) =>
                                prev.map((field) => {
                                  if (field.uuid === el.uuid) {
                                    return { ...field, desc: newValue };
                                  }
                                  return field;
                                }),
                              );
                            }}
                            disabled={true}
                          />
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Select
                            style={{ width: "100%" }}
                            value={rFieldsDict[el.uuid]?.type || ""}
                            onChange={(e) => {
                              const newValue = e;
                              setRFields((prev) =>
                                prev.map((field: any) => {
                                  if (field.uuid === el.uuid) {
                                    return { ...field, type: newValue };
                                  }
                                  return field;
                                }),
                              );
                            }}
                            options={__optionType}
                            disabled={true}
                          />
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Button onClick={() => setSelectedTransform(el.uuid)}>
                            🔎
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={100}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет исходящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span>
            Параметр мэппинга:{" "}
            {rFields && rFields.find((el) => el.uuid === selectedTransform)?.id}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              height: "100px",
            }}
          >
            <TextArea
              size="large"
              style={{ height: "100px", resize: "none" }}
              disabled={!selectedTransform}
              value={textAreaValue}
              onChange={handleTextAreaChange}
            />
            <Button
              style={{ width: "100px", height: "100px", fontSize: "36px" }}
              onClick={handleEdges}
            >
              👁
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KnowledgeSpaceClassOutput;

const __optionType = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];
