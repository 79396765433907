import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Edge, Node } from "@xyflow/react";
import { Button, Input, InputNumber, Select, message } from "antd";
import Empty from "antd/lib/empty";
import { pick } from "lodash";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";

type Props = {
  id: string;
  nodes: Node[];
  edges: Edge[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
};

const ExtrapolationWindow: React.FC<Props> = ({
  id,
  nodes,
  edges,
  setNodes,
  setEdges,
}) => {
  const [editNodeNameStatus, setEditNodeNameStatus] = useState<boolean>(false);
  const [nodeName, setNodeName] = useState<string>(id);
  const [fcstMethod, setFcstMethod] = useState<string>();
  const [fcstTimeLevel, setFcstTimeLevel] = useState<string>();
  const [fcstLength, setFcstLength] = useState<Number>(0);
  const [fcstOffset, setFcstOffset] = useState<Number>(0);

  const [methodParameters, setMethodParameters] = useState<any>([]);

  const [rFields, setRFields] = useState<MappingField[]>([]);
  const [rFieldsDict, setRFieldsDict] = useState<{
    [key: string]: MappingField;
  }>({});

  const onChangeNodeId = () => {
    IntegrationManager.changeNodeId(
      id,
      nodeName,
      nodes,
      () => {},
      setNodes,
      setEdges,
      setEditNodeNameStatus,
    );
  };

  useEffect(() => {
    setNodeName(id);

    const node = nodes.find((node) => node.id === id)!;

    const fields: any[] = [];

    const leftEdge = edges.find((edge) => edge.target === id);
    if (leftEdge) {
      const leftNode = nodes.find((node) => node.id === leftEdge.source)!;

      ((leftNode.data.formula as any[]) ?? []).forEach((field) => {
        const findF = [...((node.data.formula as any[]) ?? [])].find(
          (f) => f.id === field.id,
        );
        const oldF = pick(field, ["uuid", "id", "type", "flow", "formula"]);
        const newF = findF ? { ...oldF, ...findF } : oldF;
        fields.push(newF);
      });
    }

    const forecastMethod: any = node.data.fcstMethod;

    if (forecastMethod) {
      setFcstMethod(forecastMethod);
    }

    const fcstTimeLevel: any = node.data.fcstTimeLevel;

    if (fcstTimeLevel) {
      setFcstTimeLevel(fcstTimeLevel);
    }

    const fcstLength: any = node.data.fcstLength;

    if (fcstLength) {
      setFcstLength(fcstLength);
    }

    const fcstOffset: any = node.data.fcstOffset;

    if (fcstOffset) {
      setFcstOffset(fcstOffset);
    }

    const methodParameters: any = node.data.methodParameters;

    if (methodParameters && methodParameters.length > 0) {
      console.log("setting", methodParameters);
      setMethodParameters(methodParameters);
    }

    setRFields(fields);
  }, [id]);

  useEffect(() => {
    setNodes((prev) => {
      return prev.map((rec) => {
        if (rec.id === id) {
          rec.data.fcstMethod = fcstMethod;
        }
        return rec;
      });
    });
  }, [fcstMethod]);

  useEffect(() => {
    setNodes((prev) => {
      return prev.map((rec) => {
        if (rec.id === id) {
          rec.data.fcstTimeLevel = fcstTimeLevel;
        }
        return rec;
      });
    });
  }, [fcstTimeLevel]);

  useEffect(() => {
    setNodes((prev) => {
      return prev.map((rec) => {
        if (rec.id === id) {
          rec.data.fcstLength = fcstLength;
        }
        return rec;
      });
    });
  }, [fcstLength]);

  useEffect(() => {
    setNodes((prev) => {
      return prev.map((rec) => {
        if (rec.id === id) {
          rec.data.fcstOffset = fcstOffset;
        }
        return rec;
      });
    });
  }, [fcstOffset]);

  useEffect(() => {
    if (rFields && rFields.length > 0) {
      setNodes((prev) => {
        return prev.map((rec) => {
          if (rec.id === id) {
            rec.data.formula = rFields.filter((e) => e.forecastFieldType);
          }
          return rec;
        });
      });
      let res: { [key: string]: MappingField } = {};
      for (const field of rFields) {
        res[field.uuid] = field;
      }
      setRFieldsDict(res);
    }
  }, [rFields]);

  useEffect(() => {
    setNodes((prev) => {
      return prev.map((rec) => {
        if (rec.id === id) {
          console.log("meth", methodParameters);
          rec.data.methodParameters = methodParameters;
        }
        return rec;
      });
    });
  }, [methodParameters]);

  const generateMethodParametersElements = useMemo(() => {
    const fields = [];

    const getValue = (fieldId: string) => {
      if (!methodParameters) {
        return undefined;
      }
      console.log(methodParameters);
      const parameter = methodParameters.find((el: any) => el.id === fieldId);
      return parameter ? parameter.value : undefined;
    };

    switch (fcstMethod) {
      case "MOVAVG":
        fields.push({
          id: "HISTORY",
          label: "Глубина истории",
          type: "NUMBER",
          value: getValue("HISTORY"),
        });
        break;
      case "QUANTILE":
        fields.push({
          id: "QUANTILE",
          label: "Квантиль",
          type: "NUMBER",
          value: getValue("QUANTILE"),
        });
        break;
      case "EXPSMOOTH":
        fields.push({
          id: "ALPHA",
          label: "Альфа",
          type: "NUMBER",
          value: getValue("ALPHA"),
        });
        break;
      default:
        break;
    }

    return fields.map((parameter: any) => {
      const setValue = (value: any) => {
        setMethodParameters((prev: any) => {
          if (prev && prev.find((e: any) => e.id === parameter.id)) {
            return prev.map((pr: any) => {
              if (pr.id === parameter.id) {
                return { ...pr, value };
              }
              return pr;
            });
          } else {
            prev.push({ ...parameter, value });
            return prev;
          }
        });
      };

      return (
        <div
          key={parameter.id}
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            {parameter.label}
          </div>
          {parameter.type === "NUMBER" && (
            <InputNumber
              style={{ width: "300px", color: "black" }}
              value={Number(parameter.value)}
              onChange={(e) => setValue(Number(e))}
            />
          )}
          {parameter.type === "STRING" && (
            <Input
              style={{ width: "300px", color: "black" }}
              value={parameter.value}
              onChange={(e) => setValue(e.target.value)}
            />
          )}
        </div>
      );
    });
  }, [fcstMethod, methodParameters]);

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Наименование ноды
          </div>
          <Input
            value={nodeName}
            status={
              nodeName.toString().match(/^[A-Za-z][A-Za-z0-9\-_]*$/)
                ? ""
                : "error"
            }
            disabled={!editNodeNameStatus}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => {
              if (!el.target.value.match(/^[A-Za-z][A-Za-z0-9\-_]*$/)) {
                message.warning(
                  "Вводимое наименование содержит недопустимые символы",
                );
              }
              setNodeName(el.target.value);
            }}
            allowClear
          />
          {!editNodeNameStatus && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditNodeNameStatus(true)}
            >
              ✏️
            </Button>
          )}
          {editNodeNameStatus && (
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <Button style={{ width: "50px" }} onClick={onChangeNodeId}>
                💾
              </Button>
              <Button
                style={{ width: "50px" }}
                onClick={() => {
                  setNodeName(id);
                  setEditNodeNameStatus(false);
                }}
              >
                🔄
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Метод прогнозирования
          </div>
          <Select
            style={{ width: "300px", color: "black" }}
            options={__forecastOptions}
            value={fcstMethod}
            onChange={setFcstMethod}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Временная гранулярность
          </div>
          <Select
            style={{ width: "300px", color: "black" }}
            options={__forecastPeriods}
            value={fcstTimeLevel}
            onChange={setFcstTimeLevel}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Кол-во периодов
          </div>
          <InputNumber
            style={{ width: "300px", color: "black" }}
            value={Number(fcstLength)}
            onChange={(e) => setFcstLength(Number(e))}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Смещение по времени
          </div>
          <InputNumber
            style={{ width: "300px", color: "black" }}
            value={Number(fcstOffset)}
            onChange={(e) => setFcstOffset(Number(e))}
          />
        </div>
        {generateMethodParametersElements}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            height: "500px",
            overflow: "auto",
          }}
        >
          <div>
            <span style={{ fontWeight: "bold" }}>
              ⤴️ Входящий/Исходящий поток: {nodeName}
            </span>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "20px",
                      padding: "10px",
                      height: "32px",
                    }}
                  ></TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Идентификатор
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "250px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Описание
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Тип
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Тип поля
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rFields?.length > 0 ? (
                  rFields.map((el: MappingField, ind: number) => (
                    <TableRow key={ind}>
                      <TableCell style={{ padding: "10px", height: "32px" }}>
                        {el.forecastFieldType ? "✅" : "⭕"}
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Input
                          value={rFieldsDict[el.uuid]?.id || ""}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setRFields((prev) =>
                              prev.map((field) => {
                                if (field.uuid === el.uuid) {
                                  return { ...field, id: newValue };
                                }
                                return field;
                              }),
                            );
                          }}
                          disabled={true}
                        />
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Input
                          value={rFieldsDict[el.uuid]?.desc || ""}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setRFields((prev) =>
                              prev.map((field) => {
                                if (field.uuid === el.uuid) {
                                  return { ...field, desc: newValue };
                                }
                                return field;
                              }),
                            );
                          }}
                          disabled={true}
                        />
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Select
                          style={{ width: "100%" }}
                          value={rFieldsDict[el.uuid]?.type || ""}
                          onChange={(e) => {
                            const newValue = e;
                            setRFields((prev) =>
                              prev.map((field: any) => {
                                if (field.uuid === el.uuid) {
                                  return { ...field, type: newValue };
                                }
                                return field;
                              }),
                            );
                          }}
                          options={__optionType}
                          disabled={true}
                        />
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Select
                          style={{ width: "100%" }}
                          options={__fieldType}
                          onChange={(e) => {
                            setRFields((prev) =>
                              prev.map((field) => {
                                if (field.uuid === el.uuid) {
                                  return { ...field, forecastFieldType: e };
                                }
                                return field;
                              }),
                            );
                          }}
                          value={rFieldsDict[el.uuid]?.forecastFieldType}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={100}>
                      <Empty
                        imageStyle={{ height: "50px" }}
                        description="Нет входящих полей"
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtrapolationWindow;

const __optionType = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];

const __fieldType = [
  {
    value: "ATTRIBUTE",
    label: "Атрибут",
  },
  {
    value: "FIGURE",
    label: "Показатель",
  },
  {
    value: "DATE",
    label: "Дата",
  },
];

const __forecastOptions = [
  {
    value: "AVG",
    label: "Арифметическое среднее",
  },
  {
    value: "MOVAVG",
    label: "Скользящее среднее",
  },
  {
    value: "MEDIAN",
    label: "Медиана",
  },
  {
    value: "STDEV",
    label: "Стандартное отклонение",
  },
  {
    value: "VARIANCE",
    label: "Дисперсия",
  },
  {
    value: "QUANTILE",
    label: "Квантиль",
  },
  {
    value: "SIMLINREG",
    label: "Простая линейная регрессия",
  },
];

const __forecastPeriods = [
  {
    value: "DAY",
    label: "День",
  },
  {
    value: "WEEK",
    label: "Неделя",
  },
  {
    value: "MONTH",
    label: "Месяц",
  },
  {
    value: "QUARTER",
    label: "Квартал",
  },
  {
    value: "YEAR",
    label: "Год",
  },
];
