import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, MenuItem } from "@mui/material";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Modal, notification, Popconfirm, Tag, Tooltip } from "antd";
import { format } from "date-fns";
import type {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import React, { useState } from "react";
import AttributeService from "../../../entities/model/AttributeService";
import { IAttribute } from "../../../entities/types/IAttribute";
import CreateAttribute from "../../../features/componets/CreateAttribute";
import ImportAttribute from "../../../features/componets/ImportAttribute";
import Button from "../../../shared/components/button";
import UserPopover from "../../../shared/components/popovers/user";
import { exportToExcel } from "../../../shared/helper/excelExport";

const COLUMNS: MRT_ColumnDef<any>[] = [
  {
    header: "ID",
    accessorKey: "id",
  },
  {
    header: "Наименование",
    accessorKey: "name",
  },
  {
    header: "Тип",
    accessorKey: "type",
    Cell: (e) => <Tag>{e.renderedCellValue}</Tag>,
  },
  {
    header: "Создан",
    accessorKey: "createdBy",
    enableColumnFilter: false,
  },
  {
    header: "Дата создания",
    accessorKey: "createdAt",
    filterVariant: "date",
    filterFn: "betweenInclusive",
    muiFilterDatePickerProps: { format: "DD.MM.YYYY" },
    Cell: ({ cell }: any) =>
      format(new Date(cell.getValue()), "dd.MM.yyyy HH:mm:ss"),
  },
  {
    header: "Изменен",
    accessorKey: "updatedBy",
    enableColumnFilter: false,
  },
  {
    header: "Дата изменения",
    accessorKey: "updatedAt",
    filterVariant: "date",
    filterFn: "betweenInclusive",
    muiFilterDatePickerProps: { format: "DD.MM.YYYY" },
    Cell: ({ cell }: any) =>
      format(new Date(cell.getValue()), "dd.MM.yyyy HH:mm:ss"),
  },
];

const AttributePage: React.FC = () => {
  const [createState, setCreateState] = useState<boolean>(false);
  const [importState, setImportState] = useState<boolean>(false);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState<MRT_ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "createdAt", desc: true },
  ]);

  const {
    data: { rows, count } = { rows: [], count: 0 },
    refetch,
    isLoading,
    isError,
    isRefetching,
  } = useQuery<{ count: number; rows: IAttribute[] }>({
    refetchOnWindowFocus: false,
    retry: false,
    placeholderData: keepPreviousData,
    queryKey: [
      "attributes",
      filters,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      const response = await AttributeService.getAll({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        filters,
        sorting,
      });

      if (response.code !== 1) {
        throw new Error(
          `Возникла ошибка при загрузке атрибутов: "${response.text}"`,
        );
      }

      const rows = response.data.rows.map((item: Record<string, any>) => {
        item.createdBy = <UserPopover userId={item.createdBy} />;
        item.updatedBy = <UserPopover userId={item.updatedBy} />;
        return item;
      });

      return { count: response.data.count, rows };
    },
  });

  const table = useMaterialReactTable({
    columns: COLUMNS,
    data: rows,

    localization: MRT_Localization_RU,

    rowCount: count,

    initialState: { density: "compact" },

    state: {
      columnFilters: filters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },

    enableGrouping: false,
    enableStickyHeader: true,
    enableGlobalFilter: false,

    groupedColumnMode: "remove",

    manualFiltering: true,
    onColumnFiltersChange: setFilters,

    manualPagination: true,
    onPaginationChange: setPagination,

    manualSorting: true,
    onSortingChange: setSorting,

    muiToolbarAlertBannerProps: isError
      ? { color: "error", children: "Возникла ошибка при загрузке данных" }
      : undefined,

    muiTableBodyRowProps: () => ({
      sx: {
        height: "50px",
      },
    }),

    renderTopToolbarCustomActions: () => (
      <Tooltip arrow title="Обновить">
        <IconButton onClick={() => refetch()}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    ),

    enableRowActions: true,
    renderRowActionMenuItems: ({ row }) => [
      <MenuItem key="edit" onClick={() => setCreateState(true)}>
        📋 Изменить
      </MenuItem>,
      <MenuItem key="delete">
        <Popconfirm
          title="Удаление атрибута"
          description="Вы уверены что хотите безвозвратно удалить атрибут?"
          okText="Да"
          cancelText="Нет"
          onConfirm={async () => {
            const attribute: IAttribute = row.original;
            const response = await AttributeService.delete(attribute);

            if (response.code !== 1012) {
              return notification.error({
                message: "Ошибка при удалении атрибута",
                description: response?.text,
              });
            }

            notification.warning({
              message: "Атрибут удален",
              description: response?.text,
            });

            refetch();
          }}
        >
          🚫 Удалить
        </Popconfirm>
      </MenuItem>,
    ],
  });

  return (
    <div className="pageAttribute">
      <div
        className="ManageTable"
        style={{ display: "flex", flexDirection: "row", gap: "15px" }}
      >
        <Button
          type="primary"
          size="large"
          label="📁 Создать"
          onClick={() => setCreateState(true)}
        />
        <Button
          label="🔄 Обновить"
          size="large"
          type="default"
          onClick={refetch}
        />
        <Button
          label="📤 Экспорт"
          size="large"
          type="default"
          onClick={() => exportToExcel("Атрибуты", rows)}
        />
        <Button
          label="📥 Импорт"
          size="large"
          type="default"
          onClick={() => setImportState(true)}
        />
      </div>

      <MaterialReactTable table={table} />

      <Modal
        open={createState}
        footer={false}
        onCancel={() => setCreateState(false)}
        destroyOnClose
      >
        <CreateAttribute
          onCancel={() => setCreateState(false)}
          onSuccess={() => {
            setCreateState(false);
            refetch();
          }}
        />
      </Modal>

      <Modal
        open={importState}
        footer={false}
        onCancel={() => setImportState(false)}
        destroyOnClose
      >
        <ImportAttribute
          onCancel={() => setImportState(false)}
          onSuccess={() => {
            setImportState(false);
            refetch();
          }}
        />
      </Modal>
    </div>
  );
};

export default AttributePage;
