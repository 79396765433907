import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Edge, Node } from "@xyflow/react";
import { Button, Input, Select, message, notification } from "antd";
import Empty from "antd/lib/empty";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import LevelService from "../../../../../entities/model/LevelService";
import ModelService from "../../../../../entities/model/ModelService";
import VersionService from "../../../../../entities/model/VersionService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";

const DEFAULT_VERSION_ID = "__BASE__";
const DEFAULT_VERSION_NAME = "🧱 Базовая";

enum VersionType {
  BASE = "base",
  VARIABLE = "variable",
  CONSTANT = "constant",
}

type Props = {
  id: string;
  nodes: Node[];
  edges: Edge[];
  variables: any[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
  setVariables: Dispatch<SetStateAction<any[]>>;
};

const PlanXFigureInputWindow: React.FC<Props> = ({
  id,
  nodes,
  edges,
  variables,
  setNodes,
  setEdges,
}) => {
  const [nodeName, setNodeName] = useState<string>(id);
  const [editNodeNameStatus, setEditNodeNameStatus] = useState<boolean>(false);

  const [rFields, setRFields] = useState<MappingField[]>([]);
  const [rFieldsDict, setRFieldsDict] = useState<{
    [key: string]: MappingField;
  }>({});
  const [selectedTransform, setSelectedTransform] = useState<string | null>(
    null,
  );

  const [fields, setFields] = useState<MappingField[]>([]);

  const onChangeNodeId = () => {
    IntegrationManager.changeNodeId(
      id,
      nodeName,
      nodes,
      () => {},
      setNodes,
      setEdges,
      setEditNodeNameStatus,
    );
  };

  useEffect(() => {
    setSelectedTransform(null);
    handleEdges();
    setNodeName(id);
    const node: any = nodes.find((el) => el.id === id)?.data;
    if (node) {
      setFields(node.formula);
      setModelSelected(node.model);
      setLevelSelected(node.level);
      const version =
        !node.version || node.version === ""
          ? DEFAULT_VERSION_ID
          : node.version;
      setVersionSelected(
        version.type === VersionType.VARIABLE
          ? `VARIABLE_${version.value}`
          : version.value,
      );
    }
  }, [id]);

  const [modelOptions, setModelOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [modelSelected, setModelSelected] = useState<string>("");

  const [levelOptions, setLevelOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [levelSelected, setLevelSelected] = useState<string>();

  const [versions, setVersions] = useState<{ label: string; value: string }[]>(
    [],
  );
  const [versionSelected, setVersionSelected] =
    useState<string>(DEFAULT_VERSION_ID);

  const versionOptions = useMemo(
    () => [
      {
        label: DEFAULT_VERSION_NAME,
        value: DEFAULT_VERSION_ID,
        data: {
          value: DEFAULT_VERSION_ID,
          type: VersionType.BASE,
        },
      },
      ...variables.map((variable) => ({
        label: `🧮 ${variable.desc}`,
        value: `VARIABLE_${variable.id}`,
        data: {
          type: VersionType.VARIABLE,
          value: variable.id,
        },
      })),
      ...versions.map((version) => ({
        ...version,
        data: {
          type: VersionType.CONSTANT,
          value: version.value,
        },
      })),
    ],
    [versions, variables],
  );

  const getModelList = async () => {
    const getModels = await ModelService.getAll();
    if (getModels.code === 1) {
      setModelOptions((prevOptions: any) =>
        getModels.data.map((el: any) => ({
          label: el.name,
          value: el.id,
        })),
      );
    } else {
      notification.error({
        message: getModels.text,
        description: `Код ответа: ${getModels.code}`,
      });
    }
  };

  useEffect(() => {
    getModelList();
  }, []);

  const getLevelList = async () => {
    if (!modelSelected || modelSelected === "") {
      return;
    }
    const getLevels = await LevelService.getAll(modelSelected);
    if (getLevels.code === 1) {
      setLevelOptions((prevOptions: any) =>
        getLevels.data.map((el: any) => ({
          label: el.name,
          value: el.id,
        })),
      );
    }
  };

  useEffect(() => {
    getLevelList();
  }, [modelSelected]);

  const getVersionList = async () => {
    if (
      !modelSelected ||
      modelSelected === "" ||
      !levelSelected ||
      levelSelected === ""
    ) {
      return;
    }

    const getVersions = await VersionService.getAll({
      modelId: modelSelected,
      levelId: levelSelected,
    });

    if (getVersions.code === 1) {
      setVersions(
        getVersions.data.map((version: any) => ({
          label: version.name,
          value: version.id,
        })),
      );
    }
  };

  useEffect(() => {
    getVersionList();
  }, [modelSelected, levelSelected]);

  const getFields = async () => {
    if (!levelSelected || levelSelected === "") {
      return;
    }

    const getAttributes = await LevelService.getAttributes(
      modelSelected,
      levelSelected,
    );
    if (getAttributes.code !== 1) {
      return;
    }
    const keyAttributes = getAttributes.data.filter((el: any) => el.key);

    const getFigures = await LevelService.getFigures(
      modelSelected,
      levelSelected,
    );
    if (getFigures.code !== 1) {
      return;
    }

    const getPeriod = await LevelService.getPeriod(
      modelSelected,
      levelSelected,
    );
    if (getPeriod.code !== 1) {
      return;
    }

    const fields: MappingField[] = [];
    for (const key of keyAttributes) {
      fields.push({
        uuid: uuidv4(),
        id: key.attributeId,
        desc: key.attributeName,
        type: "STRING",
      });
    }

    if (getPeriod.data > 0) {
      fields.push({
        uuid: uuidv4(),
        id: "DATE",
        desc: "Дата",
        type: "DATE",
      });
    }

    for (const figure of getFigures.data) {
      fields.push({
        uuid: figure.uuid,
        id: "F_" + figure.id,
        desc: figure.name,
        type: "DECIMAL",
      });
    }

    setFields(fields);
  };

  useEffect(() => {
    getFields();
  }, [levelSelected]);

  useEffect(() => {
    if (rFields) {
      setNodes((prev) => {
        const res = prev.map((rec) => {
          if (rec.id === id) {
            rec.data.formula = rFields;
          }
          return rec;
        });
        return res;
      });
      let res: { [key: string]: MappingField } = {};
      for (const field of rFields) {
        res[field.uuid] = field;
      }
      setRFieldsDict(res);
    }
  }, [rFields]);

  const handleEdges = () => {
    setNodeName(id);
    const fNode: any = nodes.find((el) => el.id === id)?.data!;
    setRFields(fNode.formula);
  };

  useEffect(() => {
    setNodes((prev) => {
      const res = prev.map((rec) => {
        if (rec.id === id) {
          rec.data.formula = fields;
          rec.data.model = modelSelected;
          rec.data.level = levelSelected;
          if (versionSelected && versionSelected !== "") {
            rec.data.version = versionOptions.find(
              (option) => option.value === versionSelected,
            )?.data!;
          }
        }
        return rec;
      });
      return res;
    });

    if (fields) {
      setRFields((prev) => {
        return fields.map((el) => {
          const filter = prev.find((z) => el.id === z.id);
          if (filter) {
            el.formula = filter.formula;
          }
          return el;
        });
      });
    }
  }, [fields, modelSelected, levelSelected, versionSelected, versionOptions]);

  const [textAreaValue, setTextAreaValue] = useState("");

  useEffect(() => {
    if (selectedTransform) {
      setTextAreaValue(rFieldsDict[selectedTransform]?.formula || "");
    } else {
      setTextAreaValue("");
    }
  }, [selectedTransform, rFieldsDict]);

  const handleTextAreaChange = (e: any) => {
    const newValue = e.target.value;
    setTextAreaValue(newValue);
    // Теперь обновляем глобальное состояние
    setRFields((prev) =>
      prev.map((field) => {
        if (field.uuid === selectedTransform) {
          return { ...field, formula: newValue };
        }
        return field;
      }),
    );
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Наименование ноды
          </div>
          <Input
            value={nodeName}
            status={
              nodeName.toString().match(/^[A-Za-z][A-Za-z0-9\-_]*$/)
                ? ""
                : "error"
            }
            disabled={!editNodeNameStatus}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => {
              if (!el.target.value.match(/^[A-Za-z][A-Za-z0-9\-_]*$/)) {
                message.warning(
                  "Вводимое наименование содержит недопустимые символы",
                );
              }
              setNodeName(el.target.value);
            }}
            allowClear
          />
          {!editNodeNameStatus && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditNodeNameStatus(true)}
            >
              ✏️
            </Button>
          )}
          {editNodeNameStatus && (
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <Button style={{ width: "50px" }} onClick={onChangeNodeId}>
                💾
              </Button>
              <Button
                style={{ width: "50px" }}
                onClick={() => {
                  setNodeName(id);
                  setEditNodeNameStatus(false);
                }}
              >
                🔄
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Модель</div>
          <Select
            value={modelSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setModelSelected(el)}
            options={modelOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Уровень</div>
          <Select
            value={levelSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setLevelSelected(el)}
            options={levelOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Версия</div>
          <Select
            value={versionSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(version) => setVersionSelected(version)}
            options={versionOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Идентификатор
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Описание</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Тип</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields?.length > 0 ? (
              fields.map((el: MappingField, ind: number) => (
                <TableRow key={ind}>
                  <TableCell>{el.id}</TableCell>
                  <TableCell>{el.desc}</TableCell>
                  <TableCell>{el.type}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={100}>
                  <Empty
                    imageStyle={{ height: "50px" }}
                    description="Нет исходящих полей"
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default PlanXFigureInputWindow;

const __optionType = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];
