import "./wdyr";

import { createContext, StrictMode, useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import "./app/global/color.css";
import "./app/global/fonts.css";
import "./app/global/index.css";
import Store from "./entities/model/store";

const store = new Store();

interface State {
  store: Store;
  header: {
    extension?: React.ReactNode;
    setExtension: (value: React.ReactNode | undefined | null) => void;
  };
  content: {
    type: "default" | "custom";
    setType: (value: "default" | "custom") => void;
  };
}

export const Context = createContext<State>({
  store,
  header: { extension: undefined, setExtension: () => {} },
  content: { type: "default", setType: () => {} },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const Root = () => {
  const [extension, setExtension] = useState<React.ReactNode>();
  const [type, setType] = useState<"default" | "custom">("default");

  const value = {
    store,
    header: { extension, setExtension },
    content: { type, setType },
  };

  return (
    <StrictMode>
      <Context.Provider value={value}>
        <App />
      </Context.Provider>
    </StrictMode>
  );
};

root.render(<Root />);
