import {
  Background,
  BackgroundVariant,
  Controls,
  Edge,
  MiniMap,
  Node,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  ReactFlow,
  ReactFlowProvider,
  SelectionMode,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { Modal, message } from "antd";
import { format } from "date-fns";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Context } from "../../..";
import FlowService from "../../../entities/model/FlowService";
import {
  ModalType,
  createModalWindow,
  nodeTypes,
} from "./blockSettings/blockTypes";
import colors, { nodeColor } from "./colors";
import { ContextMenu } from "./contextMenu";
import { edgeTypes } from "./edgeSettings";
import IntegrationConstructorHeader from "./header";
import "./index.css";
import { IFlowVariableField } from "./modalVariables";
import Sidebar from "./sidebar";
import { historyStore } from "./undoRedo";

const HistoryContext = React.createContext(historyStore());

export const useHistory = () => {
  return useContext(HistoryContext);
};

const IntegrationConstructorPage: React.FC = () => {
  const params = useParams();
  const startFlowId: string = params.flowId || "";

  const [flowId, setFlowId] = useState<string | null>(
    startFlowId === "new" ? null : startFlowId,
  );
  const [project, setProject] = useState<string | null>(null);
  const [variables, setVariables] = useState<IFlowVariableField[]>([]);
  const [sidebarState, SetSidebarState] = useState<boolean>(true);
  const { store } = useContext(Context);

  useEffect(() => {
    store.setPageName(
      `Конструктор интеграций: ${flowId ? flowId : "Новая интеграция"}`,
    );
  }, [store, flowId]);

  const reactFlowWrapper = useRef<any>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [menu, setMenu] = useState<any>(null);

  //UndoRedo functions
  const [lastState, setLastState] = useState<any>({});
  const [undoCount, setUndoCount] = useState<number>(0);

  const [title, setTitle] = useState<string>("");
  const [nodeId, setNodeId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType | null>(null);

  const [flowChangedAt, setFlowChangedAt] = useState<string>();
  const [flowChangedBy, setFlowChangedBy] = useState<string>();

  const ref = useRef<any>(null);

  const { history, historyRecord } = useHistory();

  const onNodesChange: OnNodesChange = useCallback((changes) => {
    setNodes((nodes) => {
      const newNodes = applyNodeChanges(changes, nodes);

      // put notes on the top to make them appear under other nodes
      newNodes.sort((x, y) => (x.type === "note" ? -1 : 0));

      return newNodes;
    });
  }, []);

  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => {
      setEdges((eds) => {
        return applyEdgeChanges(changes, eds);
      });
    },
    [setEdges],
  );

  const handleFetchData = async () => {
    if (startFlowId !== "new" && startFlowId && startFlowId !== "") {
      const response = await FlowService.getOne(startFlowId);
      if (response.code === 1) {
        setVariables(response.data.variables);
        setProject(response.data.project);
        setNodes(response.data.nodes);
        setEdges(response.data.edges);
        setFlowChangedAt(
          format(new Date(response.data.updatedAt), "dd.MM.yyyy HH:mm:ss"),
        );
        setFlowChangedBy(response.data.updatedBy);
        historyRecord({
          nodes: response.data.nodes,
          edges: response.data.edges,
          comment: "initial",
        });
      } else {
        message.error("Поток не найден");
      }
    }
  };

  useEffect(() => {
    handleFetchData();
  }, [startFlowId]);

  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onNodeContextMenu = useCallback((event: any, node: Node) => {
    // Prevent native context menu from showing
    event.preventDefault();

    // Calculate position of the context menu. We want to make sure it
    // doesn't get positioned off-screen.
    const pane = ref.current.getBoundingClientRect();
    setMenu({
      id: node.id,
      top: event.clientY < pane.height - 200 && event.clientY,
      left: event.clientX < pane.width - 200 && event.clientX,
      right: event.clientX >= pane.width - 200 && pane.width - event.clientX,
      bottom: event.clientY >= pane.height - 200 && pane.height - event.clientY,
    });
  }, []);

  const onPaneClick = useCallback(() => setMenu(null), []);

  const openModal = (
    id: string | undefined | null,
    type: string | undefined | null,
  ) => {
    setOpen(true);
    setModalType(getModalType(type));
    setNodeId(id ? id : "");
    setTitle(`Просмотр ноды ${type}: ${id}`);
  };

  const onConnect: OnConnect = (connection) => {
    setEdges((edges) => {
      const ret = addEdge({ ...connection, type: "buttonedge" }, edges);
      historyRecord({
        nodes,
        edges: ret,
        comment: "OnEdgesChange",
      });
      return ret;
    });

    const node = nodes.find((node) => node.id === connection.target);

    openModal(connection.target, node?.type);
  };

  const onDrop = (event: any) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("application/reactflow");

    // check if the dropped element is valid
    if (typeof type === "undefined" || !type) {
      return;
    }

    const position = reactFlowInstance.screenToFlowPosition({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    const genId = uuidv4();

    if (type === "note") {
      setNodes((nodes) => {
        const res = [
          ...nodes,
          {
            id: `${type}_${genId}`,
            type,
            position,
            dragHandle: ".custom-drag-handle",
            data: {
              id: `${type}_${genId}`,
              label: `${type}_${genId}`,
              color: colors.note,
            },
          },
        ];

        historyRecord({
          nodes: res,
          edges: edges,
          comment: "onDrop",
        });

        return res;
      });
    } else {
      setNodes((nodes) => {
        const res = [
          ...nodes,
          {
            id: `${type}_${genId}`,
            type,
            position,
            data: {
              label: `${type}_${genId}`,
            },
          },
        ];

        historyRecord({
          nodes: res,
          edges: edges,
          comment: "onDrop",
        });

        return res;
      });
    }
  };

  return (
    <div style={{ height: "100%", margin: "-20px" }}>
      <div
        className="Flow"
        style={{ width: "100%", height: "100%" }}
        onKeyDown={(e: any) =>
          open && e.stopPropagation() && e.preventDefault()
        }
      >
        <ReactFlowProvider>
          <IntegrationConstructorHeader
            project={project}
            flowChangedAt={flowChangedAt}
            flowChangedBy={flowChangedBy}
            setEdges={setEdges}
            setNodes={setNodes}
            setFlowId={setFlowId}
            setProject={setProject}
            variables={variables}
            setVariables={setVariables}
            flowId={flowId}
            nodes={nodes}
            edges={edges}
            startFlowId={startFlowId}
            fetchData={handleFetchData}
            setSidebarState={SetSidebarState}
            sidebarState={sidebarState}
            historical={history}
            lastState={lastState}
            undoCount={undoCount}
            setUndoCount={setUndoCount}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "100%",
            }}
          >
            {sidebarState && (
              <Sidebar
                setEdges={setEdges}
                setNodes={setNodes}
                setFlowId={setFlowId}
                setProject={setProject}
                variables={variables}
                setVariables={setVariables}
                flowId={flowId}
                project={project}
                nodes={nodes}
                edges={edges}
                startFlowId={startFlowId}
              />
            )}
            <div
              className="reactflow-wrapper"
              style={{ width: "100%", height: "100%" }}
              ref={reactFlowWrapper}
            >
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                edgeTypes={edgeTypes}
                nodeTypes={nodeTypes}
                onConnect={onConnect}
                fitView
                proOptions={{ hideAttribution: true }}
                selectionOnDrag
                panOnDrag={[1, 2]}
                selectionMode={SelectionMode.Partial}
                onInit={setReactFlowInstance}
                onDrop={onDrop}
                onDragOver={onDragOver}
                ref={ref}
                onPaneClick={onPaneClick}
                onNodeContextMenu={onNodeContextMenu}
              >
                <Controls />
                <MiniMap
                  style={{ paddingLeft: 0, margin: 0 }}
                  nodeColor={nodeColor}
                  pannable={true}
                />
                <Background
                  variant={BackgroundVariant.Dots}
                  gap={12}
                  size={1}
                />
                {menu && (
                  <ContextMenu
                    onClick={onPaneClick}
                    onView={(node?: Node<any>) =>
                      openModal(node?.id, node?.type)
                    }
                    historyRecord={historyRecord}
                    {...menu}
                  />
                )}
              </ReactFlow>
            </div>
          </div>
        </ReactFlowProvider>
      </div>
      <Modal
        title={title}
        centered
        open={open}
        width="1400px"
        onCancel={() => setOpen(false)}
        footer={false}
        destroyOnClose
      >
        {createModalWindow(
          modalType,
          nodeId,
          nodes,
          edges,
          setNodes,
          setEdges,
          variables,
          setVariables,
        )}
      </Modal>
    </div>
  );
};

const getModalType = (type: string | undefined | null) => {
  switch (type) {
    case "flatFile":
      return ModalType.flatfile;
    case "mapping":
      return ModalType.mapping;
    case "join":
      return ModalType.join;
    case "filter":
      return ModalType.filter;
    case "groupBy":
      return ModalType.group;
    case "rowGen":
      return ModalType.rowGen;
    case "targetFlatFile":
      return ModalType.target__flatFile;
    case "knowledgeSpaceDictionaryInput":
      return ModalType.knowledgeSpaceDictionaryInput;
    case "knowledgeSpaceDictionaryOutput":
      return ModalType.knowledgeSpaceDictionaryOutput;
    case "knowledgeSpaceClassInput":
      return ModalType.knowledgeSpaceClassInput;
    case "knowledgeSpaceClassOutput":
      return ModalType.knowledgeSpaceClassOutput;
    case "planxFigureInput":
      return ModalType.planxFigureInput;
    case "planxFigureOutput":
      return ModalType.planxFigureOutput;
    case "postgresInput":
      return ModalType.postgresInput;
    case "postgresOutput":
      return ModalType.postgresOutput;
    case "clickhouseInput":
      return ModalType.clickhouseInput;
    case "rabbitmqOutput":
      return ModalType.rabbitmqOutput;
    case "launchFlow":
      return ModalType.launchFlow;
    case "note":
      return ModalType.note;
    case "extrapolation":
      return ModalType.extrapolation;
    default:
      return null;
  }
};

export default IntegrationConstructorPage;
