import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Edge, Node } from "@xyflow/react";
import { Button, Input, Popconfirm, Select, message } from "antd";
import Empty from "antd/lib/empty";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";
import IntegrationFieldsManager from "../utils/fields";

type Props = {
  id: string;
  nodes: Node[];
  edges: Edge[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
};

const { TextArea } = Input;

const JoinWindow: React.FC<Props> = ({
  id,
  nodes,
  edges,
  setNodes,
  setEdges,
}) => {
  const [editNodeNameStatus, setEditNodeNameStatus] = useState<boolean>(false);
  const [nodeName, setNodeName] = useState<string>(id);

  const [editJoinSetting, setEditJoinSetting] = useState<boolean>(false);
  const [editMainTableSetting, setEditMainTableSetting] =
    useState<boolean>(false);
  const [joinType, setJoinType] = useState<string>("");
  const [mainTable, setMainTable] = useState<string>("");
  const [mainTableOptions, setMainTableOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const [SourceId, setSourceId] = useState<string | null>(null);

  const [lFields, setLFields] = useState<MappingField[]>([]);
  const [rFields, setRFields] = useState<MappingField[]>([]);
  const [rFieldsDict, setRFieldsDict] = useState<{
    [key: string]: MappingField;
  }>({});
  const [selectedTransform, setSelectedTransform] = useState<string | null>(
    null,
  );

  const onChangeNodeId = () => {
    IntegrationManager.changeNodeId(
      id,
      nodeName,
      nodes,
      () => {},
      setNodes,
      setEdges,
      setEditNodeNameStatus,
    );
  };

  useEffect(() => {
    setSelectedTransform(null);
    handleEdges();
  }, [id, edges]);

  useEffect(() => {
    if (rFields) {
      setNodes((prev) => {
        const res = prev.map((rec) => {
          if (rec.id === id) {
            rec.data.formula = rFields;
          }
          return rec;
        });
        return res;
      });
      let res: { [key: string]: MappingField } = {};
      for (const field of rFields) {
        res[field.uuid] = field;
      }
      setRFieldsDict(res);
    }
  }, [rFields]);

  const [fieldsEditList, setFieldsEditList] = useState<string[]>([]);

  const handleEditFieldOn = (uuid: string) => {
    setFieldsEditList((prev) => Array.from(new Set([...prev, uuid])));
  };

  const handleDelete = (uuid: string) => {
    setRFields((prev) => prev.filter((el) => el.uuid !== uuid));
  };

  const handleEditFieldOff = (uuid: string) => {
    const el = rFields.find((el) => el.uuid === uuid);

    if (el?.id === "") {
      message.error("Заполните идентификаторы для всех полей");
      return;
    }
    if (el?.type === "") {
      message.error("Заполните типы значений для всех полей");
      return;
    }
    setFieldsEditList((prev) => prev.filter((el) => el !== uuid));
    message.success("Поле успешно изменено");
  };

  const handleEdges = () => {
    const fNode: any = nodes.find((el) => el.id === id)?.data;
    if (!fNode) {
      // message.error('Не обнаружена нода. Возможно, вы ее удалили перед тем как открыть.');
      return;
    }
    setJoinType(fNode.joinType);
    setMainTable(fNode.mainTable);
    setRFields(fNode.formula);
    const findEdges = edges.filter((el) => el.target === id);
    if (!findEdges) {
      message.warning(
        `Обратите внимание, что в эту ноду (${id}) не входит поток данных`,
      );
      return;
    }
    let result: MappingField[] = [];
    for (const edge of findEdges) {
      const findSourceNode: any = nodes.find((el) => el.id === edge.source);
      if (findSourceNode?.data.formula) {
        findSourceNode.data.formula.forEach((el: MappingField) => {
          result.push({ ...el, flow: edge.source });
        });
      } else {
        message.warning(
          `Обратите внимание, что в ноде ${edge.source} не заданы поля`,
        );
      }
    }
    setMainTableOptions(
      findEdges.map((edge) => {
        return { label: edge.source, value: edge.source };
      }),
    );
    setLFields(result);
  };

  const handleMoveRight = (uuid: string) => {
    const element = lFields.find((el) => el.uuid === uuid);
    if (!element) {
      message.error(`Ошибка при попытке найти элемент`);
      return;
    }

    if (!rFields) {
      setRFields([
        {
          ...element,
          formula: `${element.flow}.${element.id}`,
          uuid: uuidv4(),
        },
      ]);
      return;
    }

    const checkRight = rFields.find((ele: any) => ele.id === id);
    if (checkRight) {
      message.warning(`Поле ${element.id} уже есть в исходящем потоке`);
      return;
    }

    setRFields((prev) => [
      ...prev,
      { ...element, formula: `${element.flow}.${element.id}`, uuid: uuidv4() },
    ]);
  };

  const handleSaveJoinType = () => {
    setNodes((prev) => {
      const res = prev.map((el) => {
        if (el.id === id) {
          el.data.joinType = joinType;
        }
        return el;
      });
      return res;
    });

    setEditJoinSetting(false);
  };

  const handleSaveMainTable = () => {
    setNodes((prev) => {
      const res = prev.map((el) => {
        if (el.id === id) {
          el.data.mainTable = mainTable;
        }
        return el;
      });
      return res;
    });

    setEditMainTableSetting(false);
  };

  const [textAreaValue, setTextAreaValue] = useState("");

  useEffect(() => {
    if (selectedTransform) {
      setTextAreaValue(rFieldsDict[selectedTransform]?.formula || "");
    } else {
      setTextAreaValue("");
    }
  }, [selectedTransform, rFieldsDict]);

  const handleTextAreaChange = (e: any) => {
    const newValue = e.target.value;
    setTextAreaValue(newValue);
    // Теперь обновляем глобальное состояние
    setRFields((prev) =>
      prev.map((field) => {
        if (field.uuid === selectedTransform) {
          return { ...field, formula: newValue };
        }
        return field;
      }),
    );
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Наименование ноды
          </div>
          <Input
            value={nodeName}
            status={
              nodeName.toString().match(/^[A-Za-z][A-Za-z0-9\-_]*$/)
                ? ""
                : "error"
            }
            disabled={!editNodeNameStatus}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => {
              if (!el.target.value.match(/^[A-Za-z][A-Za-z0-9\-_]*$/)) {
                message.warning(
                  "Вводимое наименование содержит недопустимые символы",
                );
              }
              setNodeName(el.target.value);
            }}
            allowClear
          />
          {!editNodeNameStatus && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditNodeNameStatus(true)}
            >
              ✏️
            </Button>
          )}
          {editNodeNameStatus && (
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <Button style={{ width: "50px" }} onClick={onChangeNodeId}>
                💾
              </Button>
              <Button
                style={{ width: "50px" }}
                onClick={() => {
                  setNodeName(id);
                  setEditNodeNameStatus(false);
                }}
              >
                🔄
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Тип джоина</div>
          <Select
            value={joinType}
            disabled={!editJoinSetting}
            onChange={(e) => setJoinType(e)}
            options={[
              { value: "LEFT", label: "LEFT" },
              { value: "FULL", label: "FULL" },
              { value: "INNER", label: "INNER" },
            ]}
            style={{ width: "300px", color: "black" }}
          />
          {!editJoinSetting && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditJoinSetting(true)}
            >
              ✏️
            </Button>
          )}
          {editJoinSetting && (
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <Button style={{ width: "50px" }} onClick={handleSaveJoinType}>
                💾
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Основная таблица
          </div>
          <Select
            value={mainTable}
            disabled={!editMainTableSetting}
            onChange={(e) => setMainTable(e)}
            options={mainTableOptions}
            style={{ width: "300px", color: "black" }}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
          {!editMainTableSetting && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditMainTableSetting(true)}
            >
              ✏️
            </Button>
          )}
          {editMainTableSetting && (
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <Button style={{ width: "50px" }} onClick={handleSaveMainTable}>
                💾
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            height: "500px",
            overflow: "auto",
          }}
        >
          <div>
            <span style={{ fontWeight: "bold" }}>
              ⤵️ Входящий поток: {SourceId}
            </span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "70px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Поток
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lFields?.length > 0 ? (
                    lFields.map((el: MappingField, ind: number) => (
                      <TableRow key={ind}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {IntegrationFieldsManager.getSourceFieldStatus(
                            `${el.flow || ""}.${el.id}`,
                            rFields,
                          )}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.flow}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.id}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.desc}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {el.type}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          <Button
                            type="dashed"
                            onClick={() => handleMoveRight(el.uuid)}
                          >
                            ➡
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={100}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет входящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>
              ⤴️ Исходящий поток: {nodeName}
            </span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Действие
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rFields?.length > 0 ? (
                    rFields.map((el: MappingField, ind: number) => (
                      <TableRow key={ind}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {fillIndicator(el.formula)}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Input
                            value={rFieldsDict[el.uuid]?.id || ""}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRFields((prev) =>
                                prev.map((field) => {
                                  if (field.uuid === el.uuid) {
                                    return { ...field, id: newValue };
                                  }
                                  return field;
                                }),
                              );
                            }}
                            disabled={!fieldsEditList.includes(el.uuid)}
                          />
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Input
                            value={rFieldsDict[el.uuid]?.desc || ""}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setRFields((prev) =>
                                prev.map((field) => {
                                  if (field.uuid === el.uuid) {
                                    return { ...field, desc: newValue };
                                  }
                                  return field;
                                }),
                              );
                            }}
                            disabled={!fieldsEditList.includes(el.uuid)}
                          />
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Select
                            style={{ width: "100%" }}
                            value={rFieldsDict[el.uuid]?.type || ""}
                            onChange={(e) => {
                              const newValue = e;
                              setRFields((prev) =>
                                prev.map((field: any) => {
                                  if (field.uuid === el.uuid) {
                                    return { ...field, type: newValue };
                                  }
                                  return field;
                                }),
                              );
                            }}
                            options={__optionType}
                            disabled={!fieldsEditList.includes(el.uuid)}
                          />
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <div
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              onClick={() => setSelectedTransform(el.uuid)}
                              disabled={fieldsEditList.includes(el.uuid)}
                            >
                              🔎
                            </Button>
                            {!fieldsEditList.includes(el.uuid) && (
                              <Button
                                onClick={() => handleEditFieldOn(el.uuid)}
                              >
                                ✏️
                              </Button>
                            )}
                            {fieldsEditList.includes(el.uuid) && (
                              <Button
                                onClick={() => handleEditFieldOff(el.uuid)}
                              >
                                💾
                              </Button>
                            )}
                            <Popconfirm
                              title={`Вы действительно хотите удалить поле ${el.id}?`}
                              okText="Да"
                              cancelText="Нет"
                              onConfirm={() => handleDelete(el.uuid)}
                            >
                              <Button>❌</Button>
                            </Popconfirm>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={100}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет исходящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <Button
              type="dashed"
              style={{ marginTop: "5px", width: "700px" }}
              onClick={() =>
                IntegrationFieldsManager.addField(
                  rFields,
                  setRFields,
                  setFieldsEditList,
                )
              }
            >
              Добавить поле
            </Button>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span>
            Параметр мэппинга:{" "}
            {rFields && rFields.find((el) => el.uuid === selectedTransform)?.id}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              height: "100px",
            }}
          >
            <TextArea
              size="large"
              style={{ height: "100px", resize: "none" }}
              disabled={!selectedTransform}
              value={textAreaValue}
              onChange={handleTextAreaChange}
            />
            <Button
              style={{ width: "100px", height: "100px", fontSize: "36px" }}
              onClick={handleEdges}
            >
              👁
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinWindow;

const __optionType = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];

const fillIndicator = (formula: string | null | undefined) => {
  if (!formula) {
    return "⭕";
  }
  console.log(formula, formula.indexOf("=") === -1);
  if (formula.indexOf("=") === -1) {
    if (formula.length > 0) {
      return "✅";
    } else {
      return "⭕";
    }
  } else {
    return "🔷";
  }
};
