import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Edge, Node } from "@xyflow/react";
import { Button, Input, Select, message, notification } from "antd";
import Empty from "antd/lib/empty";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TransferService from "../../../../../entities/model/TransferService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";

type Props = {
  id: string;
  nodes: Node[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
};

interface Format {
  value: string;
  label: string;
}

const ClickhouseInput: React.FC<Props> = ({
  id,
  nodes,
  setNodes,
  setEdges,
}) => {
  const [nodeName, setNodeName] = useState<string>(id);
  const [editNodeNameStatus, setEditNodeNameStatus] = useState<boolean>(false);

  const [fields, setFields] = useState<MappingField[]>([]);

  const onChangeNodeId = () => {
    IntegrationManager.changeNodeId(
      id,
      nodeName,
      nodes,
      () => {},
      setNodes,
      setEdges,
      setEditNodeNameStatus,
    );
  };

  useEffect(() => {
    const fNode: any = nodes.find((el) => el.id === id)?.data;
    if (fNode) {
      setChConnSelected(fNode.chConn);
      setChTableSelected(fNode.chTable);
      setFields(fNode.formula);
      getChConn();
    }
  }, [id]);

  const [chConnOptions, setChConnOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [chConnSelected, setChConnSelected] = useState<string>("");

  const [chTableOptions, setChTableOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [chTableSelected, setChTableSelected] = useState<string>("");

  const getChConn = async () => {
    const getSystems = await TransferService.getAll({
      filters: [{ id: "system", value: "CLICKHOUSE" }],
    });
    setChConnOptions(
      getSystems.data.rows.map((el: any) => ({ label: el.id, value: el.id })),
    );
  };

  const getChTable = async () => {
    if (!chConnSelected) {
      return;
    }
    const getTables = await TransferService.getClickhouseTables(chConnSelected);
    if (getTables.code === 1) {
      setChTableOptions(
        getTables.data.map((el: any) => ({ label: el, value: el })),
      );
    } else {
      notification.warning({ message: "Не удалось получить таблицы" });
    }
  };
  useEffect(() => {
    getChTable();
  }, [chConnSelected]);

  const getMappingField = (type: string) => {
    if (type.indexOf("String") !== -1) {
      return "STRING";
    }

    if (type.indexOf("Decimal") !== -1) {
      return "DECIMAL";
    }

    if (type.indexOf("Date") !== -1) {
      return "DATE";
    }

    return "STRING";
  };

  const getChField = async () => {
    if (!chConnSelected || !chTableSelected) {
      return;
    }
    const getFields = await TransferService.getClickhouseFields(
      chConnSelected,
      chTableSelected,
    );
    if (getFields.code === 1) {
      setFields(
        getFields.data.map((el: any) => ({
          uuid: uuidv4(),
          id: el.columnName,
          type: getMappingField(el.dataType),
        })),
      );
    } else {
      notification.warning({ message: "Не удалось получить таблицы" });
    }
  };
  useEffect(() => {
    getChField();
  }, [chTableSelected]);

  useEffect(() => {
    setNodes((prev) => {
      const res = prev.map((rec) => {
        if (rec.id === id) {
          rec.data.formula = fields;
          rec.data.chConn = chConnSelected;
          rec.data.chTable = chTableSelected;
        }
        return rec;
      });
      return res;
    });
  }, [fields]);

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Наименование ноды
          </div>
          <Input
            value={nodeName}
            status={
              nodeName.toString().match(/^[A-Za-z][A-Za-z0-9\-_]*$/)
                ? ""
                : "error"
            }
            disabled={!editNodeNameStatus}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => {
              if (!el.target.value.match(/^[A-Za-z][A-Za-z0-9\-_]*$/)) {
                message.warning(
                  "Вводимое наименование содержит недопустимые символы",
                );
              }
              setNodeName(el.target.value);
            }}
            allowClear
          />
          {!editNodeNameStatus && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditNodeNameStatus(true)}
            >
              ✏️
            </Button>
          )}
          {editNodeNameStatus && (
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <Button style={{ width: "50px" }} onClick={onChangeNodeId}>
                💾
              </Button>
              <Button
                style={{ width: "50px" }}
                onClick={() => {
                  setNodeName(id);
                  setEditNodeNameStatus(false);
                }}
              >
                🔄
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Подключение Clickhouse
          </div>
          <Select
            value={chConnSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setChConnSelected(el)}
            options={chConnOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Таблица</div>
          <Select
            value={chTableSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setChTableSelected(el)}
            options={chTableOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Идентификатор
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Описание</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Тип</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields?.length > 0 ? (
              fields.map((el: MappingField, ind: number) => (
                <TableRow key={ind}>
                  <TableCell>{el.id}</TableCell>
                  <TableCell>{el.desc}</TableCell>
                  <TableCell>{el.type}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={100}>
                  <Empty
                    imageStyle={{ height: "50px" }}
                    description="Нет исходящих полей"
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default ClickhouseInput;
