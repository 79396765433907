import { LockOutlined as Lock, LockOpen } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { Button, Input, notification, Popconfirm } from "antd";
import { isEqual } from "lodash";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../../..";
import TransferService from "../../../entities/model/TransferService";
import { Loader } from "../../../shared/components/loader";

const ViewSourcePage = () => {
  const params = useParams();
  const sourceID = params.sourceID!;

  const { store } = useContext(Context);
  store.setPageName(`Просмотр источника: ${sourceID}`);

  const {
    data: { id, system, connection: _connection = {} } = {},
    isFetching,
    isError,
    refetch,
  } = useQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ["source-data", sourceID],
    queryFn: async () => {
      const response = await TransferService.getSource(sourceID);

      if (response.code !== 1) {
        throw new Error(
          `Возникла ошибка при загрузке источника интеграции: ${response.text}`,
        );
      }

      // required to properly set state after changes in connection
      setConnection(response.data.connection);

      return response.data;
    },
  });

  const [connection, setConnection] = useState(_connection);

  const [isLocked, setIsLocked] = useState<boolean>(true);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  if (isFetching) {
    return <Loader />;
  }

  if (isError) {
    return (
      <div style={{ color: "red" }}>
        Не удалось загрузить информацию об источнике интеграции
      </div>
    );
  }

  const hasChanges = !isEqual(connection, _connection);

  const onLockChange = () => {
    if (isLocked) {
      setIsLocked(false);
      setConfirmOpen(false);
      return;
    }

    if (!isLocked && !hasChanges) {
      setIsLocked(true);
      setConfirmOpen(false);
      return;
    }

    if (!confirmOpen) {
      setConfirmOpen(true);
    }
  };

  const onUpdateConfirm = async () => {
    if (!id || !system) {
      notification.error({
        message: "Информация об источнике не была загружена",
      });
      return;
    }

    const response = await TransferService.updateSource(id, system, connection);

    if (response.code !== 1) {
      notification.error({
        message: "Возникла ошибка при обновлении источника",
        description: response.text,
      });
      return;
    }

    notification.success({ message: "Источник обновлен" });

    setConfirmOpen(false);
    setIsLocked(true);
    refetch();
  };

  const revert = () => {
    setConnection(_connection);
    setIsLocked(true);
  };

  const parameters = (() => {
    if (system === "Knowledge Space") {
      return (
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <span style={{ fontWeight: "bold" }}>URL системы</span>
            <Input
              value={connection!.url! as string}
              disabled={isLocked}
              onChange={({ target: { value: url } }) =>
                setConnection({ ...connection, url })
              }
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <span style={{ fontWeight: "bold" }}>Логин пользователя</span>
            <Input
              value={connection!.login! as string}
              disabled={isLocked}
              onChange={({ target: { value: login } }) =>
                setConnection({ ...connection, login })
              }
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <span style={{ fontWeight: "bold" }}>Пароль пользователя</span>
            <Input.Password
              value={connection!.password! as string}
              disabled={isLocked}
              onChange={({ target: { value: password } }) =>
                setConnection({ ...connection, password })
              }
            />
          </div>
        </div>
      );
    }

    if (system === "CLICKHOUSE" || system === "POSTGRES") {
      return (
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <span style={{ fontWeight: "bold" }}>Хост</span>
            <Input
              value={connection!.host! as string}
              disabled={isLocked}
              onChange={({ target: { value: host } }) =>
                setConnection({ ...connection, host })
              }
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <span style={{ fontWeight: "bold" }}>Порт</span>
            <Input
              value={connection!.port! as string}
              disabled={isLocked}
              onChange={({ target: { value: port } }) =>
                setConnection({ ...connection, port })
              }
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <span style={{ fontWeight: "bold" }}>База данных</span>
            <Input
              value={connection!.database! as string}
              disabled={isLocked}
              onChange={({ target: { value: database } }) =>
                setConnection({ ...connection, database })
              }
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <span style={{ fontWeight: "bold" }}>Логин пользователя</span>
            <Input
              value={connection!.user! as string}
              disabled={isLocked}
              onChange={({ target: { value: user } }) =>
                setConnection({ ...connection, user })
              }
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <span style={{ fontWeight: "bold" }}>Пароль пользователя</span>
            <Input.Password
              value={connection!.password! as string}
              disabled={isLocked}
              onChange={({ target: { value: password } }) =>
                setConnection({ ...connection, password })
              }
            />
          </div>
        </div>
      );
    }

    return <div style={{ color: "red" }}>Неизвестный вид подключения</div>;
  })();

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        <Button type="default" onClick={() => refetch()}>
          🔄
        </Button>
        <Popconfirm
          open={confirmOpen}
          onOpenChange={onLockChange}
          title={`Изменение параметров соединения`}
          description={`Вы действительно хотите изменить параметры соединения?`}
          onConfirm={onUpdateConfirm}
          onCancel={() => setConfirmOpen(false)}
          okText="Да"
          cancelText="Нет"
        >
          <Button type="default">{isLocked ? <Lock /> : <LockOpen />}</Button>
        </Popconfirm>
        {hasChanges && (
          <Button type="default" onClick={revert}>
            ⏪
          </Button>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span style={{ fontWeight: "bold" }}>Идентификатор подключения</span>
          <Input value={id} disabled />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span style={{ fontWeight: "bold" }}>Тип подключения</span>
          <Input value={system} disabled />
        </div>
        {parameters}
      </div>
    </div>
  );
};

export default ViewSourcePage;
