import { Button, Form, Input, notification } from "antd";
import { useContext } from "react";
import { Context } from "../..";

const UserSettingsPage = () => {
  const { store } = useContext(Context);

  const userBasicInfo = [
    {
      name: "Имя",
      value: store.user.name,
    },
    {
      name: "Фамилия",
      value: store.user.surname,
    },
    {
      name: "Почта",
      value: store.user.email,
    },
    {
      name: "Техническая роль",
      value: store.user.role,
    },
    {
      name: "Идентификатор",
      value: store.user.id,
    },
    {
      name: "Статус активации",
      value: store.user.isActivated,
    },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      <span
        style={{ fontSize: 18, fontWeight: 700, color: "var(--Primary-400)" }}
      >
        Общая информация
      </span>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "250px",
        }}
      >
        {userBasicInfo.map((el: { name: string; value: any }, key) => {
          return (
            <div key={key}>
              <span>{el.name}</span>
              <Input disabled value={el.value} style={{ color: "black" }} />
            </div>
          );
        })}
      </div>
      <span
        style={{ fontSize: 18, fontWeight: 700, color: "var(--Primary-400)" }}
      >
        Сменить пароль
      </span>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "250px",
        }}
      >
        <Form onFinish={(el) => handlePassChange(el)}>
          <Form.Item name={"curr_password"}>
            <div>
              <span>Текущий пароль</span>
              <Input.Password />
            </div>
          </Form.Item>
          <Form.Item name={"new_password"}>
            <div>
              <span>Новый пароль</span>
              <Input.Password />
            </div>
          </Form.Item>
          <Form.Item name={"new_password_repeat"}>
            <div>
              <span>Повторите новый пароль</span>
              <Input.Password />
            </div>
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Сохранить новый пароль
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default UserSettingsPage;

const handlePassChange = async (form: {
  curr_password: string;
  new_password: string;
  new_password_repeat: string;
}) => {
  return notification.warning({
    message: `На данный момент функция отключена`,
  });

  // if (!form.new_password) return notification.error({ message: "Новый пароль не задан" });
  // if (!form.new_password_repeat) return notification.error({ message: "Повторите новый пароль" });
  // if (form.new_password !== form.new_password_repeat)
  //   return notification.error({ message: "Введенные пароли не совпадают" });

  // const response = await AuthService.changePassword(form.curr_password, form.new_password);
  // console.log(response);

  // switch (response.data.code) {
  //   default:
  //     return notification.warning({ message: response.data.text });
  // }
};
