import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Edge, Node } from "@xyflow/react";
import { Button, Input, Select, Switch, message, notification } from "antd";
import Empty from "antd/lib/empty";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import KSService from "../../../../../entities/model/KSService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { getCyrillic } from "../../../../../shared/helper/cyrillic";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";

interface Input {
  id: string;
  nodes: Node[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
}

interface Format {
  value: string;
  label: string;
}

const KnowledgeSpaceClassInput: React.FC<Input> = ({
  id,
  nodes,
  setNodes,
  setEdges,
}) => {
  const [nodeName, setNodeName] = useState<string>(id);
  const [editNodeNameStatus, setEditNodeNameStatus] = useState<boolean>(false);

  const [fields, setFields] = useState<MappingField[]>([]);

  const onChangeNodeId = () => {
    IntegrationManager.changeNodeId(
      id,
      nodeName,
      nodes,
      () => {},
      setNodes,
      setEdges,
      setEditNodeNameStatus,
    );
  };

  useEffect(() => {
    setNodeName(id);
    const node: any = nodes.find((el) => el.id === id)?.data;
    if (node) {
      setFields(node.formula);
      setKSSystemSelected(node.system);
      setKSProjectSelected(node.project);
      setKSModelSelected(node.model);
      setKSClassSelected(node.class);
      setKSDatasetSelected(node.dataset);
      setKSTimeDependentSelected(node.timeDependent === true);
    }
    KSgetSystemList();
  }, [id]);

  const [ksSystemOptions, setKSSystemOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [ksSystemSelected, setKSSystemSelected] = useState<string>("");

  const [ksProjectOptions, setKSProjectOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [ksProjectSelected, setKSProjectSelected] = useState<string>("");

  const [ksClassOptions, setKSClassOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [ksClassSelected, setKSClassSelected] = useState<string>("");

  const [ksModelOptions, setKSModelOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [ksModelSelected, setKSModelSelected] = useState<string>("");

  const [ksDatasetOptions, setKSDatasetOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [ksDatasetSelected, setKSDatasetSelected] = useState<string>("");

  const [ksTimeDependentSelected, setKSTimeDependentSelected] =
    useState<boolean>(false);

  const KSgetSystemList = async () => {
    const getSystems = await KSService.getAll();
    setKSSystemOptions(
      getSystems.data.map((el: any) => ({ label: el.id, value: el.id })),
    );
  };

  const KSgetProjectList = async () => {
    if (ksSystemSelected && ksSystemSelected !== "") {
      const getProjects = await KSService.getProjects(ksSystemSelected);
      if (getProjects.code === 1) {
        setKSProjectOptions((prevOptions: any) =>
          getProjects.data.map((el: any) => ({
            label: el.name,
            value: el.uuid,
          })),
        );
      } else {
        notification.error({
          message: getProjects.text,
          description: `Код ответа: ${getProjects.code}`,
        });
      }
    }
  };

  useEffect(() => {
    KSgetProjectList();
  }, [ksSystemSelected]);

  const KSgetClassList = async () => {
    const getClasses = await KSService.getClasses(
      ksSystemSelected,
      ksProjectSelected,
    );
    if (getClasses.code === 1) {
      setKSClassOptions((prevOptions: any) =>
        getClasses.data.map((el: any) => ({
          label: el.name,
          value: el.uuid,
        })),
      );
    }
  };

  useEffect(() => {
    KSgetClassList();
  }, [ksProjectSelected]);

  const KSgetModelList = async () => {
    const getModels = await KSService.getModels(
      ksSystemSelected,
      ksProjectSelected,
    );
    if (getModels.code === 1) {
      setKSModelOptions((prevOptions: any) =>
        getModels.data.map((el: any) => ({
          label: el.name,
          value: el.uuid,
        })),
      );
    }
  };

  useEffect(() => {
    KSgetModelList();
  }, [ksProjectSelected]);

  const KSgetFigures = async () => {
    const getFigures = await KSService.getFigures(
      ksSystemSelected,
      ksProjectSelected,
      ksClassSelected,
    );

    if (getFigures.code === 1) {
      const newFields = getFigures.data
        .filter((el: any) => el.canBeTimed === ksTimeDependentSelected)
        .map((el: any) => ({
          uuid: el.uuid,
          id: getCyrillic(el.name.replace(/[^A-Za-zА-Яа-яёЁ0-9]/g, "")),
          desc: el.name,
          type:
            el.dataType === "number"
              ? "DECIMAL"
              : el.dataType === "datetime"
                ? "DATE"
                : el.dataType === "boolean"
                  ? "BOOL"
                  : "STRING",
        }));

      if (ksTimeDependentSelected) {
        setFields([
          {
            uuid: uuidv4(),
            id: "_Date",
            type: "DATE",
            desc: "Дата для показателей со временем",
          },
          {
            uuid: uuidv4(),
            id: "_Object",
            type: "STRING",
            desc: "Наименование объекта",
          },
          ...newFields,
        ]);
      } else {
        setFields([
          {
            uuid: uuidv4(),
            id: "_Object",
            type: "STRING",
            desc: "Наименование объекта",
          },
          ...newFields,
        ]);
      }
    }
  };

  const KSgetDataset = async () => {
    if (
      ksSystemSelected &&
      ksSystemSelected !== "" &&
      ksProjectSelected &&
      ksProjectSelected !== "" &&
      ksModelSelected &&
      ksModelSelected !== ""
    ) {
      const getDatasets = await KSService.getDatasets(
        ksSystemSelected,
        ksProjectSelected,
        ksModelSelected,
      );
      console.log(getDatasets);
      if (getDatasets.code === 1) {
        setKSDatasetOptions((prevOptions: any) =>
          getDatasets.data.map((el: any) => ({
            label: el.name,
            value: el.uuid,
          })),
        );
      }
    }
  };

  useEffect(() => {
    KSgetDataset();
  }, [ksModelSelected]);

  useEffect(() => {
    setNodes((prev) => {
      const res = prev.map((rec) => {
        if (rec.id === id) {
          rec.data.formula = fields;
          rec.data.system = ksSystemSelected;
          rec.data.project = ksProjectSelected;
          rec.data.model = ksModelSelected;
          rec.data.class = ksClassSelected;
          rec.data.dataset = ksDatasetSelected;
          rec.data.timeDependent = ksTimeDependentSelected;
        }
        return rec;
      });
      return res;
    });
  }, [fields]);

  useEffect(() => {
    KSgetFigures();
  }, [ksClassSelected, ksTimeDependentSelected]);

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Наименование ноды
          </div>
          <Input
            value={nodeName}
            status={
              nodeName.toString().match(/^[A-Za-z][A-Za-z0-9\-_]*$/)
                ? ""
                : "error"
            }
            disabled={!editNodeNameStatus}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => {
              if (!el.target.value.match(/^[A-Za-z][A-Za-z0-9\-_]*$/)) {
                message.warning(
                  "Вводимое наименование содержит недопустимые символы",
                );
              }
              setNodeName(el.target.value);
            }}
            allowClear
          />
          {!editNodeNameStatus && (
            <Button
              style={{ width: "50px" }}
              onClick={() => setEditNodeNameStatus(true)}
            >
              ✏️
            </Button>
          )}
          {editNodeNameStatus && (
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              <Button style={{ width: "50px" }} onClick={onChangeNodeId}>
                💾
              </Button>
              <Button
                style={{ width: "50px" }}
                onClick={() => {
                  setNodeName(id);
                  setEditNodeNameStatus(false);
                }}
              >
                🔄
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Подключение KS
          </div>
          <Select
            value={ksSystemSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setKSSystemSelected(el)}
            options={ksSystemOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Проект KS</div>
          <Select
            value={ksProjectSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setKSProjectSelected(el)}
            options={ksProjectOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Модель KS</div>
          <Select
            value={ksModelSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setKSModelSelected(el)}
            options={ksModelOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Датасет KS</div>
          <Select
            value={ksDatasetSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setKSDatasetSelected(el)}
            options={ksDatasetOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Класс KS</div>
          <Select
            value={ksClassSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setKSClassSelected(el)}
            options={ksClassOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Времязависимые
          </div>
          <Switch
            checked={ksTimeDependentSelected}
            onChange={(el) => setKSTimeDependentSelected(el)}
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Идентификатор
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Описание</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Тип</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields?.length ? (
              fields.map((el: MappingField, ind: number) => (
                <TableRow key={ind}>
                  <TableCell>{el.id}</TableCell>
                  <TableCell>{el.desc}</TableCell>
                  <TableCell>{el.type}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={100}>
                  <Empty
                    imageStyle={{ height: "50px" }}
                    description="Нет исходящих полей"
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default KnowledgeSpaceClassInput;
