import { Edge, Node } from "@xyflow/react";
import type { CollapseProps } from "antd";
import { Collapse } from "antd";
import { Dispatch, SetStateAction } from "react";
import colors from "./colors";
import { IFlowVariableField } from "./modalVariables";

type Props = {
  setFlowId: Dispatch<SetStateAction<string | null>>;
  setProject: Dispatch<SetStateAction<string | null>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setVariables: Dispatch<SetStateAction<IFlowVariableField[]>>;
  variables: IFlowVariableField[];
  flowId: string | null;
  project: string | null;
  nodes: Node[];
  edges: Edge[];
  startFlowId: string;
};

const Sidebar: React.FC<Props> = ({}) => {
  const onDragStart = (event: any, nodeType: any) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  const items: CollapseProps["items"] = [
    {
      key: "s_note",
      label: "Заметки",
      children: (
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <SidebarElement
            background="#FDD892"
            name="Заметка"
            id="note"
            onDragStart={onDragStart}
            iconUri={"/img/blocks/mapping.svg"}
          />
        </div>
      ),
    },
    {
      key: "s_transformation",
      label: "Трансформации",
      children: (
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <SidebarElement
            background={colors.mapping}
            name="Выбор"
            id="mapping"
            onDragStart={onDragStart}
            iconUri="/img/blocks/mapping.svg"
          />
          <SidebarElement
            background={colors.filter}
            name="Фильтр"
            id="filter"
            onDragStart={onDragStart}
            iconUri="/img/blocks/filter.svg"
          />
          <SidebarElement
            background={colors.group}
            name="Группировка"
            id="groupBy"
            onDragStart={onDragStart}
            iconUri="/img/blocks/group.svg"
          />
          <SidebarElement
            background={colors.join}
            name="Соединение"
            id="join"
            onDragStart={onDragStart}
            iconUri="/img/blocks/join.svg"
          />
          <SidebarElement
            background={colors.rowGen}
            name="Генератор"
            id="rowGen"
            onDragStart={onDragStart}
            iconUri="/img/blocks/rowgen.svg"
          />
          <SidebarElement
            background={colors.extrapolation}
            name="Прогноз"
            id="extrapolation"
            onDragStart={onDragStart}
            iconUri="/img/blocks/rowgen.svg"
          />
        </div>
      ),
    },
    {
      key: "s_sources",
      label: "Источники",
      children: (
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <SidebarElement
            background={colors.planx}
            name="PX Показатели"
            id="planxFigureInput"
            onDragStart={onDragStart}
            iconUri="/img/blocks/planx.svg"
          />
          <SidebarElement
            background={colors.flatFile}
            name="Плоский файл"
            id="flatFile"
            onDragStart={onDragStart}
            iconUri="/img/blocks/mapping.svg"
          />
          <SidebarElement
            background={colors.knowledgeSpace}
            name="KS Словарь"
            id="knowledgeSpaceDictionaryInput"
            onDragStart={onDragStart}
            iconUri="/img/blocks/ks.svg"
          />
          <SidebarElement
            background={colors.knowledgeSpace}
            name="KS Класс"
            id="knowledgeSpaceClassInput"
            onDragStart={onDragStart}
            iconUri="/img/blocks/ks.svg"
          />
          <SidebarElement
            background={colors.postgres}
            name="PostgreSQL"
            id="postgresInput"
            onDragStart={onDragStart}
            iconUri="/img/blocks/postgres.svg"
          />
          <SidebarElement
            background={colors.clickhouse}
            name="Clickhouse"
            id="clickhouseInput"
            onDragStart={onDragStart}
            iconUri="/img/blocks/clickhouse.svg"
          />
        </div>
      ),
    },
    {
      key: "s_targets",
      label: "Цели",
      children: (
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <SidebarElement
            background={colors.planx}
            name="PX Показатели"
            id="planxFigureOutput"
            onDragStart={onDragStart}
            iconUri="/img/blocks/planx.svg"
          />
          <SidebarElement
            background={colors.flatFile}
            name="Плоский файл"
            id="targetFlatFile"
            onDragStart={onDragStart}
            iconUri="/img/blocks/mapping.svg"
          />
          <SidebarElement
            background={colors.knowledgeSpace}
            name="KS Класс"
            id="knowledgeSpaceClassOutput"
            onDragStart={onDragStart}
            iconUri="/img/blocks/ks.svg"
          />
          <SidebarElement
            background={colors.postgres}
            name="PostgreSQL"
            id="postgresOutput"
            onDragStart={onDragStart}
            iconUri="/img/blocks/postgres.svg"
          />
        </div>
      ),
    },
    {
      key: "s_actions",
      label: "События",
      children: (
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <SidebarElement
            background={colors.rabbitmq}
            name="RabbitMQ"
            id="rabbitmqOutput"
            onDragStart={onDragStart}
            iconUri="/img/blocks/rabbitmq.svg"
          />
          <SidebarElement
            background={colors.launchFlow}
            name="Launcher"
            id="launchFlow"
            onDragStart={onDragStart}
            iconUri="/img/shape_merge.svg"
          />
        </div>
      ),
    },
  ];

  return (
    <aside
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        width: "220px",
        background: "#292d39",
        padding: 5,
        borderRadius: "0px 0px 0px 8px",
        color: "white",
        height: "calc(100vh - 56px - 24px - 15px - 10px)",
        minHeight: "600px",
        overflow: "auto",
      }}
    >
      <Collapse
        items={items}
        style={{ background: "#F2F2F2", fontWeight: "bold" }}
        defaultActiveKey={[
          "s_transformation",
          "s_sources",
          "s_targets",
          "s_actions",
        ]}
      />
    </aside>
  );
};

export default Sidebar;

interface IIntegrationFlowSidebarElement {
  background: string;

  name: string;
  id: string;

  onDragStart: (event: any, nodeType: any) => void;

  iconUri: string;

  color?: string;
}

const SidebarElement: React.FC<IIntegrationFlowSidebarElement> = ({
  background,
  name,
  id,
  onDragStart,
  iconUri,
  color,
}) => {
  return (
    <div
      style={{
        height: "40px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: "18px",
        cursor: "grab",
      }}
      className={id}
      onDragStart={(event) => onDragStart(event, id)}
      draggable
    >
      <div
        style={{
          width: "42px",
          height: "100%",
          background,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px 0px 0px 8px",
        }}
      >
        <img
          src={iconUri}
          style={{
            filter: "grayscale(1) brightness(1.2)",
            width: "24px",
            opacity: 0.9,
          }}
        />
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          paddingLeft: "5px",
          background: "#F2F2F2",
          // background: '#555555',
          display: "flex",
          alignItems: "center",
          borderRadius: "0px 8px 8px 0px",
        }}
      >
        <span style={{ fontWeight: "bold", color: color ? color : "black" }}>
          {name}
        </span>
      </div>
    </div>
  );
};
