import { Edge, Node, NodeTypes } from "@xyflow/react";
import {
  Type_ClickhouseInput,
  Type_Extrapolation,
  Type_Filter,
  Type_FlatFile,
  Type_GroupBy,
  Type_Join,
  Type_KnowledgeSpaceClassInput,
  Type_KnowledgeSpaceClassOutput,
  Type_KnowledgeSpaceDictionaryInput,
  Type_LaunchFlow,
  Type_Mapping,
  Type_Note,
  Type_PlanXInput,
  Type_PlanXOutput,
  Type_PostgresInput,
  Type_PostgresOutput,
  Type_RabbitOutput,
  Type_RowGen,
  Type_TargetFlatFile,
} from "../blocks";
import ClickhouseInput from "./inputs/clickhouseinput";
import FlatFileWindow from "./inputs/flatFile";
import KnowledgeSpaceClassInput from "./inputs/knowledgeSpaceClassInput";
import KnowledgeSpaceDictionaryInput from "./inputs/knowledgeSpaceDictionaryInput";
import PlanXFigureInputWindow from "./inputs/planxFigureInput";
import PostgresInput from "./inputs/postgreinput";
import KnowledgeSpaceClassOutput from "./targets/knowledgeSpaceClassOutput";
import LaunchFlow from "./targets/launchFlow";
import PlanXFigureOutputWindow from "./targets/planxFigureOutput";
import PostgresOutput from "./targets/postgreoutput";
import RabbitmqOutput from "./targets/rabbitmqOutput";
import TargetFlatFileWindow from "./targets/targetFlatFile";
import ExtrapolationWindow from "./transformation/extrapolation";
import FilterWindow from "./transformation/filter";
import GroupByWindow from "./transformation/groupBy";
import JoinWindow from "./transformation/join";
import MappingWindow from "./transformation/mapping";
import RowGenWindow from "./transformation/rowGen";

export const nodeTypes: NodeTypes = {
  targetFlatFile: Type_TargetFlatFile,
  flatFile: Type_FlatFile,
  mapping: Type_Mapping,
  filter: Type_Filter,
  join: Type_Join,
  groupBy: Type_GroupBy,
  rowGen: Type_RowGen,
  knowledgeSpaceDictionaryInput: Type_KnowledgeSpaceDictionaryInput,
  // knowledgeSpaceDictionaryOutput: Type_KnowledgeSpaceDictionaryOutput,
  knowledgeSpaceClassInput: Type_KnowledgeSpaceClassInput,
  knowledgeSpaceClassOutput: Type_KnowledgeSpaceClassOutput,
  planxFigureInput: Type_PlanXInput,
  planxFigureOutput: Type_PlanXOutput,
  postgresInput: Type_PostgresInput,
  postgresOutput: Type_PostgresOutput,
  clickhouseInput: Type_ClickhouseInput,
  rabbitmqOutput: Type_RabbitOutput,
  launchFlow: Type_LaunchFlow,
  extrapolation: Type_Extrapolation,
  note: Type_Note,
};

export enum ModalType {
  target__flatFile,
  flatfile,
  mapping,
  join,
  filter,
  group,
  rowGen,
  knowledgeSpaceClassInput,
  knowledgeSpaceClassOutput,
  knowledgeSpaceDictionaryInput,
  knowledgeSpaceDictionaryOutput,
  planxFigureInput,
  planxFigureOutput,
  postgresInput,
  postgresOutput,
  clickhouseInput,
  rabbitmqOutput,
  launchFlow,
  extrapolation,
  note,
}

export const createModalWindow = (
  modalType: ModalType | null,
  nodeId: string,
  nodes: Node[],
  edges: Edge[],
  setNodes: any,
  setEdges: any,
  variables: any[],
  setVariables: any,
) => {
  const modalWindows = {
    [ModalType.flatfile]: FlatFileWindow,
    [ModalType.mapping]: MappingWindow,
    [ModalType.filter]: FilterWindow,
    [ModalType.group]: GroupByWindow,
    [ModalType.join]: JoinWindow,
    [ModalType.rowGen]: RowGenWindow,
    [ModalType.target__flatFile]: TargetFlatFileWindow,
    [ModalType.knowledgeSpaceClassInput]: KnowledgeSpaceClassInput,
    [ModalType.knowledgeSpaceClassOutput]: KnowledgeSpaceClassOutput,
    [ModalType.knowledgeSpaceDictionaryInput]: KnowledgeSpaceDictionaryInput,
    [ModalType.planxFigureInput]: PlanXFigureInputWindow,
    [ModalType.planxFigureOutput]: PlanXFigureOutputWindow,
    [ModalType.postgresInput]: PostgresInput,
    [ModalType.postgresOutput]: PostgresOutput,
    [ModalType.clickhouseInput]: ClickhouseInput,
    [ModalType.rabbitmqOutput]: RabbitmqOutput,
    [ModalType.launchFlow]: LaunchFlow,
    [ModalType.extrapolation]: ExtrapolationWindow,
  };

  if (modalType === null || modalType === undefined) {
    return null;
  }

  // @ts-ignore
  const ModalWindow = modalWindows[modalType];

  if (!ModalWindow) {
    return null;
  }

  return (
    <ModalWindow
      id={nodeId}
      nodes={nodes}
      edges={edges}
      variables={variables}
      setNodes={setNodes}
      setEdges={setEdges}
      setVariables={setVariables}
    />
  );
};
